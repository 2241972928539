export default function orders(state = null, action) {
  switch (action.type) {
    case "RESET_ORDERS_ALL":
      return null;
    case "ORDERS_ALL_FETCHED":
      return action.payload;
    default:
      return state;
  }
}
