import React from 'react'
import { Parallax } from "react-parallax";



// const styles = {
//     fontFamily: "sans-serif",
//     textAlign: "center"
// };

const insideStyles = {
  background: "rgba(255, 255, 255, .6)",
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};

const bg_img = "/images/industria1080.jpg";
const logo_img = "/images/logo-md.png"
//const image2 = "https://images.unsplash.com/photo-1498092651296-641e88c3b057?auto=format&fit=crop&w=1778&q=60&ixid=dW5zcGxhc2guY29tOzs7Ozs%3D";

const Jumbotron2 = () => {
  return (
    <div style={{marginTop: 30}} id="home2">
    <Parallax bgImage={bg_img} strength={800} >
    <div style={{ height: 500, }} >
      <div style={insideStyles}>
      <div className="content logo-sq">
        <img className="logo"  src={logo_img} alt="" />
      </div>
      </div>
    </div>
    </Parallax>
    </div>
  )
}

export default Jumbotron2
