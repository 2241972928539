//@ts-check
/* eslint eqeqeq: "off"*/
import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import get from "lodash.get";
import { Dropdown } from "semantic-ui-react";

//todo remove virtualizeselect and use Dropdown only if speed is right
// todo remove unecessary data
// todo remove functions from render
//todo update server data
//todo add and remove discount
//todo add and remove fee

import { v1 as uuidv1 } from "uuid";

// https://react.semantic-ui.com/modules/dropdown/#types-inline

import VirtualizedSelect from "react-virtualized-select";

import "react-select/dist/react-select.css";
import "react-virtualized/styles.css";
import "react-virtualized-select/styles.css";

import { formatMoney, toFixed } from "../../services/utils";
import {
  fetchOrders,
  fetchSlabs,
  updateOrder,
} from "../../services/apiService";
import {
  Spinner,
  showAlert,
  showDeleteConfirmation,
} from "../../components/dialogs";
import { updateOrderAction } from "../../actions";

const toBool = (val) => {
  return +val == 1 || val === true;
};

export const formatDatetime = (datetime) => {
  if (!datetime) return "";
  if (datetime.startsWith("0000-00-00")) return "";
  const tmp = moment(datetime).format("MMM D, YYYY @ h:mm a");
  return tmp;
};

const initial = {
  id: 0,
  customer: {},
  items: [],
  removed: [],
  discounts: [],
  fees: [],
  payments: null,
  modalIsOpen: false,
  delivery: null,
};
class OrderEdit extends Component {
  redirectSufix = "";
  state = {
    loading: true,
    order: initial,
    balance: 1,
    delivery: {},
    slabOptions: [],
    slabs: [],
    selectedSlabOption: {},
    selectedPriceOption: "",
  };

  async componentDidMount() {
    this.showSpinner();
    // @ts-ignore
    moment.suppressDeprecationWarnings = true;
    if (this.props.location.search.search("redirect=all") > -1) {
      this.redirectSufix = "/all";
    }

    const order = this.props.order || {};
    if (order.id < 1) {
      const res = await fetchOrders(this.props.match.params.id);
      this.setState({ order: res });
    } else {
      this.setState({ order: order });
    }

    const slabs = await fetchSlabs();
    const slabOptions = slabs.map((slab) => {
      return {
        value: slab.slab_num,
        label: `${slab.material} - ${slab.slab_num}`,
      };
    });

    this.setState({ slabOptions, slabs });

    this.hideSpinner();
  }

  handleClose = () => {
    this.props.history.push(`/orders/${this.state.order.id}`);
  };

  handleUpdateOrder = async () => {
    console.log("Update Order", this.state.order);
    const result = await updateOrder(this.state.order);
    console.log("data", result.data);
    if (!result.error) {
      await this.updateOrderAction(result.data);
      showAlert("Order updated", "success");
    } else {
      showAlert("Error trying to update order", "error");
    }
  };

  handleRemoveOrderItem = (id) => {
    let order = this.state.order;
    console.log("handleRemoveOrderItem", id, order.items);
    const filteredItems = order.items.filter((item) => item.id != id);
    order.items = filteredItems;
    order.removed = order.removed || [];
    order.removed.push(id);
    this.updateOrderTotal(order);
    this.updateOrderItemIndexes(order);
    this.setState({ order });
  };

  handlePriceOptionsChange = (event, data) => {
    console.log("dropdown onchange", data);
    this.setState({
      selectedPriceOption: data.value || "",
    });
  };

  handleAddOrderItem = () => {
    const _selectedValue = this.state.selectedSlabOption.value;

    if (!_selectedValue) return;

    const selectSlab = this.state.slabs.find(
      (slab) => slab.slab_num == _selectedValue
    );
    const items = this.state.order.items;
    const newOrderItem = this.getNewOrderitem(items, selectSlab);
    items.push(newOrderItem);
    const order = this.state.order;
    order.items = items;
    //remove slab from dropdown list
    const slabOptions = this.state.slabOptions.filter((slab) => {
      return slab.value != selectSlab.slab_num;
    });

    this.updateOrderTotal(order);
    this.updateOrderItemIndexes(order);
    this.setState({
      order,
      slabOptions,
      selectedSlabOption: "",
      selectedPriceOption: "",
    });
  };

  updateOrderTotal = (order) => {
    const reducer = (accumulator, currentValue) =>
      accumulator + Number(currentValue.amount);
    const discountTotal = (order.discounts || []).reduce(reducer, 0);
    let feeTotal = (order.fees || []).reduce(reducer, 0);
    const itemTotal = (order.items || []).reduce((accumulator, item) => {
      return accumulator + Number(item.total);
    }, 0);

    feeTotal = feeTotal || Number(order.total_fees);
    console.log("itemTotal", itemTotal);
    console.log("discountTotal", discountTotal);
    console.log("feeTotal", feeTotal);
    const subTotal = itemTotal + feeTotal - discountTotal;
    console.log("subtotal", subTotal);
    const total_tax = subTotal * order.tax_rate;
    const total = subTotal + total_tax;
    order.subtotal = subTotal;
    order.tax = total_tax;
    order.total = total;
  };

  updateOrderItemIndexes = (order) => {
    const length = order.items.length;
    for (let i = 0; i < length; i++) {
      order.items[i].index = i;
    }
  };

  getNewOrderitem = (items, selectSlab) => {
    const selectedPriceOption = this.state.selectedPriceOption || "";
    const isbundle = selectedPriceOption.includes("bundle");
    const selectedPrice = isbundle
      ? Number(selectSlab.price.bundle_price)
      : Number(selectSlab.price.unit_price);
    const newOrderItem = {
      bundle_price: selectSlab.price.bundle_price,
      id: "add-" + uuidv1(),
      index: items.length,
      isbundle: isbundle,
      order_id: this.state.order.id,
      slab: {
        id: selectSlab.id,
        slab_num: selectSlab.slab_num,
        material: selectSlab.material,
        slug: selectSlab.slug,
        length: selectSlab.length,
        height: selectSlab.height,
      },
      slab_id: selectSlab.id,
      slab_num: selectSlab.slab_num,
      sqft: selectSlab.sqft,
      total: Number(selectSlab.sqft) * selectedPrice,
      unit_price: selectSlab.price.unit_price,
    };
    return newOrderItem;
  };

  showSpinner = (value = true) => {
    this.setState({ loading: value });
  };

  hideSpinner = () => {
    this.setState({ loading: false });
  };

  appendClassStatus = (str, check) => {
    if (check) return str + " success";
    return str + " danger";
  };

  orderBalance = () => {
    const order = this.state.order;
    const totalInvoiced = +order.total;
    const balance = toFixed(totalInvoiced);
    return balance;
  };

  getTime = (time) => {
    if (!time) return "00:00:00";
    const _time = moment("2018-12-12 " + time).format("HH:mm:00");
    return _time;
  };

  combineDateAndTime = (date, time) => {
    const _time = this.getTime(time);
    const datetime = moment(date).format("YYYY-MM-DD " + _time);
    return datetime;
  };

  updateOrderAction = async (data = {}) => {
    const order_id = data.order_id ? data.order_id : this.state.order.id;
    const res = await fetchOrders(order_id);
    //console.log("updateOrderAction res", res);
    if (!res.error) {
      this.setState({ order: res });
      this.props.dispatch(updateOrderAction(res));
    }
    return;
  };

  getPriceOptions(selectedSlab) {
    let priceOptions = [{ value: "" }];
    if (selectedSlab && selectedSlab.price) {
      priceOptions = [
        {
          value: "unit_price",
          text: get(selectedSlab, "price.unit_price") + " (unit)",
          key: "unit_price",
        },
        {
          value: "bundle_price",
          text: get(selectedSlab, "price.bundle_price") + " (bundle)",
          key: "bundle_price",
        },
      ];
    }
    return priceOptions;
  }

  getSelectedSlab = () => {
    const selected = this.state.selectedSlabOption || {};
    console.log("selected ", selected);
    const selectedSlab = this.state.slabs.find(
      (slab) => selected.value == slab.slab_num
    );
    return selectedSlab;
  };

  handleSlabOptionChange = (selectedValue) => {
    this.setState({ selectedSlabOption: selectedValue });
  };

  render() {
    const order = this.state.order || initial;
    const customer = order.customer || {};
    const shipping = order.shipping || customer;
    let priceOptions = this.getPriceOptions(this.getSelectedSlab());

    return (
      <div className="invoice-page bg-light">
        <Spinner isVisible={this.state.loading} />
        <div className="container mt-4 py-5 bg-white invoice-container">
          <div
            className="py-3 px-3 bg-light"
            style={{ border: "0px solid gray" }}
          >
            <button
              className="btn btn-outline-secondary"
              onClick={this.handleClose}
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Back
            </button>
            {!order.deleted_at && (
              <div className="float-right">
                <button
                  className="btn btn-outline-secondary ml-4"
                  onClick={this.handleUpdateOrder}
                >
                  <i className="fa fa-save mr-2"></i>Save
                </button>
              </div>
            )}
            {order.deleted_at && (
              <div className="float-right text-danger pr-5">
                <h1>DELETED</h1>
              </div>
            )}
          </div>
          <div className="row my-4">
            {/* <div className="col-md-3">
                    <div className="invoice-title" >INVOICE</div>
                </div> */}
            <div className="bill-to-box col-md-4">
              <div className="text-weight-bold high-line">BILL TO:</div>
              <div className="">
                <div>{customer.company} </div>
                <div>
                  {customer.street} <div></div>
                  {customer.city}, {customer.state} {customer.zip}
                </div>
                <div>{customer.phone}</div>
                <div></div>
              </div>
            </div>
            <div className="bill-to-box col-md-5">
              <div className="text-weight-bold high-line">SHIP TO:</div>
              <div>
                <div>{shipping.name}</div>
                <div>
                  {shipping.street} <div></div>
                  {shipping.city}, {shipping.state} {shipping.zip}
                </div>
                <div>{shipping.phone}</div>
                <div></div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="invoice-number2">
                <div>
                  Invoice # <span>{order.id} </span>
                </div>
                <div className="text-bottom3">
                  Date: <span>{toDate(order.created_at)}</span>
                </div>
                <div className="text-bottom3">
                  Rep: <span>{order.sales_rep}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="bg-secondary text-white p-1 mb-2">
              <h4>Order Items</h4>
            </div>
            <table className="invoice-table">
              <thead>
                <tr className="text-left">
                  <th>#</th>
                  <th>Description</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {/* <!-- items >>---> */}
                {order.items &&
                  order.items.map((item) => (
                    <tr className="item" key={item.id}>
                      <td>{Number(item.index) + 1}</td>
                      <td>
                        {item.slab ? item.slab.material : ""} - {item.slab_num}
                        &nbsp;&nbsp;&nbsp;&nbsp;{item.sqft} sf&sup2; (
                        {item.slab && toFixed(item.slab.length)}&times;
                        {item.slab && toFixed(item.slab.height)})
                      </td>
                      <td>
                        {toBool(item.isbundle) ? (
                          <span>{item.bundle_price}*</span>
                        ) : (
                          <span>{item.unit_price}</span>
                        )}
                      </td>
                      <td>
                        {formatMoney(item.total)}
                        <button
                          className="btn btn-outline-light pull-right"
                          onClick={() => this.handleRemoveOrderItem(item.id)}
                        >
                          <i className="fa fa-times text-danger"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                <tr className="item">
                  <td colSpan={2}>
                    <VirtualizedSelect
                      options={this.state.slabOptions}
                      onChange={this.handleSlabOptionChange}
                      value={this.state.selectedSlabOption}
                    />
                  </td>
                  <td>
                    <Dropdown
                      placeholder="price"
                      selection
                      options={priceOptions}
                      defaultValue={priceOptions[0].value}
                      onChange={this.handlePriceOptionsChange}
                    />
                  </td>
                  <td>
                    {/* {formatMoney(item.total)} */}
                    <button
                      className="btn btn-outline-success pull-right"
                      onClick={this.handleAddOrderItem}
                    >
                      <i className="fa fa-plus-square"></i>
                      <span className="ml-2 d-none  d-lg-inline">Add</span>
                    </button>
                  </td>
                </tr>
                {order.discounts.map((item) => (
                  <tr key={item.name} className="item">
                    <td></td>
                    <td>
                      Discount <span className="small">({item.name})</span>
                    </td>
                    <td></td>
                    <td>-{formatMoney(item.amount)}</td>
                  </tr>
                ))}
                {order.fees &&
                  order.fees.map((item) => (
                    <tr key={item.name} className="item">
                      <td></td>
                      <td>
                        Fee <span className="small">({item.name})</span>
                      </td>
                      <td></td>
                      <td>+{formatMoney(item.amount)}</td>
                    </tr>
                  ))}
                {order.total_fees > 0 && (
                  <tr className="item">
                    <td></td>
                    <td>Total Fees</td>
                    <td></td>
                    <td>{formatMoney(order.total_fees)}</td>
                  </tr>
                )}
                {order.tax > 0 && (
                  <tr className="item">
                    <td></td>
                    <td>Tax {toFixed(order.tax_rate * 100)}% </td>
                    <td></td>
                    <td>{formatMoney(order.tax)}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              {order.items.length > 0 && (
                <div className="bg-lighter mt-3 p-3 text-right col-md-6 offset-md-6">
                  <span>TOTAL</span>
                  <span className="ml-3 font-weight-bold">
                    {formatMoney(order.total)}
                  </span>
                </div>
              )}
            </div>
          </div>
          {/* <!-- << table section ---> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.match.params.id;
  return {
    order: state.orders ? state.orders.find((item) => item.id == id) : initial,
    isAuthenticated: state.isAuthenticated,
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(OrderEdit);

const summary = (payment) => {
  if (payment.confirmation) return payment.confirmation;
  if (payment.check_num) return payment.check_num + " " + payment.bank;
  return "";
};

const toDate = (date) => {
  return moment(date).format("MM/DD/YYYY");
};
