import React, { Component } from "react";
import { fetchPayments } from "./../services/apiService";
import { formatMoney } from "./../services/utils";
import moment from "moment";

export default class Payments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      search: "",
      months: 1,
    };
  }

  async componentDidMount() {
    const result = await fetchPayments();
    if (result) {
      this.setState({ payments: result });
    }
  }

  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value,
    });
  };

  filter = (data) => {
    if (data) {
      const minDate = this.getMinDate(this.state.months);
      const filtered = data.filter((item) => {
        return (
          item.order_id.toString().startsWith(this.state.search) &&
          Date.parse(item.paid_date) > minDate
        );
      });

      return filtered;
    }
    return [];
  };

  getMinDate = (months) => {
    if (months > 0) {
      let date = new Date();
      date.setMonth(date.getMonth() - months);
      return date.getTime();
    }
    return 0;
  };

  render() {
    const pays = this.filter(this.state.payments);

    return (
      <div className="page-div">
        <div className="container">
          <h4>Payments</h4>
          <div className="row mb-2">
            <div className="col-sm-3 mb-2">
              <input
                type="search"
                className="form-control"
                name="search"
                placeholder="Search"
                value={this.state.search}
                onChange={this.handleChange}
              ></input>
            </div>
            <div className="col-sm-3 mb-2">
              <select
                name="months"
                className="form-control"
                onChange={this.handleChange}
              >
                <option value="1">1 Month</option>
                <option value="3">3 Months</option>
                <option value="6">6 Months</option>
                <option value="0">All</option>
              </select>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Num</th>
                  <th>Invoice#</th>
                  <th>Date</th>
                  <th>Invoiced</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {pays.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.order_id}</td>
                    <td>{moment(item.paid_date).format("MM/DD/YYYY")}</td>
                    <td>{formatMoney(item.invoice_amount, 2, "")}</td>
                    <td>{formatMoney(item.amount, 2, "")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
