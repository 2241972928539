import React, { Component } from "react";
import {
  graniteInitialState,
  isEmptyObj,
  fixImageNotFound,
  imageurl,
  nameToSlug,
} from "../services/utils";
import { imageNoFoundNameW, CONSTS } from "./../constants";

class GraniteEditDialog extends Component {
  constructor(props) {
    super(props);
    this.state = graniteInitialState;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const granite = !isEmptyObj(nextProps.granite)
      ? nextProps.granite
      : graniteInitialState;
    this.setState(granite);
  }

  handleCreate = () => {
    const file = this.state.fileToUpload;
    this.setState({ fileToUpload: null });
    this.props.createItem(this.state, file);
  };

  handleSave = () => {
    const file = this.state.fileToUpload;
    this.setState({ fileToUpload: null });
    this.props.updateItem(this.state, file);
  };

  fileSelected = () => {
    const reader = new FileReader();
    const image = this.imagePreviewer;
    const file = this.fileBrowser.files[0];

    if (!file) return;

    reader.onload = () => {
      image.src = reader.result;
    };
    reader.readAsDataURL(file);

    const fd = new FormData();
    fd.append("file", file, file.name);
    this.setState({ fileToUpload: fd });
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const newValue = name === "name" ? value.toUpperCase() : value;

    this.setState({
      [name]: newValue,
    });
  };

  handleFieldBlur = () => {
    const slug = nameToSlug(this.state.name);
    this.setState({ slug });
  };

  render() {
    const readonly = false;
    const path = CONSTS.server.replace("api/", "");

    return (
      <div
        className="modal fade"
        id="editorDialog"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editorDialogLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editorDialogLabel">
                {this.state.id ? "Edit Type" : "Add Type"}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                className="form-horizontal ml-2"
                style={{ backgroundColor: "white" }}
              >
                <p style={{ color: "#DC3545", fontSize: "13px" }}>
                  Make sure to crop the image to size 16:9 ratio and remove
                  background.
                  <a href={path + "/media/crop_image.mp4"} target="_blank">
                    {" "}
                    demo
                  </a>
                </p>
                <div className="row mb-3">
                  <img
                    style={{ width: 352, height: 198, margin: "0 auto" }}
                    src={imageurl(this.state.filename)}
                    alt={imageurl(this.state.filename)}
                    ref={(me) => (this.imagePreviewer = me)}
                    onError={(e) => fixImageNotFound(e, imageNoFoundNameW)}
                  />

                  <button
                    type="button"
                    className="form-control btn btn btn-secondary"
                    onClick={() => this.fileBrowser.click()}
                  >
                    Choose new image
                  </button>
                  <input
                    className="d-none"
                    type="file"
                    ref={(me) => (this.fileBrowser = me)}
                    onChange={this.fileSelected}
                  />
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Material</label>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className={
                        "form-control " +
                        (readonly ? "form-control-plaintext " : "")
                      }
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      onBlur={this.handleFieldBlur}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Image File</label>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      name="filename"
                      value={this.state.filename}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Description</label>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      value={this.state.description || ""}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Group</label>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="1"
                      name="group_num"
                      value={this.state.group_num || ""}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Slug</label>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="granite-name"
                      name="slug"
                      value={this.state.slug || ""}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">Type</label>
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="granite"
                      name="material_type"
                      value={this.state.material_type || "granite"}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {this.state.id ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.handleSave}
                >
                  Save
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={this.handleCreate}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GraniteEditDialog;
