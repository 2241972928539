import React, { Component } from 'react'
import Footer from './Home/Footer'
import GraniteSection from './Home/GraniteSection'
import Contact from './Home/Contact';
import Call from './Home/Call';
import HomeNavBar from './HomeNavBar';
import Jumbotron2 from './Home/Jumbotron2'; 
import About from './Home/About';




export default class Home extends Component {
  render() {
    return ( 
    <div>
      <HomeNavBar />
      <Jumbotron2 />
      <About />
      <GraniteSection />
      <Call />
      <Contact />
      <Footer />
    </div>);
  }
}
