import React, { Component } from "react";
import debounce from "lodash.debounce";
import { connect } from "react-redux";
import { CONSTS, imageNoFoundNameW } from "../constants";
import {
  deleteGranite,
  fetchGranites,
  createGranite,
  updateGranite,
  uploadImage,
} from "../services/apiService";
import {
  deleteGraniteAction,
  fetchedGranitesAction,
  updateGraniteAction,
  createGraniteAction,
} from "../actions/index";
import { showDeleteConfirmation, showAlert } from "../components/dialogs";
import GraniteEditDialog from "../components/GraniteEditDialog";
import { fixImageNotFound } from "../services/utils";
import { Link } from "react-router-dom";

class GraniteList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      selectedItem: {},
      loading: true,
      search: "",
      granites: [],
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.updateSelectedItem = this.updateSelectedItem.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
  }

  async componentDidMount() {
    if (!this.props.granites) {
      const data = await fetchGranites();
      this.props.dispatch(fetchedGranitesAction(data));
      this.setState({ ready: true, loading: false, granites: data });
    } else {
      this.setState({
        ready: true,
        loading: false,
        granites: this.props.granites,
      });
    }
  }

  updateSelectedItem(item) {
    this.setState({ selectedItem: item });
  }

  async uploadImage(item, image) {
    if (image) {
      const result = await uploadImage(image, "", 2);
      if (!result.error) item.filename = result.filename;
      return;
    }
  }

  async handleCreate(item, file) {
    this.showSpinner();

    await this.uploadImage(item, file);
    const res = await createGranite(item);

    if (!res.error) {
      this.props.dispatch(createGraniteAction(res));
      showAlert("Granite was created", "success");
    } else {
      showAlert(res.message, "error");
    }
    this.hideSpinner();
  }

  async handleUpdate(item, file) {
    this.showSpinner();

    await this.uploadImage(item, file);
    const res = await updateGranite(item);

    if (!res.error) {
      this.props.dispatch(updateGraniteAction(res));
      this.updateLocalGranite(res);
      showAlert("Granite updated", "success");
    } else {
      showAlert(res.message, "error");
      console.log(res.message);
    }

    this.hideSpinner();
  }

  updateLocalGranite = (granite) => {
    const granites = this.state.granites.map((item, index) => {
      if (parseInt(item.id) !== parseInt(granite.id)) {
        return item;
      }
      return { ...granite };
    });
    this.setState({ granites });
  };

  async handleDelete(id) {
    showDeleteConfirmation(async () => {
      this.showSpinner();
      const res = await deleteGranite(id);

      if (!res.error) {
        this.props.dispatch(deleteGraniteAction(res));
        showAlert("Granite deleted", "success");
      } else {
        showAlert(res.message, "error");
      }

      this.hideSpinner();
    });
  }

  handleSearchChange = (event) => {
    this.setState({ search: event.target.value });
    this.debouncedSearch();
  };

  debouncedSearch = debounce(async () => {
    this.showSpinner();

    const searchStr = this.state.search || "";
    const filtered = this.props.granites.filter((g) => {
      const name = g.name || "";
      return name.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1;
    });

    this.setState({ granites: filtered });
    this.hideSpinner();
  }, 500);

  showSpinner() {
    this.setState({ loading: true });
  }

  hideSpinner() {
    this.setState({ loading: false });
  }

  render() {
    const isAuthenticated = this.props.auth.isAuthenticated();

    return (
      <div className="page-div">
        <div className="container">
          <div>
            {this.state.loading && <div className="spinner"></div>}
            <h3>Types of Granite</h3>
            {isAuthenticated && (
              <div className="mb-3 row">
                <button
                  className="btn btn-secondary"
                  data-toggle="modal"
                  data-target="#editorDialog"
                  onClick={() => this.updateSelectedItem({})}
                >
                  Add New Type
                </button>
                <Link
                  className="btn btn-secondary ml-2"
                  to="/tools/granites/color"
                >
                  Color Sorting
                </Link>
                <Link
                  className="btn btn-secondary ml-2 mr-2"
                  to="/tools/pklist-parser"
                >
                  Packlist Parser
                </Link>
                <input
                  type="search"
                  className="form-control"
                  name="search"
                  placeholder="Search"
                  style={{ width: "200px" }}
                  value={this.state.search}
                  onChange={this.handleSearchChange}
                  onKeyPress={this.handleKeyPress}
                />
              </div>
            )}
          </div>
          <ul className="list-group">
            {this.state.granites &&
              this.state.granites.map((granite) => (
                <li className="list-group-item" key={granite.id}>
                  <div className="card flex-row flex-wrap">
                    <div className="card-header border-0">
                      <img
                        className="granite-card"
                        src={CONSTS.imgBaseUrl + granite.filename}
                        alt=""
                        onError={(e) => {
                          fixImageNotFound(e, imageNoFoundNameW);
                        }}
                      />
                    </div>
                    <div className="card-block px-2" style={{ paddingTop: 30 }}>
                      <h4 className="card-title">{granite.name}</h4>
                      {this.props.auth.isAuthenticated() && (
                        <div>
                          <button
                            className="btn  btn-outline-primary btn-sm ml-2"
                            data-toggle="modal"
                            data-target="#editorDialog"
                            onClick={() => this.updateSelectedItem(granite)}
                          >
                            Edit
                          </button>
                          <button
                            className="btn btn-outline-danger btn-sm ml-2"
                            onClick={() => this.handleDelete(granite.id)}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
          </ul>
          <GraniteEditDialog
            granite={this.state.selectedItem}
            updateItem={this.handleUpdate}
            createItem={this.handleCreate}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  granites: state.granites,
  hasGranites: state.hasGranites,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(GraniteList);
