import React, { Component } from 'react'
import { customerInitialState } from './../services/utils';
import isEmpty from 'lodash.isempty';


export default class CustomerEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: customerInitialState,
            isvalid: false,
            error: '',
            showShipping: false,
            unsaved: false
        };
    }

    handleChange = (event) => {
        const target = event.target; 
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;   
        if(name === 'state')
        {
            value = target.value.toUpperCase();
        }

        if(name === 'street' || name === 'name' || name === 'city')
        {
            value = target.value.toCapitalizedCase();
        }
        
        this.setState({
            customer: {
                ...this.state.customer,
                [name]: value
            },
            unsaved: true
        }, () => { this.isFormValid() });
    }

    handleShippingChange = (event) => {
        const target = event.target; 
        let value = target.value;
        const name = target.name;
        if(name === 'state')
        {
            value = target.value.toUpperCase();
        }

        if(name === 'street' || name === 'name' || name === 'city')
        {
            value = target.value.toCapitalizedCase();
        }

        let shipping = {
            ...this.state.customer.shipping,
            [name]: value
        }

        this.setState({
            customer: {
                ...this.state.customer,
                shipping: shipping
            },
            unsaved: true
        }, () => { this.isFormValid() });
    }

    toCapitalizedCase = (str) => {
        return str.replace(/\w\S*/g, function(txt){
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    componentWillReceiveProps(nextProps) {
        //console.log("componentWillReceiveProps", nextProps);
        if(this.state.unsaved) return;
        const customer = !isEmpty(nextProps.customer)? nextProps.customer: customerInitialState;
        this.setState({customer, isvalid: false});
    }

    handleCreate = () => {
        this.props.createItem(this.state.customer);
        this.setState({unsaved: false});
    }

    handleSave = () => {
        this.props.updateItem(this.state.customer);
        this.setState({unsaved: false});
    }

    handleClose = () => {
        this.setState({customer: customerInitialState, unsaved: false});
    }

    isFormValid = () => {
        let result = [];
        const customer = this.state.customer;
        if(!this.isEmailValid(customer.email)){
            result.push('Invalid Email');
        }
        if(!this.isStringLength(customer.name, 1)) {
            result.push('Invalid Name');
        }
        if(!this.isValidPhone(customer.phone)) {
            result.push('Invalid Phone #');
        }
        if(!this.isStringLength(customer.street)) {
            result.push('Invalid Address');
        }
        if(!this.isStateValid(customer.state)) {
            result.push('Invalid State Name (UT)');
        }
        if(!this.isZipValid(customer.zip)) {
            result.push('Invalid Zip Code');
        }

        if(result.length === 0){
            this.setState({error: '', isvalid: true})
            return true;
        }
        this.setState({error: result.toString(), isvalid: false });
        return false;
    }

    isStateValid = (value) => {
        if(value)
        {
            const isvalid = /^([A-Z]{2})$/.test(value);
            return isvalid;
        }
        return false;
    }

    isStringLength = (value, len=6) => {
        let text = value || '';
        if(text.length > len) return true;
        return false;
    }
    
    isEmailValid = (value) => {
        if(!value) return false;
        const isvalid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        return (isvalid)? true: false;
    }
    
    isZipValid = (value) => {
        if(value)
        {
             // eslint-disable-next-line
            var isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
            return isValidZip;
        }
        return false;
    }

    isValidPhone = (value) => {
        if(value) {
            // eslint-disable-next-line
            const isvalid = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value);
            
            return isvalid;
        }
        return false;
    }

    render() {
        const customer = this.state.customer;
        const shipping = customer.shipping || customerInitialState.shipping;
        
        return (
            <div ref={(e) => this.modal = e } className="modal fade" id="editorDialog" tabIndex="-1" role="dialog" aria-labelledby="editorDialogLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="editorDialogLabel">{customer.id? 'Edit Customer': 'Create Customer'}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                <div className="modal-body">
                    <form>
                    <div className="row">
                            <div className="form-group  col-md-12">
                                <label  htmlFor="name">Company</label>
                                <input type="text" className="form-control" name="company" 
                                value={customer.company} 
                                onChange={this.handleChange} 
                                placeholder="Company" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group  col-md-12">
                                <label  htmlFor="name">Contact</label>
                                <input type="text" className="form-control" name="name" 
                                value={customer.name} 
                                onChange={this.handleChange} 
                                placeholder="Joe" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label  htmlFor="email">Email</label>
                                <input type="text" className="form-control" name="email" value={customer.email || '' } onChange={this.handleChange} placeholder="Email" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label  htmlFor="phone">Phone</label>
                                <input type="tel" className="form-control" name="phone" value={customer.phone} onChange={this.handleChange} placeholder="888-000-9999" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label  htmlFor="street">Address</label>
                                <input type="text" className="form-control" name="street" value={customer.street} onChange={this.handleChange} placeholder="1234 Main Street" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label  htmlFor="city">City</label>
                                <input type="text" className="form-control" name="city"
                                value={customer.city} onChange={this.handleChange} 
                                placeholder="Provo"/>
                            </div>
                            <div className="form-group col-md-2">
                                <label  htmlFor="state">State</label>
                                <input name="state" className="form-control" value={customer.state} placeholder="UT" onChange={this.handleChange} />
                            </div>
                            <div className="form-group col-md-4">
                                <label  htmlFor="zip">Zip</label>
                                <input type="text" className="form-control" name="zip" value={customer.zip} onChange={this.handleChange} placeholder="84000" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-inline form-group col-md-12">
                                <label  htmlFor="tax_exempted">Tax Exempted</label>
                                <input type="checkbox" className="ml-3 form-control" name="tax_exempted" checked={customer.tax_exempted} onChange={this.handleChange}  />
                            </div>
                        </div>
                        <div className='my-2' onClick={() => this.setState({showShipping: !this.state.showShipping}) }>
                            <span className='font-weight-bold'>Shipping Info: </span> <a href="#hide">{this.state.showShipping? "hide": "show"}</a>
                        </div>
                        {
                        <div className='border border-secondary p-3 rounded text-secondary mb-2' style={{display: this.state.showShipping? 'block': 'none'  }}>
                        {/* shipping address ================================================================*/}
                        <div className="row">
                            <div className="form-group  col-md-12">
                                <label  htmlFor="name">Contact Name</label>
                                <input type="text" className="form-control" 
                                name="name" 
                                value={shipping.name} 
                                onChange={this.handleShippingChange} 
                                 />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label  htmlFor="phone">Phone</label>
                                <input type="tel" className="form-control" 
                                    name="phone" 
                                    value={shipping.phone} 
                                    onChange={this.handleShippingChange} 
                                    />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label  htmlFor="street">Address</label>
                                <input type="text" className="form-control" 
                                name="street" 
                                value={shipping.street} 
                                onChange={this.handleShippingChange} 
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label  htmlFor="city">City</label>
                                <input type="text" className="form-control" 
                                name="city"
                                value={shipping.city} 
                                onChange={this.handleShippingChange} 
                                />
                            </div>
                            <div className="form-group col-md-2">
                                <label  htmlFor="state">State</label>
                                <input className="form-control" 
                                name="state" 
                                value={shipping.state} 
                                onChange={this.handleShippingChange} />
                            </div>
                            <div className="form-group col-md-4">
                                <label  htmlFor="zip">Zip</label>
                                <input type="text" className="form-control" 
                                name="zip" 
                                value={shipping.zip} 
                                onChange={this.handleShippingChange} />
                            </div>
                        </div>
                        </div>
                        }
                    </form>
                    { this.state.error && <div className='alert alert-danger'>{ this.state.error }</div> }
                </div>
                {/* body */}
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>Close</button>
                    { 
                        customer.id > 0? 
                        <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.state.isvalid}
                            onClick={ (e) => this.handleSave(e) }>Save
                        </button>:
                        <button type="button" className="btn btn-primary" data-dismiss="modal"  disabled={!this.state.isvalid}
                            onClick={ this.handleCreate }>Create
                        </button>
                    }
                </div>

                </div>
                </div>
            </div>
        )
    }
}

