import React from 'react'

const Call = () => {
  return (
    <div className="call">
        <div className="container">
            <h3>Call Now and order your Slabs</h3>
            <a className='contacts' href="tel:8016879500">(801) 687-9500</a>
        </div>
    </div>
  )
}

export default Call
