import React from "react";
import Slider from "react-slick";
import { CONSTS } from './../../constants';

/*** https://react-slick.neostack.com/docs/api/#methods  ***/

const filepath = (filename) => {
    return CONSTS.imgBaseUrl+filename;
}
export default class Carousel extends React.Component {  

  goTo(index)
  {
    this.slider.slickGoTo(index);
    this.slider.slickPause();
  }

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      centerMode:true,
      centerPadding: '0',
      pauseOnHover:true, 
    };
    var {granites} = this.props;
    return (
      <div className='granite-slider'>
      <Slider {...settings}  autoplay="true" ref={c => (this.slider = c)}>
      {
          granites.map((granite) => (<div style={{paddingLeft: '50px'}} key={granite.id} >
              <img style={{width: '100%'}} src={filepath(granite.filename)} alt='granite' />
              <h3>{granite.name}</h3>
              </div>) )
      }
      </Slider>
      </div>
    );
  }
}