import React, { Component } from "react";
import { fetchSlabs, fetchOrders } from "../services/apiService";
import { Spinner } from "../components/dialogs";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";
import moment from "moment";

export default class SlabsReport extends Component {
  state = {
    slabs: [],
    orders: [],
    loading: true,
    view: true,
    slabSold: [],
  };

  async componentDidMount() {
    const slabs = await fetchSlabs();
    const orders = await fetchOrders();
    this.setState({ slabs, orders, loading: false });
    this.processSlabSold();
  }

  processSlabSold = () => {
    let tmp = [];
    this.state.orders.map((order) =>
      order.items.map((item) =>
        tmp.push({
          id: item.id,
          order: item.order_id,
          slab: item.slab_num,
          material: item.slab ? item.slab.material : "",
          price: item.total,
          date: moment(order.created_at).format("L"),
          rep: order.sales_rep,
        })
      )
    );
    this.setState({ slabSold: tmp });
  };

  soldRows = () => {
    const result = this.getSlabsFromOrders(this.state.orders);
    const slabs = result.groupBy("material");
    const keys = Object.keys(slabs).sort((a, b) => a.localeCompare(b));
    let soldTotal = 0;

    const soldRows = keys.map((key) => {
      soldTotal += slabs[key].length;

      return (
        <tr key={key}>
          <td>{key}</td>
          <td>{slabs[key].length}</td>
        </tr>
      );
    });
    return { soldRows, soldTotal };
  };

  getSlabsFromOrders = (orders) => {
    const slabs = [];
    const count = orders.length;
    for (let i = 0; i < count; i++) {
      const items = orders[i].items;
      const count2 = items.length;

      for (let j = 0; j < count2; j++) {
        const slab = items[j].slab || {};
        slabs.push({ material: slab.material, count: 1 });
      }
    }

    return slabs;
  };

  toggleView = () => {
    const view = !this.state.view;
    this.setState({ view });
  };

  render() {
    const slabs = this.state.slabs.groupBy("material");
    const keys = Object.keys(slabs).sort((a, b) => a.localeCompare(b));
    let total = 0;

    const rows = keys.map((key) => {
      total += slabs[key].length;

      return (
        <tr key={key}>
          <td>{key}</td>
          <td>{slabs[key].length}</td>
        </tr>
      );
    });

    const { soldRows, soldTotal } = this.soldRows();

    return (
      <div className="page-s">
        <Spinner isVisible={this.state.loading} />
        <div className="row">
          <div className="bg-grey col-sm-2" style={{ height: "100vh" }}>
            <div className="list-group list-group-flush">
              <Link
                className="list-group-item list-group-item-action"
                to="/tools/inventory"
              >
                Inventory
              </Link>
              {/* <Link className='list-group-item list-group-item-action' to='/reports/lastweek' >Last Week</Link> */}
              <Link
                className="list-group-item list-group-item-action"
                to="/reports/sales"
              >
                Sales
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                to="/reports/powerbi"
              >
                Power BI
              </Link>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="my-3">
              <div className="float-left">
                <Button
                  className="btn-secondary btn-sm"
                  onClick={this.toggleView}
                >
                  {this.state.view ? "Details" : "Summary"}
                </Button>
                {!this.state.view && (
                  <CSVLink
                    className="btn btn-primary btn-sm ml-2"
                    filename="slab_sales.csv"
                    data={this.state.slabSold}
                  >
                    Export
                  </CSVLink>
                )}
              </div>
              <h5>Slab Count</h5>
            </div>
            {this.state.view && (
              <SlabsSummary
                total={total}
                rows={rows}
                soldRows={soldRows}
                soldTotal={soldTotal}
              />
            )}
            {!this.state.view && (
              <SlabSoldList slabSold={this.state.slabSold} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const SlabSoldList = (props) => {
  return (
    <div className="">
      <div className="float-left"></div>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Order</th>
              <th>Number</th>
              <th>Material</th>
              <th>Price</th>
              <th>Date</th>
              <th>Rep</th>
            </tr>
          </thead>
          <tbody>
            {props.slabSold.map((item) => (
              <tr key={item.id}>
                <td>{item.order}</td>
                <td>{item.slab}</td>
                <td>{item.material}</td>
                <td>{item.price}</td>
                <td>{item.date}</td>
                <td>{item.rep}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    // https://www.w3schools.com/w3css/w3css_sidebar.asp
  );
};

const SlabsSummary = (props) => {
  const { rows, total, soldRows, soldTotal } = props;

  return (
    <div className="row">
      <div className="col-md-6">
        <h6>Available</h6>
        <table className="table table-striped table-sm">
          <thead className="thead-dark">
            <tr>
              <th>Material</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {rows}
            <tr>
              <th>Total</th>
              <th>{total}</th>
            </tr>
          </tbody>
        </table>
      </div>
      {/* slabs sold count */}
      <div className="col-md-6">
        <h6>Sold</h6>
        <table className="table table-striped table-sm">
          <thead className="thead-dark">
            <tr>
              <th>Material</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {soldRows}
            <tr>
              <th>Total</th>
              <th>{soldTotal}</th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
