import React from 'react'

const About = () => {
  return (
      <div className="page" id="about">
        <div className="container">
            <h2>About Us</h2>
            <div className="row">
                <div>
                    <img src="/images/cube-of- granite.png" alt="cube" />
                </div>
                <div className="content col-sm-6"> 
                <p>
                    We are a local granite, quartzite, marble distributor that strive to sell good quality slabs with a reasonable profit. 
                    Our goal is to have a long-lasting relationship with you and your company. 
                </p>   
                <p>
                    We sell high-quality products at a great price, in most cases offering same day or next day delivery.
                </p>
                <p>
                    We would like to be the distributor that when you think about granite, “Granite World” will be the first name to come to mind.
                </p>
                <p>
                    We look forward to doing business with you.
                </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About
