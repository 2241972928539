import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { CSVLink } from "react-csv";
import { fetchOrders } from "./../services/apiService";
import { formatMoney, invertDirection } from "./../services/utils";
import DatePicker from "react-datepicker";
import orderBy from "lodash.orderby";
import { Spinner } from "../components/dialogs";
import { fetchedOrdersAllAction } from "./../actions/index";

const headers = [
  { label: "Number", key: "id" },
  { label: "Type", key: "type" },
  { label: "Invoiced", key: "amount" },
  { label: "Paid", key: "paid" },
  { label: "Created", key: "created_at" },
  { label: "Deleted", key: "deleted_at" },
];

class OrdersAll extends Component {
  state = {
    orders: [],
    loading: true,
    search: "",
    csvData: [],
    date: null,
    columnToSort: "id",
    sortDirection: "asc",
  };

  async componentDidMount() {
    this.showSpinner(true);
    if (!this.props.ordersAll) {
      const data = await fetchOrders(null, "all");
      this.props.dispatch(fetchedOrdersAllAction(data));
    }

    this.showSpinner(false);
  }

  dateFormat = (date) => {
    if (!date) return "";
    return moment(date).format("M/D/YYYY");
  };

  reduce = (orders) => {
    let data = [];
    const ordersCount = orders.length;
    for (let i = 0; i < ordersCount; i++) {
      const order = orders[i];
      data.push({
        id: order.id,
        type: "Order",
        amount: order.total,
        paid: "",
        created_at: order.created_at,
        deleted_at: order.deleted_at,
        order_date: order.created_at,
      });

      const payments = order.payments;

      const paymentCount = payments.length;
      for (let j = 0; j < paymentCount; j++) {
        const payment = payments[j];
        data.push({
          id: payment.order_id,
          type: "Payment",
          amount: "",
          paid: payment.amount,
          created_at: payment.paid_date,
          deleted_at: payment.deleted_at,
          order_date: order.created_at,
        });
      }
    }
    // return data.sort((a, b) => a.id - b.id);
    return data;
  };

  filter = (data) => {
    if (!data) return [];

    const date = moment(this.state.date).format("YYYY-MM-DD");
    const search = this.state.search;

    const result = data.filter((item) => {
      if (this.state.date) {
        return date === moment(item.order_date).format("YYYY-MM-DD");
      }
      if (search) {
        return item.id.toString() === this.state.search;
      }
      return true;
    });
    return orderBy(result, this.state.columnToSort, this.state.sortDirection);
  };

  getClassName = (data) => {
    if (!data) return "";
    return classNames({
      "text-light bg-danger": !!data.deleted_at,
      "text-white bg-success": data.type.toLowerCase() === "payment",
    });
  };

  handleChange = (event) => {
    this.setState({
      search: event.target.value,
    });
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  showSpinner = (value) => {
    this.setState({ loading: value });
  };

  calculateTotals = (list) => {
    const totals = list.reduce(
      (acc, item) => {
        if (!item.deleted_at && item.type === "Order") {
          acc[0] += +item.amount;
        }
        if (!item.deleted_at && item.type === "Payment") {
          acc[1] += +item.paid;
        }
        return acc;
      },
      [0, 0]
    );
    return {
      amount: totals[0],
      paid: totals[1],
    };
  };

  exportList = (data) => {
    this.setState({ csvData: data });
  };

  formatMoney = (total) => {
    if (!total) return "";
    return formatMoney(total);
  };

  sortColumn = (colname) => {
    this.setState((state) => ({
      columnToSort: colname,
      sortDirection:
        state.columnToSort === colname
          ? invertDirection(this.state.sortDirection)
          : "asc",
    }));
  };

  goToOrder = (orderId) => {
    this.props.history.push(`/orders/${orderId}?redirect=all`);
  };

  render() {
    const orders = this.props.ordersAll || [];
    const list = this.filter(this.reduce(orders));
    const totals = this.calculateTotals(list);

    return (
      <div className="page-s">
        <div className="container">
          <Spinner isVisible={this.state.loading} />
          <h5>Orders-Payments</h5>
          <div className="row">
            <div className="col-sm-3 mb-2">
              <input
                type="search"
                className="form-control"
                name="search"
                placeholder="Search order"
                value={this.state.search}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-sm-3 mb-2">
              <DatePicker
                className="form-control"
                name="date"
                selected={this.state.date}
                onChange={this.handleDateChange}
                placeholderText="Date"
                isClearable={true}
              />
            </div>
            <div className="mt-3">
              <CSVLink
                data={this.state.csvData}
                headers={headers}
                onClick={() => this.exportList(list)}
              >
                Export
              </CSVLink>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th onClick={() => this.sortColumn("id")}>
                    Order# <SortArrow {...this.state} name="id" />
                  </th>
                  <th onClick={() => this.sortColumn("type")}>
                    Type <SortArrow {...this.state} name="type" />
                  </th>
                  <th onClick={() => this.sortColumn("amount")}>
                    Invoiced <SortArrow {...this.state} name="amount" />
                  </th>
                  <th onClick={() => this.sortColumn("paid")}>
                    Paid <SortArrow {...this.state} name="paid" />
                  </th>
                  <th onClick={() => this.sortColumn("created_at")}>
                    Created <SortArrow {...this.state} name="created_at" />
                  </th>
                  <th onClick={() => this.sortColumn("deleted_at")}>
                    Deleted <SortArrow {...this.state} name="deleted_at" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {list.map((item, i) => (
                  <tr
                    key={i}
                    className={this.getClassName(item) + " click-handle"}
                    onClick={() => this.goToOrder(item.id)}
                  >
                    <td> {item.id} </td>
                    <td> {item.type} </td>
                    <td> {this.formatMoney(item.amount)} </td>
                    <td> {this.formatMoney(item.paid)} </td>
                    <td> {this.dateFormat(item.created_at)} </td>
                    <td className="text-light">
                      {" "}
                      {this.dateFormat(item.deleted_at)}{" "}
                    </td>
                  </tr>
                ))}
                <tr className="bg-light font-weight-bold">
                  <td>Totals</td>
                  <td> </td>
                  <td> {formatMoney(totals.amount)}</td>
                  <td> {formatMoney(totals.paid)} </td>
                  <td> </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const SortArrow = (props) => {
  if (props.columnToSort !== props.name) return "";
  if (props.sortDirection === "asc")
    return <i className="m-1 fa fa-sort-down"></i>;
  return <i className="m-1 fa fa-sort-up"></i>;
};

const mapStateToProps = (state) => ({
  ordersAll: state.ordersAll,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(OrdersAll);
