import React, { Component } from "react";
import { uploadSlabFile } from "./../services/apiService";

export default class SlabsLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      httpResponse: null,
      slabs: null,
      loading: false,
    };
  }

  async fileUploadHandler() {
    if (!this.state.selectedFile) return;

    this.showSpinner();
    const fd = new FormData();
    fd.append(
      "textfile",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    const resp = await uploadSlabFile(fd);
    this.setState({ slabs: resp.data });

    this.hideSpinner();
    this.setState({ httpResponse: resp.message });
  }

  fileSelectedHandler(event) {
    console.log(event.target.files[0].name);
    this.setState({
      selectedFile: event.target.files[0],
    });
  }

  showSpinner() {
    this.setState({ loading: true });
  }

  hideSpinner() {
    this.setState({ loading: false });
  }

  render() {
    return (
      <div className="page-div">
        <div className="container">
          {this.state.loading && <div className="spinner"></div>}
          <div className="row">
            <div
              style={{
                display: this.state.httpResponse ? "block" : "none",
                margin: "0 auto",
                position: "fixed",
                left: "40%",
                zIndex: 10,
              }}
              className="alert alert-info"
              role="alert"
            >
              {this.state.httpResponse}

              <button
                type="button"
                className="ml-2 mb-1 close"
                aria-label="Close"
                onClick={() => this.setState({ httpResponse: null })}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <h3>Slab List Loader</h3>
          <div className="row">
            <div className="col-md-5 m-2">
              <input
                type="file"
                style={{ display: "block" }}
                ref={(fi) => (this.fileInput = fi)}
                onChange={(e) => this.fileSelectedHandler(e)}
              />
            </div>
            <div className="col-md-6 m-2">
              <button
                className={
                  this.state.selectedFile == null
                    ? "btn btn-default"
                    : "btn btn-primary"
                }
                onClick={this.fileUploadHandler.bind(this)}
              >
                Upload
              </button>
            </div>
          </div>
          <div className="row">
            {this.state.slabs && (
              <SlabsTable rows={this.state.slabs}></SlabsTable>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const SlabsTable = ({ rows }) => {
  return (
    <div style={{ padding: 20 }}>
      <table className="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Invoice#</th>
            <th>Invoice Date</th>
            <th>Bundle</th>
            <th>Slab#</th>
            <th>Material</th>
            <th>Length (mt)</th>
            <th>Height (mt)</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.slab_num}>
              <td>{row.invoice}</td>
              <td>{toDateString(row.invoice_date)}</td>
              <td>{row.bundle_num}</td>
              <td>{row.slab_num}</td>
              <td>{row.material}</td>
              <td>{row.length}</td>
              <td>{row.height}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const toDateString = (str) => {
  const d = new Date(str + " 00:00:00");
  return d.toLocaleDateString();
};
