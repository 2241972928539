import React, { Component } from 'react';
import { formatMoney, toFixed } from './../services/utils';
import  DatePicker  from 'react-datepicker';
import moment from 'moment';



let initial = {
    method: '',
    amount: '',
    paid_date:  new Date(),
    check_num: '',
    bank: '',
    confirmation: '',
    note: '',
    balance: 0
}
class PaymentEditModal extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            order: {},
            payment: initial,
            error: '', 
            isvalid: false 
        };
    }

    componentWillReceiveProps(nextProps) {
        const order = nextProps.order;
        const balance = this.props.calcBalance(order);
        initial['amount'] = toFixed(balance);
        this.setState({order, payment: initial, isvalid: false, error: '', balance});
    }

    handleSave = () => {
        const order = this.state.order;
        //format date for database - otherwise the date is saved as GMT or 7 hours faster
        const date =  moment(this.state.payment.paid_date).format('YYYY-MM-DD');

        const payment = {
            ...this.state.payment,
            order_id: order.id,
            paid_date: date,
            invoice_amount: order.total
        }
        this.props.addPayment(payment);
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const payment = {
            ...this.state.payment,
            [name]: value
        }
        this.setState({payment}, () => { this.validate()});
    }

    handleDateChange = (date) => {
        this.setState({
            payment:{
                ...this.state.payment,
                paid_date: date
            }}
        );
    }
    
    validate() {
        let result = [];
        const payment = this.state.payment;
        if(isNaN(payment.amount)) {
            result.push('Invalid Amount');
        }
        if(!this.isStringLength(payment.method)) {
            result.push('Invalid Method');
        }
        if(!payment.paid_date) {
            result.push('Invalid Date');
        }
        if(payment.method === 'Check')
        {
            if(!this.isStringLength(payment.bank)) {
                result.push('Invalid bank');
            }
            if(!this.isStringLength(payment.check_num)) {
                result.push('Invalid check#');
            }
        }
        if(payment.method === 'Card')
        {
            if(!this.isStringLength(payment.confirmation)) {
                result.push('Invalid confirmation');
            }
        }

        if(result.length === 0){
            this.setState({error: '', isvalid: true})
            return true;
        }
        this.setState({error: result.toString(), isvalid: false });
        return false;
    }

    isStringLength(text, len = 1){
        if(text) 
            return text.length > len;
        return false;
    }

    handleClose = () => {
        console.log("close");
    }

    render() {
        const order = this.state.order;
        const payment = this.state.payment || initial;
        return (
            <div className="modal fade" id="paymentEdit"  data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="myModalLabel">Add Payment</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={ this.handleClose }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <div className="form-horizontal ml-2" style={{backgroundColor: 'white'}}>
                            <div className="form-group row">
                                <div className="bg-light col-sm-12" style={{lineHeight: .5}}>
                                    <p>Invoice # {order.id} </p>
                                    <p>Invoiced { formatMoney(order.total) } </p>
                                    <p>Balance: { formatMoney(this.state.balance) } </p>
                                </div>
                            </div>
                            {/* remain due */}
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Amount</label>
                                <div className="col-md-6">
                                    <input type="search"  
                                    className="form-control" 
                                    name="amount" 
                                    value={ payment.amount || ''} 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Method</label>
                                <div className="col-md-6">
                                    <select 
                                        className="custom-select" 
                                        name="method" 
                                        value={payment.method} 
                                        onChange={this.handleChange}>
                                        <option value="">Select</option>
                                        <option value="card">Credit/Debit</option>
                                        <option value="check">Check</option>
                                        <option value="cash">Cash</option> 
                                    </select>
                                </div>
                            </div>
                            {
                                payment.method === 'check' &&
                                <div>
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label">Check #</label>
                                    <div className="col-md-6">
                                        <input type="text" 
                                        className="form-control" 
                                        name="check_num" 
                                        value={payment.check_num} 
                                        onChange={this.handleChange} />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label">Bank</label>
                                    <div className="col-md-6">
                                        <input type="text" 
                                        className="form-control" 
                                        name="bank" 
                                        value={payment.bank} 
                                        onChange={this.handleChange} />
                                    </div>
                                </div> 
                                </div>
                            }       
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Date</label>
                                <div className="col-md-6">
                                    <DatePicker 
                                    className="form-control" 
                                    name="paid_date" 
                                    selected={ payment.paid_date } 
                                    onChange={ this.handleDateChange } />
                                </div>
                            </div>
                            {
                            payment.method === 'card' &&
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Confirmation</label>
                                <div className="col-md-6">
                                    <input type="text" 
                                    className="form-control" 
                                    name="confirmation" 
                                    value={payment.confirmation } 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            }
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Note</label>
                                <div className="col-md-9">
                                    <input type="text" 
                                    className="form-control" 
                                    name="note" 
                                    value={payment.note } 
                                    onChange={this.handleChange}
                                    />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose} >Close</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.state.isvalid}
                                onClick={ this.handleSave }>Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentEditModal;