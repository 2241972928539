
export const slabsFetchedAction = (slabs) => ({
        type: 'SLABS_FETCHED',
        payload: slabs
});

export const appendSlabsAction = (slabs) => ({
    type: 'APPEND_SLABS',
    payload: slabs
});

export const updateSlabAction = (slab) => ({
    type: 'UPDATE_SLAB',
    updates: slab
});

export const createSlabAction = (slab) => ({
    type: 'ADD_SLAB',
    slab
});

export const deleteSlabAction = (id) => ({
    type: 'DELETE_SLAB',
    id
});

export const updateGraniteAction = (granite) => ({
    type: 'UPDATE_GRANITE',
    updates: granite
});

export const createGraniteAction = (granite) => ({
    type: 'ADD_GRANITE',
    granite
});

export const deleteGraniteAction = (id) => ({
    type: 'DELETE_GRANITE',
    id
});

export const fetchedGranitesAction = (payload) => ({
    type: 'GRANITES_FETCHED',
    payload
});

export const updateCartAction = (cart) => ({
    type: 'UPDATE_CART',
    cart: cart
});

//---------------------------------------------------

export const fetchedCustomerAction = (customers) => ({
    type: 'CUSTOMERS_FETCHED',
    payload: customers
});

export const updateCustomerAction = (customer) => ({
    type: 'UPDATE_CUSTOMER',
    updates: customer
});

export const createCustomersAction = (customer) => ({
    type: 'ADD_CUSTOMER',
    customer
});

export const deleteCustomersAction = (id) => ({
    type: 'DELETE_CUSTOMER',
    id
});

//---------------------------------------------------

export const fetchedOrdersAction = (orders) => ({
    type: 'ORDERS_FETCHED',
    payload: orders
});

export const updateOrderAction = (order) => ({
    type: 'UPDATE_ORDER',
    updates: order
});

export const createOrderAction = (order) => ({
    type: 'ADD_ORDERS',
    order
});

export const deleteOrderAction = (id) => ({
    type: 'DELETE_ORDER',
    id
});

export const resetOrdersAction = () => ({
    type: 'RESET_ORDERS',
    data: null
});
//-------------------------------------------------
//settings 
export const updateSettingsAction = (item) => ({
    type: 'UPDATE_SETTINGS',
    updates: item
});
//--------------------------------------------------
//prices
export const pricesFetchedAction = (payload) => ({
    type: 'PRICES_FETCH_FULFILLED',
    payload
});

export const createPriceAction = (payload) => ({
    type: 'ADD_PRICE',
    price: payload
});

export const updatePriceAction = (payload) => ({
    type: 'UPDATE_PRICE',
    updates: payload
});

export const deletePriceAction = (id) => ({
    type: 'DELETE_PRICE',
    id: id
});

//-------------------------------------------------
//profile
export const updateProfileAction = (profile) => ({
    type: 'UPDATE_PROFILE',
    updates: profile
});

export const resetProfileAction = () => ({
    type: 'RESET_PROFILE',
});

//------------------------------------------------
//report
export const fetchedWeekSalesAction = (orders) => ({
    type: 'WEEK_SALES_FETCHED',
    payload: orders
});

//-------------------------------------------------
//ordersALl
export const resetOrdersAllAction = () => ({
    type: 'RESET_ORDERS_ALL',
});

export const fetchedOrdersAllAction = (orders) => ({
    type: 'ORDERS_ALL_FETCHED',
    payload: orders
});