import React, { Component } from "react";
import { fetchModel } from "../services/apiService";
import * as d3 from "d3";

export default class InventoryGraph extends Component {
  filtered = [];
  state = {
    slabs: [],
    search: "",
    loading: true,
    data: {},
  };

  async componentDidMount() {
    const slabs = await fetchModel("inventory");
    //console.log('slabs ', slabs);

    this.setState({ slabs });

    this.createGroups();
    this.drawTree();

    this.showSpinner(false);
  }

  componentDidUpdate() {}

  createGroups = () => {
    let groups = [];
    let groupsObj = [];
    const slabs = this.state.slabs || [];
    const len = slabs.length;

    for (let i = 0; i < len; i++) {
      const slab = slabs[i];
      groups[slab.section] = groups[slab.section] || [];
      (groups[slab.section][slab.rack] =
        groups[slab.section][slab.rack] || []).push({
        name: slab.slab_num + " - " + slab.material,
      });
    }

    Object.keys(groups).forEach((l1) => {
      let children = [];
      let count = 0,
        countGr = 0;
      Object.keys(groups[l1]).forEach((l2) => {
        count = groups[l1][l2].length;
        countGr += groups[l1][l2].length;
        children.push({
          name: `# ${l2} Count:${count}`,
          children: groups[l1][l2],
        });
      });
      groupsObj.push({
        name: "Section " + l1 + " count:" + countGr,
        children: children,
      });
    });
    groupsObj = { name: "All", children: groupsObj };
    this.setState({ data: groupsObj });
    // console.log('slab line', groupsObj);
  };

  drawTree = () => {
    var margin = { top: 30, right: 20, bottom: 30, left: 20 },
      width = 960,
      barHeight = 20,
      barWidth = (width - margin.left - margin.right) * 0.8;

    var i = 0;
    const duration = 400;
    let root;

    var diagonal = d3
      .linkHorizontal()
      .x(function(d) {
        return d.y;
      })
      .y(function(d) {
        return d.x;
      });

    var svg = d3
      .select(this.node2)
      .append("svg")
      .attr("width", width) // + margin.left + margin.right)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    root = d3.hierarchy(this.state.data);
    root.x0 = 0;
    root.y0 = 0;
    update(root);

    function update(source) {
      // Compute the flattened node list.
      var nodes = root.descendants();

      var height = Math.max(
        500,
        nodes.length * barHeight + margin.top + margin.bottom
      );

      d3.select("svg")
        .transition()
        .duration(duration)
        .attr("height", height);

      d3.select(window.frameElement)
        .transition()
        .duration(duration)
        .style("height", height + "px");

      // Compute the "layout". TODO https://github.com/d3/d3-hierarchy/issues/67
      var index = -1;
      root.eachBefore(function(n) {
        n.x = ++index * barHeight;
        n.y = n.depth * 20;
      });

      // Update the nodes…
      var node = svg.selectAll(".node").data(nodes, function(d) {
        return d.id || (d.id = ++i);
      });

      var nodeEnter = node
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", function(d) {
          return "translate(" + source.y0 + "," + source.x0 + ")";
        })
        .style("opacity", 0);

      // Enter any new nodes at the parent's previous position.
      nodeEnter
        .append("rect")
        .attr("y", -barHeight / 2)
        .attr("height", barHeight)
        .attr("width", barWidth)
        .style("fill", color)
        .on("click", click);

      nodeEnter
        .append("text")
        .attr("dy", 3.5)
        .attr("dx", 5.5)
        .text(function(d) {
          return d.data.name;
        });

      // Transition nodes to their new position.
      nodeEnter
        .transition()
        .duration(duration)
        .attr("transform", function(d) {
          return "translate(" + d.y + "," + d.x + ")";
        })
        .style("opacity", 1);

      node
        .transition()
        .duration(duration)
        .attr("transform", function(d) {
          return "translate(" + d.y + "," + d.x + ")";
        })
        .style("opacity", 1)
        .select("rect")
        .style("fill", color);

      // Transition exiting nodes to the parent's new position.
      node
        .exit()
        .transition()
        .duration(duration)
        .attr("transform", function(d) {
          return "translate(" + source.y + "," + source.x + ")";
        })
        .style("opacity", 0)
        .remove();

      // Update the links…
      var link = svg.selectAll(".link").data(root.links(), function(d) {
        return d.target.id;
      });

      // Enter any new links at the parent's previous position.
      link
        .enter()
        .insert("path", "g")
        .attr("class", "link")
        .attr("d", function(d) {
          var o = { x: source.x0, y: source.y0 };
          return diagonal({ source: o, target: o });
        })
        .transition()
        .duration(duration)
        .attr("d", diagonal);

      // Transition links to their new position.
      link
        .transition()
        .duration(duration)
        .attr("d", diagonal);

      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition()
        .duration(duration)
        .attr("d", function(d) {
          var o = { x: source.x, y: source.y };
          return diagonal({ source: o, target: o });
        })
        .remove();

      // Stash the old positions for transition.
      root.each(function(d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    // collapse all nodes recusively, hence initiate the tree
    function collapse(d) {
      d.Selected = false;
      if (d.children) {
        d.numOfChildren = d.children.length;
        d._children = d.children;
        d._children.forEach(collapse);
        d.children = null;
      } else {
        d.numOfChildren = 0;
      }
    }
    if (root.children) root.children.forEach(collapse);

    // Toggle children on click.
    function click(d) {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
      update(d);
    }

    function color(d) {
      return d._children ? "#3182bd" : d.children ? "#c6dbef" : "#fd8d3c";
    }
  };

  handleChange = (event) => {
    const name = event.target.name;

    this.setState({
      [name]: event.target.value,
    });
  };

  showSpinner = (value) => {
    this.setState({ loading: value });
  };

  render() {
    //  const filtered = this.state.slabs;// this.state.slabs.filter(item => item.slab_num.startsWith(this.state.search.toLowerCase()) );
    this.filtered = [];

    return (
      <div className="page">
        <div>
          <h5 className="mt-4">Slab Inventory Tree</h5>
          <div className="mt-3" style={{ height: "100%", overflow: "auto" }}>
            <svg
              style={{ height: "100%", width: "100%" }}
              ref={(node) => {
                this.node2 = node;
              }}
            ></svg>
          </div>
        </div>
      </div>
    );
  }
}
