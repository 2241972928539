import React, {useState } from 'react';
import {totalSQFT} from './Utils';

const BundleGroup = (props) => {
  const {bundle, filenameChanged, dropped} = props;
  const [bgColor, setBgColor] = useState("");

  const handleEnter = () => {
    setBgColor('#F2F9F2');
  }

  const handleLeave = () => {
    setBgColor('#fff');
  }

  const handleDrop = (e) => {
    dropped(e);
    setBgColor('#ffF');
  }


  const slabs = bundle.slabs || [];
  const sqftSum = totalSQFT(slabs);

  return( 
    <div className='mb-4 bg-light p-3 data-bundle'>
      <div className='row'>
        <div className="form-group row col ml-3">
          <label htmlFor="bundle" className="col-form-label font-weight-bold">Bundle: </label>
          <label className="col-form-label font-weight-bold ml-3 data-bundlenum">{bundle.number}</label>
        </div>
        <div className="form-group row col-sm-8 mr-5">
          <label htmlFor="filename" className="col-form-label">Image</label>
          <div className="col">
            <input type="text" 
              className="form-control data-filename"
              style={{backgroundColor: bgColor}} 
              value={bundle.filename} 
              name={bundle.number}
              onDragEnter={handleEnter}
              onDragLeave={handleLeave}
              onDrop={handleDrop} 
              onChange={(e)=> filenameChanged(e)} />
          </div>
        </div>
      </div>
    
      <table className="table">
      <thead>
        <tr>
          <th scope="col">slab</th>
          <th scope="col">material</th>
          <th scope="col">thick</th>
          <th scope="col">length</th>
          <th scope="col">height</th>
          <th scope="col">sqft</th>
        </tr>
      </thead>
      <tbody>
        {
        slabs.map(slab => 
        <tr className='data-slab' key={slab.slab_num} >
          <td className='data-slab-num'>{slab.slab_num}</td>
          <td>{slab.material}</td>
          <td>{slab.thick}</td>
          <td>{slab.length}</td>
          <td>{slab.height}</td>
          <td>{slab.sqft}</td>
       </tr>)
       }
       <tr>
          <td>Total Slabs</td>
          <td className="totalSlabs">{slabs.length}</td>
          <td colSpan={3}>Total</td>
          <td className="totalSqft">{sqftSum}</td>
       </tr>
       </tbody>
     </table>
     </div>
  );
}

export default BundleGroup;