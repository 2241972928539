// @ts-check
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchCustomers } from "../services/apiService";
import { fetchedCustomerAction } from "../actions";
import lowerCase from "lodash.lowercase";

class CustomerSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      search: "",
    };
  }

  async componentDidMount() {
    if (!this.props.customers) {
      const data = await fetchCustomers();
      this.props.dispatch(fetchedCustomerAction(data));
      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });
    }
  }

  showSpinner() {
    this.setState({ loading: true });
  }

  hideSpinner() {
    this.setState({ loading: false });
  }

  handleSelection(customer) {
    this.props.selectItem(customer);
  }

  handleChange = (e) => {
    this.setState({ search: e.target.value });
  };

  filter = (data, search) => {
    if (data) {
      const filtered = data.filter((item) => {
        const _search = lowerCase(search);
        return (
          lowerCase(item.name).includes(lowerCase(_search)) ||
          lowerCase(item.company).includes(lowerCase(_search))
        );
      });
      return filtered;
    }
    return [];
  };

  render() {
    let filtered = this.filter(this.props.customers, this.state.search);

    return (
      <div className="page-div">
        {this.state.loading && <div className="spinner"></div>}
        <div
          ref={(e) => (this.modal = e)}
          className="modal fade"
          id="editorDialog"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="editorDialogLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex">
                  <div
                    className="modal-title font-weight-bold pt-1"
                    style={{ width: 200 }}
                  >
                    Select customer{" "}
                  </div>
                  <input
                    type="search"
                    className="form-control"
                    onChange={this.handleChange}
                    value={this.state.search}
                  />
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="list-group">
                  {filtered.map((item) => (
                    <button
                      key={item.id}
                      className="list-group-item list-group-item-action"
                      onClick={this.handleSelection.bind(this, item)}
                    >
                      {item.company}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.customers,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(CustomerSelect);
