
export function cart (state = [], action) {
    switch (action.type) {
        case 'UPDATE_CART':
            const _state = action.cart || {};
            return _state;

        default:
            return state;
    }
}

const initSetting = { taxRate: null };
export function settings (state = initSetting, action) {
    switch (action.type) {
        case 'UPDATE_SETTINGS':
            return  {
                ...state,
                ...action.updates
            };
        default:
            return state;
    }
}

export function profile  (state = {}, action) {
    switch (action.type) {
        case 'UPDATE_PROFILE':
            return  {
                ...state,
                ...action.updates
            };
        case 'RESET_PROFILE':
            return  {};
        default:
            return state;
    }
}

export function weekSales (state = {}, action) {
    switch (action.type) {
        case 'WEEK_SALES_FETCHED':
            return action.payload;
        default: 
            return state;
    }
}