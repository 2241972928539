

export default function orders (state = null, action) {
    switch (action.type) {
        case 'ADD_ORDER':
            const _state = state || [];
            return _state.concat(action.order);
        case 'UPDATE_ORDER':
            const list = state || [];
            const orders = list.map((item, index) => {
                if (parseInt(item.id) !== parseInt(action.updates.id)) { 
                    return item
                }
                return {
                    ...item,
                    ...action.updates
                }
            });
            return orders;
        case 'DELETE_ORDER':
            const removed = state.filter(({id}) => id !== action.id);
            return removed;

        case 'RESET_ORDERS':
            return null;
        case 'ORDERS_FETCHED':
            return  action.payload;
        default:
            return state;
    }
}