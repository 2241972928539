import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import { CONSTS } from "../constants";

import {
  fetchOrders,
  updateDelivery,
  createDelivery,
  updateOrder,
} from "./../services/apiService";
import { Spinner, showAlert } from "../components/dialogs";
import { toFixed } from "./../services/utils";
import DeliveryModal from "../components/DeliveryModal";
import { updateOrderAction } from "./../actions/index";

const initial = {
  id: 0,
  customer: {},
  shipping: {},
  items: [],
  delivery: null,
};

class DeliveryView extends Component {
  state = {
    order: initial,
    loading: true,
    modalIsOpen: false,
    scheduleIsOpen: false,
    address: {},
    schedule: {
      date: new Date(),
      time: "",
    },
  };

  async componentDidMount() {
    this.showSpinner();

    const id = this.props.match.params.id;
    const order = await fetchOrders(id);
    this.setState({ order });
    this.setAddress();
    this.hideSpinner();
  }

  showSpinner = () => {
    this.setState({ loading: true });
  };

  hideSpinner = () => {
    this.setState({ loading: false });
  };

  closeDeliveryModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openDeliveryModal = () => {
    const delivery = this.getDeliveryInfo();
    const order = {
      ...this.state.order,
      delivery,
    };
    this.setState({ modalIsOpen: true, order: order });
  };

  closeScheduleModal = () => {
    this.setState({ scheduleIsOpen: false });
  };

  openScheduleModal = () => {
    let date = Date.parse(this.state.order.scheduled_delivery);
    date = date ? new Date(date) : new Date();
    const schedule = {
      date: date,
      time: moment(date).format("h:mm a"),
    };
    this.setState({ scheduleIsOpen: true, schedule });
  };

  goToDeliveries = () => {
    this.props.history.push("/tools/deliveries");
  };

  setAddress = () => {
    const customer = this.state.order.customer || {};
    const shipping = this.state.order.shipping || customer;
    const delivery = this.state.order.delivery || {};
    const keys = ["company", "name", "phone", "street", "city", "state", "zip"];
    const address = {};
    keys.forEach((k) => {
      address[k] = delivery[k] || shipping[k];
    });
    this.setState({ address });
  };

  getDeliveryInfo = () => {
    if (this.state.order.delivery) return this.state.order.delivery;

    const customer = this.state.order.customer.name;
    const user = this.props.profile.nickname;
    return {
      delivered_by: user,
      received_by: customer,
      delivered_at: new Date(),
    };
  };

  handleSaveDelivery = async (data) => {
    data["delivered_at"] = this.combinedDateTime(
      data.delivered_at,
      data.delivered_time
    );
    data["order_id"] = this.state.order.id;
    let result = null;

    if (data.id > 0) {
      result = await updateDelivery(data);
    } else {
      const newData = {
        ...data,
        ...this.state.address,
      };
      result = await createDelivery(newData);
    }

    if (!result.error) {
      console.log("delivery saved ");
      const order = {
        ...this.state.order,
        delivery: result,
      };
      this.setState({ order });
      showAlert("Delivery saved", "success");
      this.updateOrder(order);
    } else {
      showAlert("Update failed", "error");
    }
  };

  handleSaveSchedule = async () => {
    const data = this.state.schedule;

    this.closeScheduleModal();
    const schedule = this.combinedDateTime(data.date, data.time);

    let order = {
      id: this.state.order.id,
      scheduled_delivery: schedule,
    };

    const result = await updateOrder(order);

    if (!result.error) {
      order = {
        ...this.state.order,
        ...result,
      };

      this.setState({ order });
      showAlert("Delivery Scheduled", "success");
      this.updateOrder(order);
    } else {
      showAlert("Schedule failed", "error");
    }
  };

  getTime = (time) => {
    if (!time) return "00:00:00";
    const _time = moment("2018-12-12 " + time).format("HH:mm:00");
    return _time;
  };

  combinedDateTime = (date, time) => {
    const _time = this.getTime(time);
    const datetime = moment(date).format("YYYY-MM-DD " + _time);
    return datetime;
  };

  updateOrder = async (order) => {
    const res = await fetchOrders(order.id);

    if (!res.error) {
      this.props.dispatch(updateOrderAction(res));
    }
  };

  scheduleChange = (event) => {
    const target = event.target;
    const schedule = this.state.schedule;

    if (target && target.name === "schedule_time") {
      schedule["time"] = target.value;
      this.setState({ schedule });
      return;
    }

    schedule["date"] = event;
    this.setState({ schedule });
  };

  render() {
    const order = this.state.order;
    const delivery = order.delivery || {};

    return (
      <div className="page-div">
        <Spinner isVisible={this.state.loading} />
        <div className="container">
          <CustomerInfo
            order={order}
            shipping={this.state.address}
            delivery={delivery}
            showDeliveryModal={this.openDeliveryModal}
            showScheduleModal={this.openScheduleModal}
            goToDeliveries={this.goToDeliveries}
          />

          <OrderInfo order={order} />

          <DeliveredInfo delivery={delivery} />
        </div>

        <DeliveryModal
          isOpen={this.state.modalIsOpen}
          delivery={delivery}
          close={this.closeDeliveryModal}
          save={this.handleSaveDelivery}
        />

        <ScheduleDeliveryModal
          isOpen={this.state.scheduleIsOpen}
          schedule={this.state.schedule}
          changes={this.scheduleChange}
          close={this.closeScheduleModal}
          save={this.handleSaveSchedule}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.match.params.id;

  return {
    // eslint-disable-next-line
    order: state.orders ? state.orders.find((item) => item.id == id) : initial,
    isAuthenticated: state.isAuthenticated,
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(DeliveryView);

export const CustomerInfo = (props) => {
  const {
    shipping,
    delivery,
    showDeliveryModal,
    showScheduleModal,
    goToDeliveries,
  } = props;
  const maplink =
    "http://maps.google.com/?q=" +
    shipping.street +
    " " +
    shipping.city +
    "," +
    shipping.state +
    " " +
    shipping.zip;
  return (
    <div className="row mb-5">
      <div className="d-addr-card mb-3">
        <div className="card-head">Delivery Address</div>
        <div className="px-3 py-2">
          <div>{shipping.name}</div>
          <div>
            <a href={"tel:" + shipping.phone}>{shipping.phone}</a>
          </div>
          <div>{shipping.street}</div>
          <div>
            {shipping.city}, {shipping.state} {shipping.zip}
          </div>
          <a href={maplink}>directions</a>
        </div>
      </div>
      <div className="ml-3">
        {isEmptyDate(delivery.delivered_at) && (
          <button
            className="btn btn-outline-primary mr-3"
            onClick={showScheduleModal}
          >
            Schedule Delivery
          </button>
        )}
        <button
          className="btn btn-outline-secondary mr-3"
          onClick={showDeliveryModal}
        >
          Update Delivery
        </button>
        <a
          className="btn btn-outline-secondary mr-md-3"
          href={`${CONSTS.server}vpdf/delivery/${props.order.uid}`}
        >
          <i className="fa fa-file-pdf-o mr-2" aria-hidden="true"></i>PDF
        </a>
        <button className="btn btn-outline-secondary" onClick={goToDeliveries}>
          <i className="fa fa-arrow-left mr-2"></i>Deliveries
        </button>
      </div>
    </div>
  );
};

function formatDate(datestr) {
  const date = Date.parse(datestr);
  if (!date) return "";
  return moment(date).format("MM/DD/YYYY");
}

function formatDateTime(datestr) {
  const date = Date.parse(datestr);
  if (!date) return "";
  return moment(date).format("MM/DD/YYYY  - h:mm A");
}

function isEmptyDate(datestr) {
  const date = Date.parse(datestr);
  if (!date) return true;
  return false;
}

export const OrderInfo = ({ order }) => {
  const items = order.items || [];
  return (
    <div>
      <div className="row">
        <table className="table table-responsive-md table-bordered table-delivery">
          <thead className="thead-dark text-center">
            <tr>
              <th className="border border-white">Order Number</th>
              <th className="border-right border-light">Order Date</th>
              <th className="border-right border-light">Scheduled Delivery</th>
              <th className="border-right border-light">Quantity</th>
            </tr>
          </thead>
          <tbody className="text-center border border-danger">
            <tr>
              <td>{order.id}</td>
              <td>{formatDate(order.created_at)}</td>
              <td>{formatDateTime(order.scheduled_delivery)}</td>
              <td>{items.length}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="row">
        <table className="table table-bordered table-delivery">
          <thead className="thead-dark">
            <tr>
              <th>Number</th>
              <th>Bundle</th>
              <th>Description</th>
              <th>Size (ft&sup2;)</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <tr key={item.id}>
                <td>{item.slab_num}</td>
                <td>{item.slab.bundle_num}</td>
                <td>
                  {item.slab.material} -{" "}
                  <small>
                    ({toFixed(item.slab.height)} &times;{" "}
                    {toFixed(item.slab.length)}) LOC:
                    {item.slab.section + item.slab.rack}
                  </small>
                </td>
                <td>{item.sqft}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const DeliveredInfo = ({ delivery }) => {
  return (
    <div className="row mt-5">
      <table className="table table-responsive-md table-bordered table-delivery">
        <thead className="thead-dark text-center">
          <tr>
            <th className="border border-white">Delivery Date</th>
            <th className="border-right border-light">Delivered by</th>
            <th className="border-right border-light">Received by</th>
          </tr>
        </thead>
        <tbody className="text-center border border-danger">
          <tr>
            <td>
              {delivery.delivered_at
                ? formatDate(delivery.delivered_at)
                : "____  /  ____  /   ____"}
            </td>
            <td>{delivery.delivered_by}</td>
            <td>{delivery.received_by}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export const ScheduleDeliveryModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      closeTimeoutMS={300}
      className="modal-panel"
      contentLabel="Date Modal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editorDialogLabel">
              Schedule Delivery
            </h5>
            <button type="button" className="close" onClick={props.close}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-horizontal">
              <div className="form-group form-row">
                <label className="col-md-3 col-form-label">Date</label>
                <div className="col-md-3">
                  <DatePicker
                    className="form-control"
                    name="schedule_date"
                    selected={props.schedule.date}
                    onChange={props.changes}
                  />
                </div>
              </div>
              <div className="form-group form-row">
                <label className="col-md-3 col-form-label">Time</label>
                <div className="col-md-3">
                  <input
                    className="form-control"
                    name="schedule_time"
                    value={props.schedule.time}
                    onChange={props.changes}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={props.close}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={props.save}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
