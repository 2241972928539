//@ts-check
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getCart,
  removeFromCart,
  updateSlab,
  updateCart,
  getSetting,
  fetchSlabs,
  addDiscountToCart,
  removeDiscountFromCart,
  addFeeToCart,
  removeFreeFromCart,
} from "./../services/apiService";
import { imageNoFoundName } from "./../constants";
import {
  fixImageNotFound,
  imageurl,
  calcPrice2,
  toFixed,
  formatMoney,
} from "../services/utils";
import {
  updateCartAction,
  updateSlabAction,
  updateSettingsAction,
  slabsFetchedAction,
} from "../actions";
import AddDiscountDlg from "../components/AddDiscountDlg";
import AddFeeDlg from "../components/AddFeeDlg";
import { showAlert } from "./../components/dialogs";

class CartView extends Component {
  constructor(props) {
    super(props);
    this.total = 0;
    this.taxRate = 0.0685;
    this.state = {
      taxRate: -1,
      isOpen: false,
      isFeeDlgOpen: false,
    };
  }

  async removeItem(id) {
    removeFromCart(id);
    const cart = getCart();
    this.props.dispatch(updateCartAction(cart));

    if (!this.props.slabs) {
      const resp = await fetchSlabs();
      this.props.dispatch(slabsFetchedAction(resp));
    }

    let slab = this.props.slabs.filter((slab) => slab.id === id)[0];
    if (slab) {
      slab.reserved = "";
      const result = await updateSlab(slab);
      if (!result.error) {
        this.props.dispatch(updateSlabAction(result));
      }
    }
  }

  handleUpdate = (e, item) => {
    item.isbundle = e.target.checked;
    const cart = updateCart(item);
    this.props.dispatch(updateCartAction(cart));
  };

  async componentDidMount() {
    const cart = getCart();
    this.props.dispatch(updateCartAction(cart));

    if (!this.props.settings.taxRate) this.getTaxRate();
  }

  getTaxRate = async () => {
    const result = await getSetting("tax_rate");
    if (result) {
      const taxRate = { taxRate: Number(result.value) };
      this.props.dispatch(updateSettingsAction(taxRate));
    }
  };

  openAddDiscountDlg = () => {
    this.setState({ isOpen: true });
  };

  closeAddDiscountDlg = () => {
    this.setState({ isOpen: false });
  };

  toggleAddFeeDlg = (isOpen) => {
    this.setState({ isFeeDlgOpen: isOpen });
  };

  addDiscount = (data) => {
    const cart = addDiscountToCart(data);
    if (cart) {
      this.props.dispatch(updateCartAction(cart));
      showAlert("discount applied", "success");
    } else {
      showAlert("discount couldn't be applied", "error");
    }
    this.closeAddDiscountDlg();
  };

  addFee = (data) => {
    const cart = addFeeToCart(data);
    if (cart) {
      this.props.dispatch(updateCartAction(cart));
      showAlert("fee added", "success");
    } else {
      showAlert("fee couldn't be added", "error");
    }
    this.toggleAddFeeDlg(false);
  };

  removeDiscount = (data) => {
    // console.log("removeDiscount");
    const cart = removeDiscountFromCart(data);
    this.props.dispatch(updateCartAction(cart));
  };

  removeFee = (data) => {
    // console.log("removeFee");
    const cart = removeFreeFromCart(data);
    this.props.dispatch(updateCartAction(cart));
  };

  resetCart = () => {
    removeFromCart();
  };

  getDiscountSum = (discounts) => {
    const discountSum = Number(
      toFixed(
        discounts.reduce(
          (acumulator, item) => (acumulator += Number(item.amount)),
          0
        )
      )
    );
    return discountSum;
  };

  getFeeSum = (fees) => {
    const feeSum = Number(
      toFixed(
        fees.reduce(
          (acumulator, item) => (acumulator += Number(item.amount)),
          0
        )
      )
    );
    return feeSum;
  };

  getSubTotal(items) {
    const subtotal = items.reduce(
      (acumulator, item) => (acumulator += calcPrice2(item)),
      0
    );
    return subtotal;
  }

  render() {
    const items = this.props.cart.items || [];
    const discounts = this.props.cart.discounts || [];
    const fees = this.props.cart.fees || [];
    const discountSum = this.getDiscountSum(discounts);
    const feeSum = this.getFeeSum(fees);
    let subtotal = this.getSubTotal(items);
    const discountedTotal = subtotal - discountSum + feeSum;
    const taxRate = this.props.settings.taxRate;
    const tax = taxRate * discountedTotal;
    this.total = tax + discountedTotal;

    return (
      <div className="page-div" id="cartview">
        <AddDiscountDlg
          isOpen={this.state.isOpen}
          close={this.closeAddDiscountDlg}
          save={this.addDiscount}
        />
        <AddFeeDlg
          isOpen={this.state.isFeeDlgOpen}
          close={() => this.toggleAddFeeDlg(false)}
          save={this.addFee}
        />
        <div className="container pb-3">
          {items.length > 0 ? (
            <div>
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Description</th>
                    <th scope="col"></th>
                    <th scope="col">Bundle</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={item.id}>
                      <th scope="row">{index + 1}</th>
                      <td style={{ width: "15%" }}>
                        <img
                          className="mr-3"
                          style={{ width: 120 }}
                          src={imageurl(item.filename)}
                          onError={(e) => fixImageNotFound(e, imageNoFoundName)}
                          alt="slab"
                        />
                      </td>
                      <td>
                        <div className="media-body">
                          <div className="mt-0 font-weight-bold ">
                            {item.material}
                          </div>
                          <div>
                            {item.slab_num} ({item.bundle_num})
                          </div>
                          <div className="mt-0">
                            {item.sqft}
                            <span> ft&sup2; (</span>
                            {toFixed(item.length) +
                              " x " +
                              toFixed(item.height)}
                            )
                          </div>
                          <div></div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <input
                            type="checkbox"
                            name="isbundle"
                            checked={item.isbundle}
                            onChange={(e) => this.handleUpdate(e, item)}
                          ></input>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div
                            className={
                              item.isbundle ? "font-weight-bold" : "text-muted"
                            }
                          >
                            {toFixed(item.bundle_price)}*
                          </div>
                          <div
                            className={
                              item.isbundle ? "text-muted" : "font-weight-bold"
                            }
                          >
                            {toFixed(item.unit_price)}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="ml-2">
                            {formatMoney(calcPrice2(item))}
                          </div>
                          <button
                            className="btn btn-link"
                            style={{ color: "red" }}
                            onClick={this.removeItem.bind(this, item.id)}
                          >
                            Remove
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {items && (
                    <Fragment>
                      <tr className="table-subtotal">
                        <th scope="row">Subtotal</th>
                        <td>{formatMoney(subtotal)}</td>
                      </tr>
                      {discounts.map((item) => (
                        <tr key={item.name} className="table-subtotal">
                          <th scope="row">
                            Discount
                            <span className="font-weight-light">
                              {" "}
                              ({item.name})
                            </span>
                            <button
                              type="button"
                              className="btn btn-sm btn-link"
                              onClick={() => this.removeDiscount(item)}
                            >
                              <span style={{ fontSize: 10 }}>Remove</span>
                            </button>
                          </th>
                          <td>-{formatMoney(item.amount)}</td>
                        </tr>
                      ))}
                      {fees.map((item) => (
                        <tr key={item.name} className="table-subtotal">
                          <th scope="row">
                            Fee
                            <span className="font-weight-light">
                              {" "}
                              ({item.name})
                            </span>
                            <button
                              type="button"
                              className="btn btn-sm btn-link"
                              onClick={() => this.removeFee(item)}
                            >
                              <span style={{ fontSize: 10 }}>Remove</span>
                            </button>
                          </th>
                          <td>{formatMoney(item.amount)}</td>
                        </tr>
                      ))}
                      <tr className="table-subtotal">
                        <th scope="row">Tax ({toFixed(taxRate * 100)}%)</th>
                        <td>{formatMoney(tax)}</td>
                      </tr>
                      <tr className="table-subtotal">
                        <th scope="row">Total</th>
                        <td>{formatMoney(this.total)}</td>
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
              <div className="pull-right m-3 row">
                <div className="mr-3">
                  <button
                    onClick={this.openAddDiscountDlg}
                    className="btn btn-outline-secondary"
                  >
                    Add Discount
                  </button>
                </div>
                <div className="mr-3">
                  <button
                    onClick={() => this.toggleAddFeeDlg(true)}
                    className="btn btn-outline-secondary"
                  >
                    Add Fee
                  </button>
                </div>
                <Link to="checkout/addr" className="btn btn-outline-primary">
                  Go to Checkout
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <div>The cart is empty </div>
              <button
                onClick={this.resetCart}
                className="btn btn-sm btn-outline-secondary my-4"
              >
                <i className="fa fa-retweet" aria-hidden="true"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  slabs: state.slabs,
  settings: state.settings,
});

export default connect(mapStateToProps)(CartView);
