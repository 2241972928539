//@ts-check
/* eslint eqeqeq: "off" */

import axios from "axios";
import { CONSTS } from "../constants";
import { unitPrice, bundlePrice } from "./utils";

const headersConfig = {
  Authorization: "Bearer " + localStorage.getItem("access_token"),
};

// -------------------------------------------------------------------------
// --- Price  ---------------------------------------------------------------
//-------------------------------------------------------------------------->
export const createPrice = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "price",
      data: data,
      headers: headersConfig,
    });

    console.log("price created ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createPrice" + err);
    return null;
  }
};

export const deletePrice = async (data) => {
  try {
    await axios({
      method: "get",
      url: CONSTS.server + "prices/delete/" + data.id,
      headers: headersConfig,
    });

    console.log("price deleted ", data.id);
    return data.id;
  } catch (err) {
    console.log("error on deletePrice " + err);
    return null;
  }
};

export const updatePrice = async (data) => {
  try {
    const path = Array.isArray(data) ? "prices" : "price/" + data.id;
    const response = await axios({
      method: "put",
      url: CONSTS.server + path,
      data: data,
      headers: headersConfig,
    });

    console.log("price updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updatePrice " + err);
    return null;
  }
};

export const fetchPrices = async (id = null) => {
  try {
    const path = id ? "prices/" + id : "prices";
    const response = await axios({
      method: "get",
      url: CONSTS.server + path,
      headers: headersConfig,
    });

    console.log("prices fetched ", response.data.length);

    return response.data;
  } catch (err) {
    console.log("error on fetchPrices " + err);
    return null;
  }
};

export const tidyCode = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "tidy",
      data: data,
      headers: headersConfig,
    });

    console.log("tidied code ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createSlab" + err);
    return null;
  }
};

// -------------------------------------------------------------------------
// --- Slabs  ---------------------------------------------------------------
//-------------------------------------------------------------------------->
export const fetchSlabs = async (params = {}) => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "slabs",
      headers: headersConfig,
      params: params,
    });

    console.log("slabs fetched ", response.data.length);
    return response.data;
  } catch (err) {
    console.log("error on fetchSlabs " + err);
    return null;
  }
};

export const fetchSlabsPaged = async (params = {}) => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "slabs/paged",
      headers: headersConfig,
      params: params,
    });

    console.log("slabs paged fetched ", response.data.data.length);
    return response.data;
  } catch (err) {
    console.log("error on fetchSlabsPaged " + err);
    return null;
  }
};

export const fetchSelectSlabs = async (ids) => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "slabs",
      params: { select: ids },
      headers: headersConfig,
    });

    console.log("slabs fetched ", response.data.length);
    return response.data;
  } catch (err) {
    console.log("error on fetchSlabs " + err);
    return null;
  }
};

export const fetchInactiveSlabs = async () => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "slabs/inactives",
      headers: headersConfig,
    });

    console.log("slabs fetched ", response.data.length);
    return response.data;
  } catch (err) {
    console.log("error on fetchSlabs " + err);
    return null;
  }
};

export const createSlab = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "slab",
      data: data,
      headers: headersConfig,
    });

    console.log("slab created ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createSlab" + err);
    return null;
  }
};

export const deleteSlab = async (data) => {
  try {
    await axios({
      method: "delete",
      url: CONSTS.server + "slab/" + data.id,
      headers: headersConfig,
    });

    console.log("slab deleted ", data.id);
    return data.id;
  } catch (err) {
    console.log("error on deleteSlab " + err);
    return null;
  }
};

export const updateSlab = async (data) => {
  try {
    const response = await axios({
      method: "put",
      url: CONSTS.server + "slab/" + data.id,
      data: data,
      headers: headersConfig,
    });

    console.log("slab updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updateSlab " + err);
    return { error: true, message: err.message };
  }
};

export const updateSlabs = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "slabs/bulk_update",
      data: data,
      headers: headersConfig,
    });

    console.log("slabs updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updateSlab " + err);
    return { error: true, message: err.message };
  }
};

export const uploadSlabFile = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "slabs/upload",
      data: data,
      headers: headersConfig,
    });

    console.log("slabs uploaded ", response.data.message);
    return response.data;
  } catch (err) {
    console.log("error on uploadSlabFile " + err);
    return { error: true, message: err.message };
  }
};

// -------------------------------------------------------------------------
// --- Granite  ------------------------------------------------------------
//-------------------------------------------------------------------------->
export const deleteGranite = async (id) => {
  try {
    await axios({
      method: "delete",
      url: CONSTS.server + "granite/" + id,
      headers: headersConfig,
    });

    console.log("granite deleted ", id);
    return id;
  } catch (err) {
    console.log("error on deleteGranite " + err);
    return { error: true, message: err.message };
  }
};

export const createGranite = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "granite",
      data: data,
      headers: headersConfig,
    });

    console.log("granite created ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createGranite" + err);
    return { error: true, message: err.message };
  }
};

export const updateGranite = async (data) => {
  try {
    const path = Array.isArray(data) ? "granites" : "granite/" + data.id;
    const response = await axios({
      method: "put",
      url: CONSTS.server + path,
      data: data,
      headers: headersConfig,
    });

    console.log("granite updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updateGranite " + err);
    return { error: true, message: err.message };
  }
};

export const fetchGranites = async () => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "granites",
      headers: headersConfig,
    });

    console.log("granites fetched ", response.data.length);

    return response.data;
  } catch (err) {
    console.log("error on fetchGranites " + err);
    return null;
  }
};

// -------------------------------------------------------------------------
// --- Image ---------------------------------------------------------------
//-------------------------------------------------------------------------->
export const uploadImage = async (file, name, type = 1) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "upload/img/" + type,
      data: file,
      headers: headersConfig,
    });

    console.log("Image uploaded ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on uploadImage " + err);
    return { error: true, message: err.message };
  }
};

export const uploadImages = async (files) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "upload/images",
      data: files,
      headers: headersConfig,
    });

    console.log("Images uploaded ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on uploadImages " + err);
    return { error: true, message: err.message };
  }
};

export const extractTextFromPDF = async (file) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "pdf/text",
      data: file,
      headers: headersConfig,
    });

    console.log("pdf processed", response.data);
    return response.data;
  } catch (err) {
    console.log("error on getTextFromPDF " + err);
    return { error: true, message: err.message };
  }
};

// -------------------------------------------------------------------------
// --- Cart  ---------------------------------------------------------------
//-------------------------------------------------------------------------->
export const addToCart = (slab) => {
  const cartItem = {
    id: slab.id,
    slab_num: slab.slab_num,
    bundle_num: slab.bundle_num,
    filename: slab.filename,
    material: slab.material,
    unit_price: unitPrice(slab),
    bundle_price: bundlePrice(slab),
    length: slab.length,
    height: slab.height,
    sqft: slab.sqft,
    isbundle: false,
  };

  let cart = getCart();
  let items = cart.items || [];
  let exists = items.some((item) => item.id === slab.id);
  if (exists === false) {
    items.push(cartItem);
    cart.items = sortCart(items);
    setCart(cart);
    return cart;
  }
  return null;
};

export const updateCart = (slab) => {
  let cart = getCart();
  let items = cart.items || [];

  cart.items = items.map((item, index) => {
    if (parseInt(item.id) !== parseInt(slab.id)) {
      return item;
    }
    return {
      ...item,
      ...slab,
    };
  });

  setCart(cart);
  return cart;
};

const sortCart = (cartItems) => {
  return cartItems.sort((a, b) => {
    if (a.slab_num < b.slab_num) return -1;
    if (a.slab_num > b.slab_num) return 1;
    return 0;
  });
};

/**
 *
 * @param {number} id
 */
export const removeFromCart = (id = null) => {
  //remove all
  if (id == null) {
    setCart({});
    return;
  }

  let cart = getCart();
  let items = cart.items || [];
  cart.items = items.filter(function(item) {
    return item.id !== id;
  });

  if (cart.items.length == 0) cart.discounts = [];
  setCart(cart);
};

export function getCart() {
  const cart = localStorage.getItem("cart");

  if (!cart) return {};
  const oCart = JSON.parse(cart);
  if (Array.isArray(oCart)) return {};

  let data = {};
  try {
    data = JSON.parse(cart);
  } finally {
    return data;
  }
}

function setCart(cart) {
  localStorage.setItem("cart", JSON.stringify(cart));
}

/**
 * @param {{name:string, description: string, amount:number}} discount
 */
export const addDiscountToCart = (discount) => {
  let cart = getCart();
  let discounts = cart.discounts || [];

  let exists = discounts.some((item) => item.name === discount.name);

  if (exists === false) {
    discounts.push(discount);
    cart.discounts = discounts;
    setCart(cart);
    return cart;
  }
  return null;
};

/**
 * @param {{name:string, description: string, amount:number}} fee
 */
export const addFeeToCart = (fee) => {
  let cart = getCart();
  let fees = cart.fees || [];

  let exists = fees.some((item) => item.name === fee.name);

  if (exists === false) {
    fees.push(fee);
    cart.fees = fees;
    setCart(cart);
    return cart;
  }
  return null;
};

/**
 * @param {{name:string, description: string, amount:number}} discount
 */
export const removeDiscountFromCart = (discount) => {
  let cart = getCart();
  let discounts = cart.discounts || [];

  cart.discounts = discounts.filter((item) => item.name !== discount.name);
  setCart(cart);
  return cart;
};

/**
 * @param {{name:string, description: string, amount:number}} fee
 */
export const removeFreeFromCart = (fee) => {
  let cart = getCart();
  let fees = cart.fees || [];

  cart.fees = fees.filter((item) => item.name !== fee.name);
  setCart(cart);
  return cart;
};

// -------------------------------------------------------------------------
// --- Billing  -----------------------------------------------------------
//-------------------------------------------------------------------------->
export function setBilling(customer) {
  localStorage.setItem("billing", JSON.stringify(customer));
}

export function getBilling() {
  return getStorageItem("billing");
}

function getStorageItem(name) {
  const tmp = localStorage.getItem(name);
  if (!tmp) return {};
  let data = {};
  try {
    data = JSON.parse(tmp);
  } finally {
    return data;
  }
}

// -------------------------------------------------------------------------
// --- Shipping  -----------------------------------------------------------
//-------------------------------------------------------------------------->
export function setShipping(shipping) {
  localStorage.setItem("shipping", JSON.stringify(shipping));
}

export function getShipping() {
  return getStorageItem("shipping");
}

// -------------------------------------------------------------------------
// --- Customer ------------------------------------------------------------
//-------------------------------------------------------------------------->
export const createCustomer = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "customer",
      data: data,
      headers: headersConfig,
    });

    console.log("customer created ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createCustomer" + err);
    return { error: true, message: err.message };
  }
};

export const deleteCustomer = async (id) => {
  try {
    await axios({
      method: "delete",
      url: CONSTS.server + "customer/" + id,
      headers: headersConfig,
    });

    console.log("customer deleted ", id);
    return id;
  } catch (err) {
    console.log("error on deleteCustomer " + err);
    return { error: true, message: err.message };
  }
};

export const updateCustomer = async (data) => {
  try {
    const response = await axios({
      method: "put",
      url: CONSTS.server + "customer/" + data.id,
      data: data,
      headers: headersConfig,
    });

    console.log("customer updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updateCustomer " + err);
    return { error: true, message: err.message };
  }
};

export const fetchCustomers = async () => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "customers",
      headers: headersConfig,
    });

    console.log("customers fetched ", response.data.length);

    return response.data;
  } catch (err) {
    console.log("error on fetchCustomers " + err);
    return null;
  }
};

export const updateCustomerShipping = async (data) => {
  try {
    const response = await axios({
      method: "put",
      url: CONSTS.server + "customer/shipping",
      data: data,
      headers: headersConfig,
    });

    console.log("customer address updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updateCustomerAddress " + err);
    return { error: true, message: err.message };
  }
};

// -------------------------------------------------------------------------
// --- Order ------------------------------------------------------------
//-------------------------------------------------------------------------->
export const createOrder = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "order",
      data: data,
      headers: headersConfig,
    });

    console.log("order created ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createOrder" + err);
    return { error: true, message: err.message };
  }
};
// <<----------------------------------------------------------------------

export const fetchOrders = async (id = "", mode = "") => {
  try {
    const path = id ? "orders/" + id : "orders";
    const response = await axios({
      method: "get",
      url: CONSTS.server + path,
      headers: headersConfig,
      params: { mode: mode },
    });

    console.log("orders fetched ", response.data.length || 0);

    return response.data;
  } catch (err) {
    console.log("error on fetchOrders " + err);
    return { error: true, message: err.message };
  }
};

export const updateOrder = async (data) => {
  try {
    const response = await axios({
      method: "put",
      url: CONSTS.server + "order/" + data.id,
      data: data,
      headers: headersConfig,
    });

    console.log("order updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updateOrder " + err);
    return { error: true, message: err.message };
  }
};

export const deleteOrder = async (id) => {
  try {
    const path = "order/" + id;
    const response = await axios({
      method: "delete",
      url: CONSTS.server + path,
      headers: headersConfig,
    });

    console.log("orders delete ", response.data.length);

    return response.data;
  } catch (err) {
    console.log("error on deleteOrder " + err);
    return { error: true, message: err.message };
  }
};

export const fetchOrderPDF = async (id) => {
  try {
    const path = "pdf/order/" + id;
    const response = await axios({
      method: "get",
      url: CONSTS.server + path,
      headers: headersConfig,
      responseType: "blob",
    });

    console.log("orders fetched ", response.data.length);
    const file = new Blob([response.data], { type: "application/pdf" });
    return file;
  } catch (err) {
    console.log("error on fetchOrders " + err);
    return null;
  }
};

// -------------------------------------------------------------------------
// --- Settings ------------------------------------------------------------
//-------------------------------------------------------------------------->
export const getSetting = async (name) => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "settings/" + name,
      headers: headersConfig,
    });

    console.log("setting fetched ", response.data);

    return response.data;
  } catch (err) {
    console.log("error on getSetting " + err);
    return null;
  }
};

export const setSetting = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "settings",
      data: data,
      headers: headersConfig,
    });

    console.log("setting data ", response.data);

    return response.data;
  } catch (err) {
    console.log("error on setSetting " + err);
    return null;
  }
};

// -------------------------------------------------------------------------
// --- Payment ------------------------------------------------------------
//-------------------------------------------------------------------------->
export const createPayment = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "payments",
      data: data,
      headers: headersConfig,
    });

    console.log("payment created ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createPayment" + err);
    return { error: true, message: err.message };
  }
};

export const deletePayment = async (id) => {
  try {
    await axios({
      method: "delete",
      url: CONSTS.server + "payments/" + id,
      headers: headersConfig,
    });

    console.log("payment deleted ", id);
    return id;
  } catch (err) {
    console.log("error on delete payment " + err);
    return { error: true, message: err.message };
  }
};

export const updatePayment = async (data) => {
  try {
    const response = await axios({
      method: "put",
      url: CONSTS.server + "payments/" + data.id,
      data: data,
      headers: headersConfig,
    });

    console.log("payment updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updatePayment " + err);
    return { error: true, message: err.message };
  }
};

export const fetchPayments = async () => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "payments",
      headers: headersConfig,
    });

    console.log("payments fetched ", response.data.length);

    return response.data;
  } catch (err) {
    console.log("error on fetchPayments " + err);
    return null;
  }
};
//<<--------------------------------------------------------------------

// -------------------------------------------------------------------------
// --- Delivery ------------------------------------------------------------
//-------------------------------------------------------------------------->
// todo
export const createDelivery = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "delivery",
      data: data,
      headers: headersConfig,
    });

    console.log("delivery created ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createDelivery " + err);
    return { error: true, message: err.message };
  }
};

export const deleteDelivery = async (id) => {
  try {
    await axios({
      method: "delete",
      url: CONSTS.server + "delivery/" + id,
      headers: headersConfig,
    });

    console.log("delivery deleted ", id);
    return id;
  } catch (err) {
    console.log("error on deleteDelivery " + err);
    return { error: true, message: err.message };
  }
};

export const updateDelivery = async (data) => {
  try {
    const response = await axios({
      method: "put",
      url: CONSTS.server + "delivery/" + data.id,
      data: data,
      headers: headersConfig,
    });

    console.log("delivery updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updateDelivery " + err);
    return { error: true, message: err.message };
  }
};

export const fetchDelivery = async (id = "") => {
  try {
    const path = id ? "delivery/" + id : "delivery";

    const response = await axios({
      method: "get",
      url: CONSTS.server + path,
      headers: headersConfig,
    });

    console.log("orders fetched ", response.data.length || 1);

    return response.data;
  } catch (err) {
    console.log("error on fetchDelivery " + err);
    return { error: true, message: err.message };
  }
};

//-----------------------------------------------------------------
// contacts
//-----------------------------------------------------------------
export const fetchContacts = async () => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + "contacts",
      headers: headersConfig,
    });

    console.log("contacts fetched ", response.data.length);

    return response.data;
  } catch (err) {
    console.log("error on fetchContacts " + err);
    return null;
  }
};

export const updateContact = async (data) => {
  try {
    const response = await axios({
      method: "put",
      url: CONSTS.server + "contacts/" + data.id,
      data: data,
      headers: headersConfig,
    });

    console.log("contact updated ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on updateContact " + err);
    return { error: true, message: err.message };
  }
};

export const createContact = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "contact",
      data: data,
      headers: headersConfig,
    });

    console.log("contact created ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on createContact " + err);
    return { error: true, message: err.message };
  }
};

export const deleteContact = async (id) => {
  try {
    await axios({
      method: "delete",
      url: CONSTS.server + "contact/" + id,
      headers: headersConfig,
    });

    console.log("contact deleted ", id);
    return id;
  } catch (err) {
    console.log("error on deleteContact " + err);
    return { error: true, message: err.message };
  }
};

export const fetchGoogleAddress = async (url) => {
  try {
    const response = await axios({
      method: "get",
      url: url,
    });

    console.log("google maps response", response.data);

    return response.data.results[0];
  } catch (err) {
    console.log("error on fetchGoogleAddress " + err);
    return { error: true, message: err.message };
  }
};
// contacts ------------------------------------------------------
// -------------------------------------------------------------
// FCM - Firebase Cloud Messaging
//--------------------------------------------------------------
export const assignFCMGroup = async (token, topic = CONSTS.FCM_GROUP) => {
  const data = {
    token: token,
    topic: topic,
  };

  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "fcm/assign",
      data: data,
      headers: headersConfig,
    });

    console.log("group assigned ", response.data);
    return response.data;
  } catch (err) {
    console.log("error on assignFCMGroup " + err);
    return { error: true, message: err.message };
  }
};

// generic fetch
export const fetchModel = async (model) => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + model,
      headers: headersConfig,
    });

    console.log("inventory fetched ", response.data.length);
    return response.data;
  } catch (err) {
    console.log("error on fetchInventory " + err);
    return null;
  }
};

// -------------------------------------------------------------------------
// --- Import Slabs from Packing List  ---------------------------------------------------------------
//-------------------------------------------------------------------------->
export const importDataFromList = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: CONSTS.server + "slabs/pklist",
      data: data,
      headers: headersConfig,
    });

    console.log("slab created from list ", response.data);
    return response.data;
  } catch (err) {
    console.log("error importDataFromList" + err);
    return { error: true, message: err.message };
  }
};

export const fetchDeliveryPdf = async (id) => {
  try {
    const response = await axios({
      method: "get",
      url: CONSTS.server + `pdf/delivery/${id}`,
      params: { output: "base64" },
      headers: headersConfig,
    });

    console.log("fetchDeliveryPdf ", response.data.length);
    return response.data;
  } catch (err) {
    console.log("error on fetchDeliveryPdf " + err);
    return null;
  }
};

// -------------------------------------------------------
// -- Report
// ------------------------------------------------------

export const fetchOrdersReport = async () => {
  try {
    const path = "orders/report";
    const response = await axios({
      method: "get",
      url: CONSTS.server + path,
      headers: headersConfig,
    });

    console.log("orders report fetched ", response.data.length || 0);
    return response.data;
  } catch (err) {
    console.log("error on fetchOrdersReport " + err);
    return { error: true, message: err.message };
  }
};
