import React, { Component, useState } from "react";
import CustomerSelect from "../components/CustomerSelect";
import {
  setShipping,
  getShipping,
  setBilling,
  getBilling,
  updateCustomerShipping,
} from "../services/apiService";

const defaults = {
  company: "",
  name: "",
  email: "",
  street: "",
  phone: "",
  city: "",
  state: "",
  zip: "",
  tax_exempted: 0,
};
export default class CheckoutAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: defaults,
      shipping: defaults,
    };
  }
  componentDidMount() {
    this.setState({
      customer: getBilling(),
      shipping: getShipping() || getBilling(),
    });
  }

  handleSelection(customer) {
    let shipping = customer.shipping || customer;
    this.setState({ customer, shipping });
    setShipping(shipping);
    setBilling(customer);
  }

  shippingChanged = (e) => {
    const shipping = {
      ...this.state.shipping,
      [e.target.name]: e.target.value,
    };

    this.setState({ shipping });
  };

  updateShipping = (e) => {
    setShipping(this.state.shipping);
  };

  handleContinue() {
    this.props.history.push("/checkout");
  }

  updateCustomerShippingAddr = () => {
    let ship = this.state.shipping;
    ship["customer_id"] = this.state.customer.id;
    console.log("shipping", ship);
    updateCustomerShipping(ship);
  };

  render() {
    return (
      <div className="page-div">
        <div className="container">
          <div className="pl-4 py-2">
            <button
              className="btn btn-outline-secondary"
              data-toggle="modal"
              data-target="#editorDialog"
            >
              Select Customer
            </button>
            <button
              className="btn btn-outline-primary ml-2"
              onClick={this.handleContinue.bind(this)}
              disabled={!this.state.customer.name}
            >
              Continue
            </button>
          </div>

          <CustomerForm {...this.state} />
          <ShippingForm
            shipping={this.state.shipping}
            changed={this.shippingChanged}
            update={this.updateShipping}
            updateCustomer={this.updateCustomerShippingAddr}
          />
          <CustomerSelect selectItem={this.handleSelection.bind(this)} />
        </div>
      </div>
    );
  }
}

const CustomerForm = ({ customer }) => {
  return (
    <div className="m-3 col-md-7">
      <form>
        <div className="form-row">
          <div className="form-group  col-md-6">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              name="company"
              value={customer.company}
              readOnly
            />
          </div>
          <div className="form-group  col-md-6">
            <label htmlFor="name">Contact</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={customer.name}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={customer.email}
              readOnly
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              className="form-control"
              name="phone"
              value={customer.phone}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="street">Address</label>
            <input
              type="text"
              className="form-control"
              name="street"
              value={customer.street}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="city">City</label>
            <input
              type="text"
              className="form-control"
              name="city"
              value={customer.city}
              readOnly
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="state">State</label>
            <input
              name="state"
              className="form-control"
              value={customer.state}
              readOnly
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="zip">Zip</label>
            <input
              type="text"
              className="form-control"
              name="zip"
              value={customer.zip}
              readOnly
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-inline form-group col-md-12">
            <label htmlFor="street">Tax Exempted</label>
            <input
              type="checkbox"
              className="ml-3 form-control bigger-ck"
              name="tax_exempted"
              checked={customer.tax_exempted}
              readOnly
            />
          </div>
        </div>
      </form>
      <div></div>
    </div>
  );
};

const ShippingForm = ({ shipping, changed, update, updateCustomer }) => {
  const [showUpdate, setShowUpdate] = useState(false);
  return (
    <div
      className="m-3 p-4 col-md-7 border border-secondary rounded"
      onMouseEnter={() => setShowUpdate(true)}
      onMouseLeave={() => setShowUpdate(false)}
    >
      <div className="mb-4 text-secondary">
        <span>Shipping</span>
        <span className="pull-right">
          {showUpdate && (
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={updateCustomer}
              title="Update Customer's Shipping"
            >
              <i class="fa fa-pencil-square-o"></i>
            </button>
          )}
        </span>
      </div>
      <form>
        <div className="form-row">
          <div className="form-group  col-md-12">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={shipping.name}
              onChange={changed}
              onBlur={update}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              className="form-control"
              name="phone"
              value={shipping.phone}
              onChange={changed}
              onBlur={update}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="street">Address</label>
            <input
              type="text"
              className="form-control"
              name="street"
              value={shipping.street}
              onChange={changed}
              onBlur={update}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="city">City</label>
            <input
              type="text"
              className="form-control"
              name="city"
              value={shipping.city}
              onChange={changed}
              onBlur={update}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="state">State</label>
            <input
              name="state"
              className="form-control"
              value={shipping.state}
              onChange={changed}
              onBlur={update}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="zip">Zip</label>
            <input
              type="text"
              className="form-control"
              name="zip"
              value={shipping.zip}
              onChange={changed}
              onBlur={update}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
