import React, { Component } from 'react';

const divStyle = {
    paddingTop: "60px",
};


export class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            remember: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleSubmit(event) {
        event.preventDefault();
        console.log("sign up was called " + JSON.stringify(this.state));
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div className="container" style={divStyle}>
            <div className="row auth-form">
                <div className="col-md-8 col-md-offset-2">
                    <div className="card" >
                        <div className="auth-form-heading card-header card-title">Login</div>
                        <div className="card-body">
                            <form className="form-horizontal" method="POST" action="http://leads.strongrockpavers.com/login">
                                <div className="form-group">
                                    <label className="col-md-4 control-label">E-Mail Address</label>
                                    <div className="col-md-6">
                                        <input type="email" className="form-control" name="email" value={this.state.email} onChange={this.handleChange}/> 
                                    </div>
                                </div>
        
                                <div className="form-group">
                                    <label className="col-md-4 control-label">Password</label>
                                    <div className="col-md-6">
                                        <input type="password" className="form-control" name="password" value={this.state.password} onChange={this.handleChange} /> 
                                    </div>
                                </div>
        
                                <div className="form-group">
                                    <div className="col-md-6 col-md-offset-4">
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" name="remember" checked={this.state.remember} onChange={this.handleChange} /> Remember Me
                                            </label>
                                        </div>
                                    </div>
                                </div>
        
                                <div className="form-group">
                                    <div className="col-md-6 col-md-offset-4">
                                        <button type="submit" className="btn btn-primary" onClick={this.handleSubmit}>
                                            <i className="fa fa-btn fa-sign-in"></i>Login
                                        </button>
                                        <a className="btn btn-link" href="http://leads.strongrockpavers.com/password/reset">Forgot Your Password?</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default Signup;




