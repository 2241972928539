import React, { Component } from 'react';
import { CONSTS } from './constants';
import { formatMoney } from './services/utils';



class MailingListTbItem extends Component {	
  render() {
    const slab = this.props.slab;
    return (
            <tr  style={{height: '150px', backgroundColor: '#F8F8F8', marginBottom:'10px'}}>
              <td>
                <div>
                <a href={CONSTS.imgBaseUrl+"thumbnails/"+slab.filename} > 
                <img style={{height:'150px', width:'150px'}} src={CONSTS.imgBaseUrl+"thumbnails/"+slab.filename} alt={"img"+slab.id}/>
                </a>
                </div>
              </td>
              <td>
                <div className="desc" style={{padding:'10px'}}>
                  <div style={{fontSize: 'xx-large' }} >{slab.material}{slab.limited>0 && '*'}</div>
                  <div style={{fontSize: 'large', margin:'5px 0'}}>
                      <span style={{margin: '0px 0 0 0px'}}>Unit Price: </span>
                      <span style={{fontWeight:'bold', color: slab.limited>0 ? '#FF4136' : 'black'  }}>{formatMoney(slab.unit_price, 2, '')}</span>
                  </div>
                  <div style={{fontSize: 'large', margin:'5px 0'}}>
                      <span style={{margin: '0px 0 0 0px'}}>Bundle Price: </span>
                      <span style={{fontWeight:'bold', color: slab.limited>0 ? '#FF4136' : 'black' }}>{formatMoney(slab.bundle_price, 2, '')}</span>
                  </div>
                </div>
              </td>
            </tr>
      )
  }
}

export default MailingListTbItem;

