import React from 'react'

const Contact = () => {
  return (
    <div className="page" id="contact">
    <div className="container">
      <h2>Contact</h2>
      <div className="row" style={{padding: "20px"}}>
        <div className="col-sm-8">
          <a href="https://goo.gl/maps/WoQZY4MHzTC2" target="_blank"  rel="noopener noreferrer">
          <img id="map" src="../images/google_map.png" alt="map"/>
          </a>
        </div>
        <div className="col-sm-4">
          <div style={{marginTop: "30px", marginBottom: "40px"}}>
            <p>
              Give us a call or write to us, we’re always happy to help out with any questions you may have.
            </p>
          </div>
          <h4>Get in Touch!</h4>
          <p>
            <a className='contacts' href="tel:8016879500">(801) 687-9500</a> <br />
            <a className="contacts" href="mailto:info@graniteworldutah.com">info@graniteworldutah.com</a> <br />
            <br />
                 {/* <!-- https://stackoverflow.com/questions/24470517/call-to-gps-app-via-html --> */}
            <a className="contacts" href="https://goo.gl/maps/WoQZY4MHzTC2" target="_blank"  rel="noopener noreferrer"> 
            2176 W Center St.<br />
            Provo, UT 84601
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Contact
