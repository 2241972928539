import React from 'react'

const PackingInfo = (props) => {
  const {InputChange, PackingList} = props;
  return (
    <div>
    <div className="row my-3 pt-3 mx-0 bg-light">
      <div className="form-group col-md-4">
        <label  htmlFor="invoice">Invoice</label>
        <input type="search" 
          className="form-control data-invoice"
          name="invoiceNum" 
          value={PackingList.invoiceNum}  
          onChange={InputChange} />
      </div>
      <div className="form-group col-md-4">
        <label  htmlFor="invoiceDate">Invoice Date</label>
        <input type="search" 
          className="form-control data-invoicedate" 
          name="invoiceDate" 
          value={PackingList.invoiceDate} 
          onChange={InputChange}  />
      </div>
      <div className="form-group col-md-4">
        <label  htmlFor="receivedAt">Received Date</label>
        <input type="search" 
          className="form-control data-receivedat " 
          name="receivedAt" 
          value={PackingList.receivedAt} 
          onChange={InputChange}  />
      </div>
    </div> 
  </div>
  );
}

export default PackingInfo;