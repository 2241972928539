import React, { Component } from 'react'

const divStyle = {
    marginTop: "80px",
};


export class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fname: "",
            lname: "",
            email: "",
            phone: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            hasAgreed: false,
            license:""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleSubmit(event) {
        console.log("sign up was called " + JSON.stringify(this.state));
        event.preventDefault();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div className="row" style={divStyle}>
                <div className="container">
                    <h2>Registration Form</h2>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label  htmlFor="fname">First Name</label>
                                <input type="text" className="form-control" name="fname" 
                                value={this.state.fname} 
                                onChange={this.handleChange} 
                                placeholder="Joe" />
                            </div>
                            <div className="form-group col-md-6">
                                <label   htmlFor="lname">Last Name</label>
                                <input type="text" className="form-control" name="lname" value={this.state.lname} onChange={this.handleChange} placeholder="Schmoe" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label  htmlFor="email">Email</label>
                                <input type="text" className="form-control" name="email" value={this.state.email} onChange={this.handleChange} placeholder="Email" />
                            </div>
                            <div className="form-group col-md-6">
                                <label  htmlFor="phone">Phone</label>
                                <input type="tel" className="form-control" name="phone" value={this.state.phone} onChange={this.handleChange} placeholder="888-000-9999" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label  htmlFor="email">License #</label>
                                <input type="text" className="form-control" name="license" value={this.state.license} onChange={this.handleChange} placeholder="" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label  htmlFor="street">Address</label>
                                <input type="text" className="form-control" name="street" value={this.state.street} onChange={this.handleChange} placeholder="1234 Main St" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label  htmlFor="city">City</label>
                                <input type="text" className="form-control" name="city"
                                value={this.state.city} onChange={this.handleChange} />
                            </div>
                            <div className="form-group col-md-4">
                                <label  htmlFor="state">State</label>
                                <select name="state" className="form-control" onChange={this.handleChange}>
                                    <option  value="">Choose...</option>
                                    <option>UT</option>
                                </select>
                            </div>
                            <div className="form-group col-md-2">
                                <label  htmlFor="zip">Zip</label>
                                <input type="text" className="form-control" name="zip" value={this.state.zip} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="hasAgreed"  checked={this.state.hasAgreed} onChange={this.handleChange} />
                                <label className="form-check-label"  htmlFor="hasAgreed">I Agree to terms and conditions
                                </label>
                            </div>
                        </div>
                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Send</button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Signup;

//todo go bootstrap to find forms samples




