import React, {useMemo, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';



const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676',
  color: '#7F7F7F'
};

const rejectStyle = {
  borderColor: '#ff1744',
  color: '#ff1744'
};

const FileDropZone = ({ process }) => {
  const onDrop = useCallback(acceptedFiles => {
    for(let file of acceptedFiles)
    {
      console.log("file "+file.name);
    }
    process(acceptedFiles);
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: "image/*, application/pdf", onDrop});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container my-3">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        {
          isDragAccept ? <p>Drop the files here ...</p> :
          isDragReject ? <p>File type not allowed...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
        }
      </div>
    </div>
  );
}

export default FileDropZone;