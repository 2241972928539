

//todo start with null;
export default function slabs(state = null, action) {
    switch (action.type) {
        case 'ADD_SLAB':
            const _state = state || []; 
            return _state.concat(action.slab);
        case 'UPDATE_SLAB':
            const slabs = state.map((item, index) => {
                if (parseInt(item.id) !== parseInt(action.updates.id)) { 
                    // This isn't the item we care about - keep it as-is
                    return item
                }
                // Otherwise, this is the one we want - return an updated value
                return {
                    ...item,
                    ...action.updates
                }
            });

            return slabs;
        case 'DELETE_SLAB':
            const removed = state.filter(({id}) => id !== action.id);
            return removed;

        case 'SLABS_FETCHED':
            return action.payload;
        
        case 'APPEND_SLABS':
            let _slabs = (state || []).concat(action.payload);
            return _slabs;
        
        default:
            return state;
    }
}