import React, { Component } from 'react';
import axios from 'axios';

//axios https://github.com/axios/axios
export default class TestToken extends Component {
    state = {profile: 'placehold'}
    componentDidMount(){
        var access_token = localStorage.getItem('access_token');
        
        var config = {
            headers: { 'Authorization': "Bearer " + access_token,
                       "client_id": "jOGjInqLhzztLY3CgdUfI3ty0lnEP2OA"
            }
        };

        console.log('access_token', access_token);

        // axios.get(url[, config])
        // axios.post(url[, data[, config]])
        axios.post('http://localhost/api/private', {token: 'mytoken'}, config
        ).then((response) => {
            this.setState({profile: JSON.stringify(response)})
            console.log('private call ', response.data)
        }).catch((error) => {
            this.setState({profile: "error: "+error.message});
            console.log(error)
        });
    }

    render() {
        //const { profile } = this.state;
        return (
        <div style={{marginTop:80, backgroundColor:'#fff'}}>
            <h2>Hello Token</h2>
            {(this.state.profile)? <p>---{ this.state.profile  }</p>: <p>No Profile</p>}
            { (window.globalVars)? <p>{window.globalVars.appname}</p>: <p>No global vars</p>}
        </div>
        )
    }
}
