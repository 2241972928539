import React from "react";

const Terms = () => {
  return (
    <div className="container bg-white p-5 mt-5">
      <div className="px-4 terms-text">
        <h1>Purchase Terms and Conditions</h1>
        <p>
          These terms and conditions ("Agreement") apply to all sales of slabs ("Products") by Granite World, LLC ("Seller")
          to any purchaser ("Buyer"). By purchasing Products from Seller, Buyer
          agrees to be bound by these terms and conditions.
        </p>
        <ol>
          <li>
            <strong>Final Sale Policy:</strong> All sales of Products are final.
            Seller does not accept returns or exchanges except as expressly
            agreed upon in writing by the Seller. Buyer acknowledges and agrees
            that:
            <ol type="i">
              <li>
                Once an order is placed and payment is received, it cannot be
                cancelled or modified.
              </li>
              <li>
                Seller does not guarantee availability of Products and reserves
                the right to limit quantities and discontinue any Product at any
                time without notice.
              </li>
              <li>
                Any exceptions to this final sale policy must be approved in
                writing by Seller prior to purchase.
              </li>
            </ol>
          </li>
          <li>
            <strong>Product Inspection:</strong> Buyer acknowledges and agrees
            that it is essential and mandatory to thoroughly inspect all
            purchased Product(s) before taking possession, delivery, or
            completing the purchase/sale. Buyer further agrees that any claims
            for defects, damage, or non-conformity, including but not limited to
            fit, form, or function, must be reported and resolved prior to
            taking possession, completing the purchase/sale, receiving a
            delivery, or any similar action which each independently constitute
            the finalization of the sale or receipt of the Product.
          </li>
          <li>
            <strong>Acceptance Upon Action:</strong> Failure to inspect the
            Product(s) and report any defects, damages, or non-conformities in
            accordance with the above clause shall constitute acceptance of the
            Product(s) in its delivered condition.
          </li>
          <li>
            <strong>Delivery and Restocking Fee:</strong> Once a product is
            loaded onto the delivery truck and the truck leaves Granite World,
            LLC premise, if Buyer decides to cancel the delivery, a delivery
            cancelation fee of $300 will apply. Buyer acknowledges and agrees to
            this delivery cancellation fee upon placing the order.
          </li>
          <li>
            <strong>Limited Warranty:</strong> Seller provides no warranty or
            coverages for Product(s) purchased:
            <ol type="i">
              <li>
                <strong>Coverage:</strong> Buyer acknowledges and agrees that
                Seller does not provide any warranty, express or implied,
                including but not limited to:
                <ol type="a">
                  <li>defects in material(s)</li>
                  <li>workmanship,</li>
                  <li>merchantability</li>
                  <li>fitness for a particular purpose, form or function</li>
                  <li>
                    or any other form or type of warranty or coverage for any
                    reason.
                  </li>
                </ol>
              </li>
              <li>
                <strong>Exclusions:</strong> Seller does not warrant or provide
                coverage against:
                <ol type="a">
                  <li>
                    Normal wear and tear. (damage(s) resulting from normal wear
                    and tear or usage)
                  </li>
                  <li>
                    Improper Fabrication, Installation, Use, or Maintenance.
                    (Damage caused by improper fabrication, installation, use or
                    maintenance of the product(s))
                  </li>
                  <li>
                    Variation in Slab Color, Veining, or Texture. (Variations in
                    natural or unnatural slab color, veining, or texture, which
                    are inherent and part of the natural or unnatural makeup and
                    content of each individual slab)
                  </li>
                </ol>
              </li>
              <li>
                <strong>Remedy:</strong> Buyer acknowledges and agrees that
                Seller shall not be liable for any defects, damages, or issues
                arising from the Product(s), whether due to their proper or
                improper use or otherwise. Buyer accepts the Product(s) in their
                current condition "as is" at the time of sale, without any
                recourse against the Seller for any issues related to the
                Product(s).
              </li>
            </ol>
          </li>
          <li>
            <strong>Limitation:</strong> To the fullest extent permitted by law,
            these sections advising the Buyer that there is no limited warranty,
            no coverages, and no remedies, are exclusive and in lieu of all
            other warranties, whether express or implied, including but not
            limited to any implied warranties of merchantability or fitness for
            a particular purpose.
          </li>
          <li>
            <strong>Limitation of Liability:</strong> To the fullest extent
            permitted by law, Seller shall not be liable for any indirect,
            incidental, special, consequential, or punitive damages, or any loss
            of profits or revenues, whether incurred directly or indirectly, or
            any loss of data, use, goodwill, or other intangible losses, arising
            out of or in connection with the purchase, use, or inability to use
            the Products.
          </li>
          <li>
            <strong>Indemnification:</strong> Buyer agrees to indemnify, defend,
            and hold harmless Seller and its affiliates, officers, directors,
            employees, agents, successors, and assignees from and against any
            and all losses, damages, liabilities, deficiencies, claims, actions,
            judgments, settlements, interest, awards, penalties, fines, costs,
            or expenses of whatever kind, including reasonable attorneys' fees,
            arising out of or relating to Buyer's purchase or use of the
            Products.
          </li>
          <li>
            <strong>Payment:</strong> Buyer agrees to pay the full purchase
            price specified in Seller's invoice promptly upon receipt of such
            invoice. Present terms are upon possession, sale, or delivery of
            Product.
          </li>
          <li>
            <strong>Governing Law:</strong> This Agreement shall be governed by
            and construed in accordance with the laws of Utah and the United
            States of America, without regard to its conflicts of laws
            principles.
          </li>
          <li>
            <strong>Entire Agreement:</strong> This Agreement constitutes the
            entire agreement between Buyer and Seller regarding the purchase of
            the Products and supersedes all prior agreements and understandings,
            whether written or oral.
          </li>
          <li>
            <strong>Amendment:</strong> Seller reserves the right to modify
            these terms and conditions at any time. Such modifications shall be
            effective immediately upon posting on Seller's website or other
            notice to Buyer.
          </li>
          <li>
            <strong>Acceptance:</strong> By signing the invoice or otherwise
            indicating acceptance of the Products, Buyer acknowledges that they
            have read, understood, and agree to be bound by these terms and
            conditions. Seller acknowledges that possession, pickup or delivery
            of the Products by Buyer constitutes acceptance and completion of
            the sale.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Terms;
