import React, { Component } from "react";
import { fetchModel } from "../services/apiService";
import { Spinner } from "../components/dialogs";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import InventoryGraph from "./InventoryGraph";

const headers = [
  { label: "Bundle", key: "bundle_num" },
  { label: "Number", key: "slab_num" },
  { label: "Material", key: "material" },
  { label: "Height", key: "height" },
  { label: "Length", key: "length" },
  { label: "Thick", key: "thick" },
  { label: "SqFt", key: "sqft" },
  { label: "Unit Price", key: "unit_price" },
  { label: "Bundle Price", key: "bundle_price" },
  { label: "Recorded At", key: "created_at" },
];
export default class Inventory extends Component {
  filtered = [];
  state = {
    slabs: [],
    search: "",
    loading: true,
    csvData: [],
  };

  async componentDidMount() {
    const slabs = await fetchModel("slabs");
    //console.log('slabs ', slabs);
    this.setState({ slabs, filtered: slabs });
    this.showSpinner(false);
  }

  handleChange = (event) => {
    const name = event.target.name;

    this.setState({
      [name]: event.target.value,
    });

    if (name === "search") {
      this.filterSlabs(event.target.value);
    }
  };

  filterSlabs = (search) => {
    const { slabs } = this.state || [];
    const filtered = slabs.filter((slab) => {
      const material = slab.material || "";
      const slab_num = slab.slab_num || "";
      const exists =
        material.toLowerCase().includes(search) || slab_num.includes(search);
      return exists;
    });
    this.setState({ filtered });
  };

  showSpinner = (value) => {
    this.setState({ loading: value });
  };

  handleActiveChange = (e, slab) => {
    const value = e.target.checked;
    const _slabs = this.state.slabs || [];
    const slabs = _slabs.map((item) => {
      if (slab.id === item.id) return { ...item, active: value };
      return item;
    });

    this.setState({ slabs });
  };

  exportList = (data) => {
    this.setState({ csvData: data });
  };

  render() {
    const filtered = this.state.filtered || []; // this.state.slabs.filter(item => item.slab_num.startsWith(this.state.search.toLowerCase()) );

    return (
      <div className="page-s">
        <Spinner isVisible={this.state.loading} />
        <div className="float-left">
          <div className="list-group">
            <Link
              className="list-group-item list-group-item-action"
              to="/tools/reports"
            >
              Reports
            </Link>
          </div>
        </div>
        <div className="container">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#inventory-list"
                role="tab"
                aria-controls="list"
                aria-selected="true"
              >
                List
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                data-toggle="tab"
                href="#inventory-tree"
                role="tab"
                aria-controls="tree"
                aria-selected="false"
              >
                Tree
              </a>
            </li>
          </ul>
          <div className="tab-content">
            <div
              className="tab-pane fade show active"
              id="inventory-list"
              role="tabpanel"
              aria-labelledby="list-tab"
            >
              <h5 className="mt-4">Slab Inventory</h5>
              <div className="row my-4">
                <div className="col-sm-3 mb-2">
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="Search #"
                    value={this.state.search}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-sm-3">
                  <label className="count-label">Count {filtered.length}</label>
                </div>
                <div className="mt-1">
                  <CSVLink
                    data={this.state.csvData}
                    headers={headers}
                    onClick={() => this.exportList(filtered)}
                    filename={"slab_inventory.csv"}
                  >
                    Download CSV
                  </CSVLink>
                </div>
              </div>
              <div className="">
                {filtered.map((item) => (
                  <div
                    key={item.id}
                    className="bg-light p-2 mb-2 border border-gray"
                  >
                    <span className="ml-2">{item.section} </span>
                    <span className="ml-0">{item.rack} </span>
                    <span className="ml-4"> {item.slab_num} </span>
                    <div className="ml-4 d-inline" style={{ width: "400x" }}>
                      {item.material}{" "}
                    </div>
                    <span className="ml-4"> {item.note} </span>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="inventory-tree"
              role="tabpanel"
              aria-labelledby="tree-tab"
            >
              <InventoryGraph />
            </div>
          </div>{" "}
          {/* tab-content */}
        </div>{" "}
        {/* container */}
      </div>
    );
  }
}
