//@ts-check
/*eslint semi: "error" */
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchOrders } from "../../services/apiService";
import moment from "moment";
import { formatMoney } from "../../services/utils";
import { fetchedWeekSalesAction } from "../../actions";
import isEmpty from "lodash.isempty";
import { Spinner } from "../../components/dialogs";
import { Link } from "react-router-dom";

class LastWeekSales extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.showSpinner(true);

    if (isEmpty(this.props.weekSales)) {
      const res = await fetchOrders(null, "lastweek");
      this.props.dispatch(fetchedWeekSalesAction(res));
    }

    this.showSpinner(false);
  }

  /**
   * @param {boolean} option
   */
  showSpinner = (option) => {
    this.setState({ loading: option });
  };

  handleChange = () => {
    const a = "";
    return a;
  };

  formatDate = (date) => {
    return moment(date).format("dddd, MMMM DD, YYYY");
  };

  /**
   * @return {string}
   */
  getTotalAmount = (orders) => {
    let total = 0;
    for (const order of orders) {
      if (order.total) total += Number(order.total);
    }
    return formatMoney(total);
  };

  goToOrder = (id) => {
    this.props.history.push(`/orders/${id}`);
  };

  /**
   * @param {any} orders
   */
  getCountByMaterial(orders) {
    const groups = orders.reduce((rv, order) => {
      order.items.map((item) => {
        const slab = item.slab || {};
        const group_key = slab.slug || "";
        (rv[group_key] = rv[group_key] || []).push(slab.material);
        return 1;
      });
      return rv;
    }, {});

    let groupCount = Object.keys(groups).map((key) => {
      const group = groups[key];
      return {
        count: group.length,
        name: group[0],
      };
    });
    groupCount.sort((a, b) => {
      return b.count - a.count;
    });
    return groupCount;
  }

  totalCount = (groups) => {
    return groups.reduce(
      (accumulator, currentItem) => accumulator + currentItem.count,
      0
    );
  };

  render() {
    const orders = this.props.weekSales.orders || [];
    const groupCounts = this.getCountByMaterial(orders);
    const qty = this.totalCount(groupCounts);
    const { startDate, endDate } = this.props.weekSales;
    const total = this.getTotalAmount(orders);
    const startDt = this.formatDate(startDate);
    const endDt = this.formatDate(endDate);

    return (
      <div className="page-s ">
        <Spinner isVisible={this.state.loading} />
        <div className="row">
          <div className="bg-grey col-sm-2" style={{ height: "100vh" }}>
            <div className="list-group list-group-flush">
              <Link
                className="list-group-item list-group-item-action"
                to="/tools/reports"
              >
                Reports
              </Link>
              {/* <Link className='list-group-item list-group-item-action active' to='/reports/lastweek' >Last Week</Link> */}
              <Link
                className="list-group-item list-group-item-action"
                to="/reports/sales"
              >
                Sales
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                to="/reports/powerbi"
              >
                Power BI
              </Link>
            </div>
          </div>
          <div className="col-sm-8 p-5">
            <h4>Week Sales Report</h4>
            <OrdersSummary
              total={total}
              qty={qty}
              startDate={startDt}
              endDate={endDt}
            />
            <SalesDetails orders={orders} click={this.goToOrder} />
            <GraniteCountTable orders={groupCounts} />
          </div>
        </div>
      </div>
    );
  }
}

const OrdersSummary = (props) => {
  const { startDate, endDate, total, qty } = props;

  return (
    <div className="row">
      <table>
        <tbody>
          <tr>
            <td>From:</td>
            <td className="font-weight-bold pl-1">{startDate}</td>
          </tr>
          <tr>
            <td>To:</td>
            <td className="font-weight-bold pl-1 pb-2">{endDate}</td>
          </tr>
          <tr className="bg-light">
            <td className="pl-2">Total:</td>
            <td className="font-weight-bold p-2 pull-right">
              {total} (C: {qty})
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const SalesDetails = (props) => {
  return (
    <div className="row">
      <h5 className="mt-4">Details</h5>
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {props.orders.map((item, i) => (
            <tr key={i} onClick={() => props.click(item.id)}>
              <td> {item.id} </td>
              <td> {moment(item.created_at).format("M/D/YYYY, h:mm a")} </td>
              <td> {formatMoney(item.total)} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const GraniteCountTable = (props) => {
  return (
    <div className="row">
      <h5 className="mt-4">Slab Materials</h5>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {props.orders.map((item, i) => (
            <tr key={i}>
              <td> {item.name} </td>
              <td> {item.count} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  weekSales: state.weekSales,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(LastWeekSales);
