import React, { Component } from 'react';
import { slabInitialState, isEmptyObj, fixImageNotFound, imageurl } from '../services/utils';
import { imageNoFoundName } from './../constants';
//todo take a look at formik


class SlabEditModal extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            ...slabInitialState, 
            original: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        const slab = !isEmptyObj(nextProps.slab)? nextProps.slab: slabInitialState;
        this.setState(slab);
        this.setState({original: slab.filename, reserved: slab.reserved || ''});
    }

    //todo new componentWillReceiveProps(nextProps) {
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (nextProps.slab !== prevState) {
    //        return ( nextProps.slab  )
    //     }
    // }

    handleCreate = () => {
        this.props.createSlab(this.state);
    }

    handleSave = () => {
        this.props.updateSlab(this.state);
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeWithCase = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value.toUpperCase()
        });
    }

    handleReservedChange = (event) => {
        const target = event.target;
        const time = new Date().getTime();
        this.setState({reserved: target.checked? time:''})
    }

    fileSelected = () => {
        const reader = new FileReader();
        const image = this.imagePreviewer;
        const file = this.fileBrowser.files[0];

        reader.onload = () => {
            image.src = reader.result;
        }
        reader.readAsDataURL(file);

        const fd = new FormData();
        fd.append('file', file, file.name);
        this.setState({fileToUpload: fd});
    }

    handleClose = () => {
        this.imagePreviewer.src = imageurl(this.state.original);
    }

    render() {
        const readonly =  false;

        return (
            <div className="modal fade" id="exampleModal" tabIndex="-1" data-backdrop="static"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.state.id? 'Edit Slab': 'Create Slab'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={ this.handleClose }>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                        <div className="form-horizontal ml-2" style={{backgroundColor: 'white'}}>
                            <div className='row mb-3'>
                                <img style={{width: 278, height: 181, margin: '0 auto'}} 
                                     src={imageurl(this.state.filename)} 
                                     alt={imageurl(this.state.filename)}
                                     ref={(me) => this.imagePreviewer = me }
                                     onError={ (e) => fixImageNotFound(e, imageNoFoundName) }
                                     />
                                
                                <button type="button"  
                                    className="form-control btn btn btn-secondary"
                                    onClick={() => this.fileBrowser.click()  } >
                                    Choose new image
                                </button>
                                <input className='d-none' 
                                       type='file' 
                                       ref={(me) => this.fileBrowser = me} 
                                       onChange={ this.fileSelected } />
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Material</label>
                                <div className="col-md-6">
                                    <input type="text" 
                                    className={"form-control " + (readonly? "form-control-plaintext ": "")} 
                                    name="material" 
                                    value={this.state.material} 
                                    onChange={this.handleChange} />
                                </div>
                            </div> 
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Number</label>
                                <div className="col-md-6">
                                    <input type="text"  
                                    className="form-control" 
                                    name="slab_num" 
                                    value={this.state.slab_num} 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Bundle</label>
                                <div className="col-md-6">
                                    <input type="text"  
                                    className="form-control" 
                                    name="bundle_num" 
                                    value={this.state.bundle_num} 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Filename</label>
                                <div className="col-md-6">
                                    <input type="text"  
                                    className="form-control" 
                                    name="filename" 
                                    value={this.state.filename} 
                                    onChange={this.handleChange} />
                                </div>
                                <div className="col-md-3">
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Length</label>
                                <div className="col-md-6">
                                    <input type="text" 
                                    className="form-control" 
                                    name="length" 
                                    value={this.state.length} 
                                    onChange={this.handleChange} />
                                </div>
                                <img src="images/length.png" alt='length' className='my-auto'></img>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Height</label>
                                <div className="col-md-6">
                                    <input type="text" 
                                    className="form-control" 
                                    name="height" value={this.state.height} 
                                    onChange={this.handleChange} />
                                </div>
                                <img src="images/height.png" alt='height' className='my-auto'></img>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">SQFT</label>
                                <div className="col-md-6">
                                    <input type="text" 
                                    className="form-control" 
                                    readOnly
                                    name="sqft" value={this.state.sqft} 
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Location</label>
                                <div className="input-group col-md-6">
                                    <input type="text" 
                                        className="form-control mr-1" 
                                        name="section" 
                                        value={this.state.section} 
                                        onChange={this.handleChangeWithCase}
                                        placeholder="A" />
                                       
                                     <input type="text" 
                                        className="form-control ml-1" 
                                        name="rack" 
                                        value={this.state.rack} 
                                        onChange={this.handleChange} 
                                        placeholder="1"/>                           
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Unit Price</label>
                                <div className="col-md-6">
                                    <input type="text" 
                                    className="form-control" 
                                    name="unit_price" 
                                    value={this.state.unit_price || ''} 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Bundle Price</label>
                                <div className="col-md-6">
                                    <input type="text" 
                                    className="form-control" 
                                    name="bundle_price" 
                                    value={this.state.bundle_price || ''} 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Origin</label>
                                <div className="col-md-6">
                                    <input type="text" 
                                    className="form-control" 
                                    name="origin" 
                                    value={this.state.origin} 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Damages</label>
                                <div className="col-md-9">
                                    <input type="text" 
                                    className="form-control" 
                                    name="damages" 
                                    value={this.state.damages || ''} 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Reserved</label>
                                <div className="col-md-2 mt-2 ml-0">
                                    <input type="checkbox" 
                                    className="form-control bigger" 
                                    name="reserved" 
                                    checked={this.state.reserved}
                                    onChange={this.handleReservedChange} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-md-3 col-form-label">Reserved for</label>
                                <div className="col-md-9">
                                    <input type="text" 
                                    className="form-control" 
                                    name="reserved" 
                                    value={this.state.reserved} 
                                    onChange={this.handleChange} />
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose} >Close</button>
                            { 
                                this.state.id? 
                                <button type="button" className="btn btn-primary" data-dismiss="modal" 
                                    onClick={ this.handleSave }>Save
                                </button>:
                                <button type="button" className="btn btn-primary" data-dismiss="modal" 
                                    onClick={ this.handleCreate }>Create
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SlabEditModal;