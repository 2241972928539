import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { Button } from 'react-bootstrap';
import { getCart } from "./services/apiService";
import { updateCartAction, updateProfileAction } from "./actions";
import $ from "jquery";

const linkActiveStyle = {
  backgroundColor: "#093648",
  fontWeight: "bold",
};

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
    };
  }

  componentDidMount() {
    const { userProfile, getProfile, decodeToken } = this.props.auth;

    if (!userProfile) {
      getProfile((err, profile) => {
        profile = profile || {};
        this.setState({ profile });

        this.props.dispatch(updateProfileAction(profile));
      });
    } else {
      this.setState({ profile: userProfile });
    }

    console.log("profile");
    console.log(this.props.auth.userProfile);
    decodeToken();
    const cart = getCart();
    this.props.dispatch(updateCartAction(cart));
    this.toggleDropdown();
  }

  toggleDropdown = () => {
    $(".leaf").on("click", function() {
      $(".navbar-toggler").click();
    });
  };

  goTo(route) {
    this.props.history.replace(`/${route}`);
  }

  showCart() {
    //this.props.history.replace(`/cart`);
  }

  login() {
    localStorage.setItem("redirect", window.location.pathname);
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const { profile } = this.state;
    const cartItems = this.props.cart.items || [];
    const cart_length = cartItems.length;

    return (
      <nav className="navbar  navbar-expand-lg navbar-dark fixed-top">
        <a className="navbar-brand" style={{ padding: 0 }} href="/">
          <img
            src="images/welovegranite_logo.jpg"
            alt="Granite World"
            style={{ height: 47, marginTop: "-5px" }}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="container">
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                {/* <a class="nav-link" href="/">Home <span class="sr-only">(current)</span></a> */}
                <NavLink
                  className="nav-link leaf"
                  exact
                  to="/"
                  activeStyle={linkActiveStyle}
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link leaf"
                  exact
                  to="/slabs"
                  activeStyle={linkActiveStyle}
                >
                  Slabs
                </NavLink>
              </li>
              <li className="nav-item">
                {isAuthenticated() && (
                  <NavLink
                    className="nav-link leaf"
                    exact
                    to="/orders"
                    activeStyle={linkActiveStyle}
                  >
                    Orders
                  </NavLink>
                )}
              </li>
              <li className="nav-item dropdown">
                {isAuthenticated() && (
                  <NavLink
                    className="nav-link  dropdown-toggle"
                    to="/prices"
                    activeClassName={"active"}
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Pricing
                  </NavLink>
                )}
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        exact
                        to="/prices"
                        activeClassName={"active"}
                      >
                        Prices
                      </NavLink>
                    )}
                  </div>
                  <div className="dropdown-divider"></div>
                  {/* eslint-disable-next-line */}
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        exact
                        to="/mlist"
                        activeStyle={linkActiveStyle}
                      >
                        Mail List
                      </NavLink>
                    )}
                  </div>
                  {/* eslint-disable-next-line */}
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        exact
                        to="/prices/print"
                        activeStyle={linkActiveStyle}
                      >
                        Print List
                      </NavLink>
                    )}
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                {isAuthenticated() && (
                  <a
                    className="nav-link dropdown-toggle"
                    href="/tools"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Tools
                  </a>
                )}
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  {/* <div  className="dropdown-item" >
                  {isAuthenticated() &&<NavLink className="nav-link leaf" to="/slabs/loader"  activeClassName={"active"}>Load CSV</NavLink>}
                </div> */}
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        to="/granites"
                        activeClassName={"active"}
                      >
                        Granite
                      </NavLink>
                    )}
                  </div>
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        to="/tools/payments"
                        activeClassName={"active"}
                      >
                        Payments
                      </NavLink>
                    )}
                  </div>
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        to="/tools/deliveries"
                        activeClassName={"active"}
                      >
                        Delivery
                      </NavLink>
                    )}
                  </div>
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        to="/tools/inactive-slabs"
                        activeClassName={"active"}
                      >
                        Inactive Slabs
                      </NavLink>
                    )}
                  </div>
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        to="/tools/reports"
                        activeClassName={"active"}
                      >
                        Reports
                      </NavLink>
                    )}
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown">
                {isAuthenticated() && (
                  <a
                    className="nav-link dropdown-toggle"
                    href="/tools"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Customers
                  </a>
                )}
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        to="/customers"
                        activeClassName={"active"}
                      >
                        Customers
                      </NavLink>
                    )}
                  </div>
                  <div className="dropdown-item">
                    {isAuthenticated() && (
                      <NavLink
                        className="nav-link leaf"
                        to="/tools/contacts"
                        activeClassName={"active"}
                      >
                        Contacts
                      </NavLink>
                    )}
                  </div>
                </div>
              </li>
            </ul>
            <ul className="navbar-nav navbar-right">
              <li className="nav-item">
                <NavLink
                  className="nav-link leaf cart"
                  exact
                  to="/cart"
                  activeStyle={linkActiveStyle}
                >
                  <span
                    className="fa fa-shopping-cart"
                    style={{ fontSize: 26 }}
                  ></span>
                  {cart_length > 0 && (
                    <span
                      className="badge badge-pill badge-info"
                      style={{ fontSize: 10 }}
                    >
                      {cart_length}
                    </span>
                  )}
                </NavLink>
              </li>
              <li className="nav-item">
                {!isAuthenticated() && (
                  <button
                    id="qsLoginBtn"
                    className="nav-link link-button"
                    onClick={this.login.bind(this)}
                  >
                    Log In
                  </button>
                )}
              </li>
              {isAuthenticated() && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      className="user-avatar"
                      src={profile.picture}
                      alt="profile"
                    ></img>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="dropdown-item">
                      <span className="nav-link leaf">{profile.nickname}</span>
                    </div>
                    <div className="dropdown-divider"></div>
                    <div className="dropdown-item">
                      <button
                        id="qsLogoutBtn"
                        className="nav-link link-button"
                        onClick={this.logout.bind(this)}
                      >
                        Log Out
                      </button>
                    </div>
                    <div className="dropdown-item">
                      <NavLink
                        className="nav-link leaf"
                        to="/a"
                        activeClassName={"active"}
                      >
                        Settings
                      </NavLink>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  profile: state.profile,
});

//export default connect(mapStateToProps)(NavBar);
export default withRouter(connect(mapStateToProps)(NavBar));

// </Route>
// </BrowserRouter>
