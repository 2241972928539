//@ts-check
/*eslint semi: "error" */
import React from 'react';
import { Link } from 'react-router-dom';


const SalesReport = () => {
    return (
      <div className='page-s'>
        <div className='row'>
                <div className='bg-grey col-sm-2' style={{height: '100vh'}}>
                    <div className='list-group list-group-flush'>
                        <Link className='list-group-item list-group-item-action' to='/tools/reports' >Reports</Link>
                        {/* <Link className='list-group-item list-group-item-action' to='/reports/lastweek' >Last Week</Link> */}
                        <Link className='list-group-item list-group-item-action' to='/reports/sales' >Sales</Link>
                        <Link className='list-group-item list-group-item-action active' to='/reports/powerbi' >Power BI</Link>
                    </div>
                </div>
        <div className='col-sm-8'>
          <h3>Granite Sales</h3>
          <div style={{marginBottom: '30px'}}>
            <iframe
              width="1140"
              height="541.25"
              src="https://app.powerbi.com/reportEmbed?reportId=7f110848-59dd-4647-81fa-f50119c15130&autoAuth=true&ctid=0e50b3ec-f04a-4195-a60a-4d8364ebb529&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtdXMtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D" 
              frameborder="0"
              title="powerview-viewer"
              allowFullScreen={true} >
            </iframe>
          </div>
        </div>
      </div>
    </div>
    );
};

export default SalesReport;