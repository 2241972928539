import React, { Component } from "react";
import NavBar from "./NavBar";
import { BrowserRouter } from "react-router-dom";
import MainRoute from "./MainRoute";
import Auth from "./Auth/Auth";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import { createStore } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers";
import { DefineJSExtensions } from "./services/utils";
import { initializeFirebase } from "./services/PushNotification";
import { showNotification } from "./components/dialogs";

const auth = new Auth();
const store = createStore(reducer);
DefineJSExtensions();

const onFCMReceived = (payload) => {
  console.log("message received", payload);
  showNotification(payload);
};

try {
  //firebase only works on https
  initializeFirebase(onFCMReceived);
} catch (error) {
  console.log("error firebase app ", error);
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
    };
  }

  componentDidMount() {
    window.globalVars = {
      appname: "Granite World React",
      version: "1.1.34",
    };
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div>
            <NavBar auth={auth} />
            <MainRoute auth={auth} />
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
