/**
 * @param {any[]} list
 */
export const totalSQFT = (list) => {
  const calc = (accumulator, current) => accumulator + Number(current.sqft);
  const sqftSum = list.reduce(calc, 0);
  return sqftSum.toFixed(2);
}

export const fullTotalSQFT = (bundles) => {
  let total = 0;
  for(const bundle of bundles)
  {
    total += Number(totalSQFT(bundle.slabs || []));
  }
  return total.toFixed(2);
}

export const fullTotalSlabs = (bundles) => {
  let total = 0;
  for(const bundle of bundles)
  {
    total += bundle.slabs.length;
  }
  return total;
}

const groupBy = key => array => {
  return array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
}

const GroupByBundle = groupBy('bundle');

export const bundleGroups = (slabs) => {
  const groups =GroupByBundle(slabs);
  let bundles = [];
  for(const key of Object.keys(groups))
  {
    bundles.push(
      {
        number: key,
        filename: "",
        slabs: groups[key] || []
      }
    );
  }

  return bundles;
}