
export default function granites (state = null, action) {
    switch (action.type) {
        case 'ADD_GRANITE':
            const _state = state || [];
            return _state.concat(action.granite).sort(sortGranites)

        case 'UPDATE_GRANITE':
            const granites = state.map((item, index) => {
                if (parseInt(item.id) !== parseInt(action.updates.id)) { 
                    // This isn't the item we care about - keep it as-is
                    return item
                }
                // this is the one we want - return an updated value
                return {
                    ...item,
                    ...action.updates
                }
            });
            return granites; 

        case 'DELETE_GRANITE':
            return state.filter(({id}) => id !== action.id);

        case 'GRANITES_FETCHED':
            return action.payload;
            
        default:
            return state;
    }
}

const sortGranites = (a, b) => {
    if(a.name < b.name) return -1;
    if(a.name > b.name) return 1;
    return 0;
};