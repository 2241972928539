import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import {
  fetchOrders,
  deleteOrder,
  fetchOrderPDF,
  createPayment,
} from "../services/apiService";
import { formatMoney } from "./../services/utils";
import {
  showAlert,
  showDeleteConfirmation,
  Spinner,
} from "../components/dialogs";
import { fetchedOrdersAction, updateOrderAction } from "../actions";
import { deleteOrderAction } from "./../actions/index";
import PaymentEditModal from "../components/PaymentEdit";
import { CONSTS } from "../constants";
import classNames from "classnames";
import { CSVLink } from "react-csv";

const headers = [
  { label: "Number", key: "id" },
  { label: "Customer", key: "customer" },
  { label: "Customer Address", key: "address" },
  { label: "Line Items", key: "items" },
  { label: "Subtotal", key: "subtotal" },
  { label: "Taxes", key: "tax" },
  { label: "Fees", key: "total_fees" },
  { label: "Discounts", key: "total_discounts" },
  { label: "Total", key: "total" },
  { label: "Payments", key: "payments" },
  { label: "Total Payment", key: "totalPayment" },
  { label: "Date", key: "created_at" },
];

class Orders extends Component {
  state = {
    order: null,
    loading: true,
    pdffile: null,
    selectedOrder: {},
    search: "",
    months: 1,
    date: null,
    reps: [],
    csvData: [],
    selectedRep: "-1",
  };

  async componentDidMount() {
    this.showSpinner();

    if (!this.props.orders) {
      const data = await fetchOrders();
      this.props.dispatch(fetchedOrdersAction(data));
    }
    this.setReps();
    this.hideSpinner();
  }

  showInvoice = async (id) => {
    this.props.history.push("/orders/" + id);
  };

  showInvoicePDF = async (id) => {
    this.showSpinner();
    const file = await fetchOrderPDF(id);
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.state.pdffile = file;
    }
    this.hideSpinner();
  };

  selectOrder = (item) => {
    this.setState({ selectedOrder: item });
  };

  deleteInvoice = async (id) => {
    showDeleteConfirmation(async () => {
      this.showSpinner();

      const result = await deleteOrder(id);
      if (!result.error) {
        this.props.dispatch(deleteOrderAction(id));
        showAlert("Order deleted", "success");
      } else {
        showAlert("Order couldn't be deleted", "error");
      }

      this.hideSpinner();
    });
  };

  handleAddPayment = async (data) => {
    this.showSpinner();

    const result = await createPayment(data);
    if (!result.error) {
      const res = await fetchOrders(data.order_id);
      if (!res.error) {
        this.props.dispatch(updateOrderAction(res));
      }
      showAlert("Payment added", "success");
    } else {
      showAlert("Payment couldn't be added", "success");
    }

    this.hideSpinner();
    this.setState({ selectedInvoice: {} });
  };

  showSpinner = () => {
    this.setState({ loading: true });
  };

  hideSpinner = () => {
    this.setState({ loading: false });
  };

  closeView = () => {
    this.setState({ order: null });
  };

  totalPayments = (payments = []) => {
    const total = payments.reduce(
      (accumulator, item) => (accumulator += Number(item.amount)),
      0
    );
    return total;
  };

  paidStatusStyle = (order) => {
    const balance = this.orderBalance(order);
    const style =
      balance > 0
        ? "fa fa-exclamation-circle text-danger"
        : "fa fa-check-circle text-success";
    return style;
  };

  paidStatusClass = (order) => {
    const balance = this.orderBalance(order);

    var btnClass = classNames("mr-2 fa", {
      "fa-exclamation-circle text-danger": balance > 0.01,
      "fa-check-circle text-success": balance <= 0.01,
    });
    return btnClass;
  };

  deliveryStatusClass = (order) => {
    const delivered = order.delivery && order.delivery.delivered_at;

    var btnClass = classNames("mr-1 fa fa-truck", {
      "text-danger": !delivered,
      "text-success": delivered,
    });
    return btnClass;
  };

  orderBalance = (order) => {
    const totalPayments = this.totalPayments(order.payments);
    const totalInvoiced = +order.total;
    const balance = totalInvoiced - totalPayments;
    return balance;
  };

  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  exportList = (data = []) => {
    const fmtAddress = (customer) => {
      return `${customer.street}, ${customer.city}, ${customer.state}, ${customer.zip}`;
    };

    const paymentsSum = (payments) => this.paymentsSum(payments);

    const joinItems = (items) => {
      return items.map((item) => {
        const slab = item.slab || {};
        return `${item.slab_num} x ${slab.sqft}`;
      });
    };

    const formatted = data.map((item) => {
      const _item = {
        ...item,
        customer: item.customer.company,
        address: fmtAddress(item.customer),
        totalPayments: 100,
        items: joinItems(item.items),
      };
      return _item;
    });
    this.setState({ csvData: formatted });
  };

  filter = (data) => {
    if (data) {
      //const minDate = this.getMinDate(this.state.months);
      const search = this.state.search.toLowerCase();
      const date = moment(this.state.date).format("YYYY-MM-DD");

      const filtered = data.filter((item) => {
        if (this.state.date) {
          return date === moment(item.created_at).format("YYYY-MM-DD");
        }

        if (this.state.selectedRep !== "-1") {
          if (this.state.selectedRep === "") {
            return !item.sales_rep || item.sales_rep.length === 0;
          }
          return this.state.selectedRep === item.sales_rep;
        }

        const customer = item.customer || {};
        const company = customer.company || "";

        if (this.state.search.length > 0) {
          return (
            item.id.toString().startsWith(search) ||
            customer.name.toLowerCase().includes(search) ||
            company.toLowerCase().includes(search) ||
            this.searchOrderItems(item, search)
          );
        }

        return true; //(Date.parse(item.created_at) > minDate);
      });
      return filtered;
    }
    return [];
  };

  searchOrderItems = (item, search) => {
    if (search.length < 6) return false;

    return item.items.some((item) => {
      return item.slab_num.startsWith(search);
    });
  };

  getMinDate = (months) => {
    if (months > 0) {
      let date = new Date();
      date.setMonth(date.getMonth() - months);
      return date.getTime();
    }
    return 0;
  };

  setReps = () => {
    const orders = this.props.orders;
    const len = orders.length;
    let reps = [];
    for (let i = 0; i < len; i++) {
      reps.push(orders[i].sales_rep);
    }

    const unique = [...new Set(reps)];
    this.setState({ reps: unique.sort() });
  };

  render() {
    const orders = this.filter(this.props.orders);
    return (
      <div className="page-div">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 mb-2">
              <input
                type="search"
                className="form-control"
                name="search"
                placeholder="Search customer/#"
                value={this.state.search}
                onChange={this.handleChange}
              />
            </div>
            {/* <div className="col-sm-3 mb-2">
                <select name='months' className="form-control" onChange={this.handleChange} >
                    <option value="1">1 Month</option>
                    <option value="3">3 Months</option>
                    <option value="6">6 Months</option>
                    <option value="0">All</option>
                </select>
            </div> */}
            <div className="col-sm-2 mb-2">
              <DatePicker
                className="form-control col-12"
                name="date"
                selected={this.state.date}
                onChange={this.handleDateChange}
                placeholderText="Date"
                isClearable={true}
              />
            </div>
            <div className="col-sm-2 mb-2">
              <select
                name="selectedRep"
                className="form-control"
                onChange={this.handleChange}
              >
                <option value="-1">All Reps</option>
                {this.state.reps.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-3">
              <label className="count-label">Count {orders.length}</label>
            </div>
            <div className="pl-0 pt-2 pr-4">
              <CSVLink
                data={this.state.csvData}
                headers={headers}
                onClick={() => this.exportList(orders)}
              >
                Export
              </CSVLink>
            </div>
            <div>
              <Link className="btn btn-outline-primary" to="orders/all">
                Summary
              </Link>
            </div>
          </div>
          <Spinner isVisible={this.state.loading} />
          {!this.state.order && (
            <table className="table table-responsive-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Date</th>
                  <th scope="col">Payments</th>
                  <th scope="col">P|D</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {orders.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">{item.id}</th>
                    <td>{item.customer && item.customer.company}</td>
                    <td>{formatMoney(item.total)}</td>
                    <td>
                      {moment(item.created_at).format("M/D/YYYY hh:mm a")}
                    </td>
                    <td>{formatMoney(this.totalPayments(item.payments))}</td>
                    <td>
                      <i
                        className={this.paidStatusClass(item)}
                        aria-hidden="true"
                      ></i>
                      <i
                        className={this.deliveryStatusClass(item)}
                        aria-hidden="true"
                      ></i>
                    </td>
                    <td>
                      <button
                        className="btn btn-outline-secondary mr-md-3 mb-2"
                        data-toggle="modal"
                        data-target="#invoiceDialog"
                        onClick={() => this.showInvoice(item.id)}
                      >
                        <i
                          className="fa fa-info-circle text-info"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <a
                        className="btn btn-outline-secondary mr-md-3 mb-2"
                        href={CONSTS.server + "vpdf/order/" + item.uid}
                      >
                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                      </a>
                      <button
                        className="btn btn-outline-danger mr-md-3 mb-2"
                        disabled={item.delivery}
                        onClick={() => this.deleteInvoice(item.id)}
                      >
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {/* <Invoice order={this.state.order} loading={this.state.loading} /> */}
        {/* {this.state.pdffile && <InvoicePDF pdf={this.state.pdffile} /> } */}
        {/* {this.state.order && <InvoiceView order={this.state.order} closeView={this.closeView} /> } */}
        <PaymentEditModal
          order={this.state.selectedOrder}
          calcBalance={this.orderBalance}
          addPayment={this.handleAddPayment}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.orders,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(Orders);
