import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import SlabList from "./SlabList";
import Signup from "./Signup";
import Callback from "./Callback/Callback";
import Login from "./Login";
import MailingListTb from "./MailingListTb";
import PriceEdit from "./PriceEdit";
import TestToken from "./TestToken";
import MainParallax from "./parallax";
import PriceList from "./PriceList";
import PricePrint from "./PricePrint";
import SlabsLoader from "./containers/SlabsLoader";
import Auth from "./Auth/Auth";
import GraniteList from "./containers/GraniteList";
import CartView from "./containers/CartView";
import CustomerList from "./containers/CustomerList";
import CheckoutAddress from "./containers/CheckoutAddress";
import OrderDetail from "./containers/OrderDetail";
import Invoice from "./containers/Invoice";
import Orders from "./containers/Orders";
import OrdersAll from "./containers/OrdersAll";
import Payments from "./containers/Payments";
import InvoiceView from "./containers/InvoiceView";
import Deliveries from "./containers/Deliveries";
import DeliveryView from "./containers/DeliveryView";
import DeliveryPDFView from "./containers/DeliveryPDFView";
import Contacts from "./containers/Contacts";
import Settings from "./containers/Settings";
import InactiveSlabs from "./containers/InactiveSlabs";
import SlabsReport from "./containers/SlabsReport";
import Inventory from "./containers/Inventory";
import InventoryGraph from "./containers/InventoryGraph";
import LastWeekSales from "./containers/Reports/LastWeekSales";
import SalesReport from "./containers/Reports/SalesReport";
import PowerBIReport from "./containers/Reports/PowerBI";
import PkListParser from "./containers/Tools/PkListParser";
import GranitesColor from "./containers/Tools/Granites/GranitesColor";
import OrderEdit from "./containers/Orders/OrderEdit";
import Terms from "./containers/Home/Terms";

const _auth = new Auth();
const handleAuthentication = (props, auth) => {
  if (/access_token|id_token|error/.test(props.location.hash)) {
    auth.handleAuthentication();
    console.log(auth.isAuthenticated());
  }
};

export default class MainRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: "",
    };
  }

  render() {
    const auth = this.props.auth;
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />
        <Route path="/signup" component={Signup} />
        <Route path="/login" component={Login} />
        <ProtectedRoute path="/settings" component={Settings} />
        <Route path="/cart" component={CartView} />
        <ProtectedRoute path="/checkout" exact component={OrderDetail} />
        <ProtectedRoute path="/checkout/addr" component={CheckoutAddress} />
        <Route
          path="/callback"
          render={(props) => {
            handleAuthentication(props, auth);
            return <Callback {...props} />;
          }}
        />
        <Route path="/slabs" exact component={SlabList} />
        <ProtectedRoute path="/slabs/loader" exact component={SlabsLoader} />
        <ProtectedRoute path="/prices/print" component={PricePrint} />
        <Route path="/prices/edit/:id" component={PriceEdit} />
        <ProtectedRoute path="/prices" component={PriceList} />
        <Route
          path="/granites"
          render={(props) => <GraniteList auth={auth} />}
        />
        <Route path="/mlist" component={MailingListTb} />
        <Route path="/mp" component={MainParallax} />
        <ProtectedRoute path="/test" component={TestToken} />
        <ProtectedRoute path="/customers" component={CustomerList} />
        <Route path="/invoice" component={Invoice} />
        <ProtectedRoute path="/orders" exact component={Orders} />
        <ProtectedRoute path="/orders/all" exact component={OrdersAll} />
        <ProtectedRoute path="/orders/edit/:id" exact component={OrderEdit} />
        <ProtectedRoute path="/orders/:id" component={InvoiceView} />
        <ProtectedRoute path="/tools/payments" component={Payments} />
        <ProtectedRoute path="/tools/deliveries" exact component={Deliveries} />
        <ProtectedRoute path="/delivery/pdf/:id" component={DeliveryPDFView} />
        <ProtectedRoute path="/deliveries/:id" component={DeliveryView} />
        <ProtectedRoute path="/tools/contacts" exact component={Contacts} />
        <ProtectedRoute
          path="/tools/inactive-slabs"
          exact
          component={InactiveSlabs}
        />
        <ProtectedRoute path="/tools/reports" exact component={SlabsReport} />
        <ProtectedRoute path="/tools/inventory" exact component={Inventory} />
        <ProtectedRoute
          path="/tools/inventory/map"
          exact
          component={InventoryGraph}
        />
        <ProtectedRoute
          path="/tools/pklist-parser"
          exact
          component={PkListParser}
        />
        <ProtectedRoute
          path="/tools/granites/color"
          exact
          component={GranitesColor}
        />
        <ProtectedRoute
          path="/reports/lastweek"
          exaxt
          component={LastWeekSales}
        />
        <ProtectedRoute path="/reports/sales" exaxt component={SalesReport} />
        <ProtectedRoute
          path="/reports/powerbi"
          exaxt
          component={PowerBIReport}
        />
        <Route path="/drag" component={Inventory} />
        <Route path="/terms" component={Terms} />
        <Route path="*" component={NoMatch} />
      </Switch>
    );
  }
}

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      _auth.isAuthenticated() ? (
        <Component {...props} auth={_auth} />
      ) : (
        <LoginRequired {...props} auth={_auth} />
      )
    }
  />
);

const LoginRequired = (props) => {
  localStorage.setItem("redirect", props.match.url);
  return (
    <div className="page-div">
      <div className="container">
        <div className="text-center">
          <h5 className="text-secondary">
            <i
              className="fa fa-exclamation-triangle btn-outline-warning mr-2"
              aria-hidden="true"
            ></i>
            Login Required
          </h5>
          <p>Please log in to see the requested page.</p>
          <button
            className="btn btn-outline-secondary"
            onClick={() => {
              props.auth.login();
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

const NoMatch = ({ location }) => (
  <div className="page-div">
    <div className="text-secondary">
      <h2>404</h2>
      <h3>Page not found</h3>
      <div className="text-center">
        <span>URL: </span>
        <quote>{window.location.href}</quote>
      </div>
    </div>
  </div>
);
