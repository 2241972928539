import React, { Component } from "react";
import { fetchOrders } from "./../services/apiService";
import { Spinner } from "../components/dialogs";
import moment from "moment";

export default class Deliveries extends Component {
  state = {
    orders: [],
    loading: false,
    orderGroups: [],
  };

  async componentDidMount() {
    this.showSpinner();

    const orders = await fetchOrders(null, "delivery");
    const orderGroups = this.groupByDate(orders);
    this.setState({ orders, orderGroups });

    this.hideSpinner();
  }

  groupByDate = function(xs, key = "scheduled_delivery") {
    return xs.reduce((rv, x) => {
      const group_key = moment(x[key]).format("YYYY-MM-DD");
      (rv[group_key] = rv[group_key] || []).push(x);
      return rv;
    }, {});
  };

  showSpinner = () => {
    this.setState({ loading: true });
  };

  hideSpinner = () => {
    this.setState({ loading: false });
  };

  handleItemClick = (id) => {
    this.props.history.push("/deliveries/" + id);
  };

  toScheduledTime = (date) => {
    if (!date) return "";
    return moment(date).format("MMM D, YYYY h:mm a");
  };

  render() {
    const groups = this.state.orderGroups || {};

    const display = Object.keys(groups).map((key) => {
      return (
        <TimeGroup
          key={key}
          gKey={key}
          group={groups[key]}
          open={this.handleItemClick}
        />
      );
    });

    return (
      <div className="page-div bg-light">
        <div className="container">
          <Spinner isVisible={this.state.loading} />
          <h3>Deliver</h3>
          <div>{display}</div>
        </div>
      </div>
    );
  }
}

const GroupItem = ({ item, open }) => {
  const shipping = item.shipping || item.customer;
  return (
    <div
      className="bg-white d-flex mb-1 border border-gray"
      onClick={() => open(item.id)}
    >
      <div className="schedule-time">
        <div>{scheduleTime(item.scheduled_delivery)}</div>
      </div>
      <div className="schedule-detail">
        <div>{shipping.name} </div>
        <div>{shipping.street + ", " + shipping.city}</div>
        <div>
          {!item.scheduled_delivery && (
            <button className="btn btn-outline-info btn-sm">
              <i className="fa fa-calendar-plus-o"></i>
            </button>
          )}
        </div>
        <div>
          <span>Order:</span> {item.id}
        </div>
        <div>
          <span>Order Date:</span> {moment(item.created_at).format("M/D/YYYY")}
        </div>
        <div>
          <span>Slabs:</span> {item.items.length}
        </div>
      </div>
    </div>
  );
};

const TimeGroup = ({ group, gKey, open }) => {
  return (
    <div className="bg-light border-top border-gray schedule-panel" key={gKey}>
      <div className="pt-1">{groupHeader(gKey)}</div>
      <div>
        {group.map((item) => (
          <GroupItem key={item.id} item={item} open={open} />
        ))}
      </div>
    </div>
  );
};

const scheduleTime = (date) => {
  if (!Date.parse(date)) return "";
  return moment(date).format("h:mm a");
};

const groupHeader = (date) => {
  if (!Date.parse(date)) return "Not Scheduled";
  return moment(date).format("ddd, MMM D");
};
