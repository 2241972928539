import {group} from "d3";
import { formatMoney } from '../../services/utils';


const WEEKS = 52;

export const getYears = (data) => {
  let years = [];
  for (const item of data) {
    years.push(item[0]);
  }
  years = years.sort((a, b) => { return +b - +a });

  //only use last 2 years
  if (years.length < 3) return years;
  return years.slice(0, 2);
}

export const getFlatSalesMatrix = (dataMatrix, years, measure) => {
  //const dataMatrix = getSalesMatrix(data, years, measure);
  //console.log('salesmatrix', dataMatrix);
  let flatSalesMatrix = Array(years.length * measure);
 
  for (let i = 0; i < flatSalesMatrix.length; i++) {
    flatSalesMatrix[i] = dataMatrix[years[Math.floor(i / measure)]][i % measure];
  }

  return flatSalesMatrix;
}

export const getSalesMatrix = (data, years, measure) => {
  let salesMatrix = Array(years.length);

  for (let i = 0; i < salesMatrix.length; i++) {
    salesMatrix[years[i]] = Array(measure);
    for (var j = 0; j < measure; j++) {
      salesMatrix[years[i]][j] = getSalesCount(data, years[i], j);
    }
  }

  return salesMatrix;
}

export const getSalesCount = (data, year, month) => {
  const tmp = data.get(year);
  const key = pad2(month + 1);

  if (tmp) {
    const entries = tmp.get(key);
    if (entries) {
      let totals = 0
      for(let order of entries) {
        totals += Number(order.total);
      }
      return {
        value: totals,
        year: year,
        key: key
      }
    }
  }

  return {
    value: 0,
    year: year,
    key: key
  }
}

export const pad2 = (number) => {
  return (number < 10 ? '0' : '') + number;
}

export const getColorArr = () => {
  //old colors['#FFE4D8','#F8AF90','#F76A49','#DC2D27','#A80F12'];
  return [
    "#FFE400",
    "#FFBA08",
    "#FAA307",
    "#F48C06",
    "#E85D04",
    "#E85D04",
    "#D00000",
    "#9D0208",
  ];
}

export const getTooltip = (d, measure) => {
  const total = formatMoney(d.value);
  const ms = measure === WEEKS? 'week: ': 'month: ';
  const mvalue = d.key;
  return `total: ${total}\n${ms}: ${mvalue}\nyear: ${d.year}`;
}; 

export const getDateOfISOWeek = (week, year) => {
  const simple = new Date(+year, 0, 1 + (Number(week) - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}


export const groupGranites = (data) => { 
  let tmp = [];

  let slabs = data.values.reduce((list, item) => list.concat(item.items), []);
  // console.log('slabs ', slabs);

  const res = group(slabs, d => d.material);
  for(let [key, value] of res) {
    tmp.push({
      name: key,
      count: value.length
    })
  }

  return {
    count: slabs.length,
    slabs: tmp.sort((a, b) => b.count-a.count)
  }
} 

export const groupCustomers = (data) => { 
  let tmp = [];
  const res = group(data.values, d => d.customer_name);
  console.log("groupCustomers", res);
  for(let [key, value] of res) {
    tmp.push({
      name: key,
      count: value.length,
      amount: value.reduce((acc, curr) => acc + Number(curr.total), 0)
    })
  }

  return tmp.sort((a, b) => b.amount-a.amount);
};

