//@ts-check
/*--eslint semi: "error" */
import React, { Component, createRef } from "react";
import * as d3 from "d3";
import moment from "moment";
import isEmpty from "lodash.isempty";
import { fetchOrdersReport } from "../../services/apiService";
import { Spinner } from "../../components/dialogs";
import { Link } from "react-router-dom";
import { formatMoney } from "../../services/utils";
import {
  getYears,
  getFlatSalesMatrix,
  getSalesMatrix,
  getColorArr,
  getTooltip,
  getDateOfISOWeek,
  groupGranites,
  groupCustomers,
} from "./reportUtils";

const MONTHS = 12;
const WEEKS = 52;
const WIDTH1 = 300;
const WIDTH2 = 900;
const HEIGHT = 116;
class SalesReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      measure: WEEKS,
      orders: [],
      selected: {},
    };
    this.groupedData = {};
    this.svgRef = createRef();
    this.svgWidth = 0;
  }

  async componentDidMount() {
    this.showSpinner(true);

    const data = await fetchOrdersReport();
    this.setState({ orders: data });
    this.showSpinner(false);
    this.setInitialSelection();
  }

  /**
   * @param {boolean} option
   */
  showSpinner = (option) => {
    this.setState({ loading: option });
  };

  handleChange = (event, name) => {
    let value = name === "months" ? MONTHS : WEEKS;
    if (event) value = event.target.name === "months" ? MONTHS : WEEKS;
    if (this.state.measure === value) return;
    this.setState({ measure: value });
  };

  formatDate = (date) => {
    return moment(date).format("dddd, MMMM DD, YYYY");
  };

  /**
   * @return {string}
   */
  getTotalAmount = (orders) => {
    let total = 0;
    for (const order of orders) {
      if (order.total) total += Number(order.total);
    }
    return formatMoney(total);
  };

  goToOrder = (id) => {
    this.props.history.push(`/orders/${id}`);
  };

  /**
   * @param {any} orders
   */
  getCountByMaterial(orders) {
    const groups = orders.reduce((rv, order) => {
      order.items.map((item) => {
        const slab = item.slab || {};
        const group_key = slab.slug || "";
        (rv[group_key] = rv[group_key] || []).push(slab.material);
        return 1;
      });
      return rv;
    }, {});

    let groupCount = Object.keys(groups).map((key) => {
      const group = groups[key];
      return {
        count: group.length,
        name: group[0],
      };
    });
    groupCount.sort((a, b) => {
      return b.count - a.count;
    });
    return groupCount;
  }

  totalCount = (groups) => {
    return groups.reduce(
      (accumulator, currentItem) => accumulator + currentItem.count,
      0
    );
  };

  getgroupedData = (data, measure) => {
    //@ts-ignore
    if (measure === MONTHS)
      return d3.group(
        data,
        (d) => d.year,
        (d) => d.month
      );
    //@ts-ignore
    return d3.group(
      data,
      (d) => d.year,
      (d) => d.week
    );
  };

  handleSelection = (data) => {
    //const orders = this.salesMatrix.get(data.year).get(data.key);
    if (Number(data.value) === 0) return; //
    const selected = this.groupedData.get(data.year).get(data.key);
    const _data = {
      ...data,
      values: selected,
      measure: this.state.measure,
    };
    //console.log("handleSelection ", _data, data.year, Number(data.value));
    this.setState({ selected: _data });
  };

  setInitialSelection = () => {
    const data = this.state.orders;
    const grouped = d3.group(data, (d) => d.year);
    const year = moment().format("YYYY");

    const selected = grouped.get(year) || [];
    const value = selected.reduce((acc, curr) => acc + Number(curr.total), 0);
    const result = {
      key: "",
      value: value,
      year: year,
      values: selected,
      measure: this.state.measure,
    };

    this.setState({ selected: result });
  };

  heatmapChart = async () => {
    const data = this.state.orders;
    const measure = this.state.measure;
    this.svgWidth = measure === MONTHS ? WIDTH1 : WIDTH2;
    const w = this.svgWidth;
    const h = HEIGHT;
    const margin = { left: 30, right: 0, bottom: 30, top: 30 };
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    const svg = d3
      .select("svg")
      .attr("width", w)
      .attr("height", h);

    svg.selectAll("*").remove();

    this.groupedData = this.getgroupedData(data, measure);
    const years = getYears(this.groupedData);

    //console.log(groupedData);
    const valueArr = d3.range(0, measure);
    const yDomain = d3.range(0, years.length).map((d) => d + "");
    // @ts-ignore
    const x = d3
      .scaleBand()
      .domain(valueArr)
      .range([margin.left, margin.left + width]);
    const y = d3
      .scaleOrdinal()
      .domain(yDomain)
      .range([margin.top, height]);
    const cellSize = (x("1") - x("0")) * 0.8;

    const salesMatrix = getSalesMatrix(this.groupedData, years, measure);
    const flatSalesMatrix = getFlatSalesMatrix(salesMatrix, years, measure);
    const maxValue = d3.max(flatSalesMatrix.map((d) => d.value));

    var color = d3
      .scaleQuantize()
      .domain([1, maxValue])
      //@ts-ignore
      .range(getColorArr());

    const xAxis = d3.axisTop(x);
    if (measure === MONTHS)
      xAxis.tickFormat((d, i) =>
        +d % 2 !== 0
          ? ""
          : moment()
              .month(d)
              .format("MMM")
      );
    else xAxis.tickFormat((d, i) => (Number(d) % 2 !== 0 ? "" : d + 1));

    const yAxis = d3.axisLeft(y).tickFormat((d, i) => years[d]);

    svg
      .append("g")
      .call(xAxis)
      .attr("class", "hidden")
      .attr("transform", "translate(-2, 30)");

    svg
      .append("g")
      .call(yAxis)
      .attr("class", "hidden")
      .attr("transform", "translate(32, 9)");

    // console.log("flat matrix", flatSalesMatrix);
    // console.log("max matrix", maxValue);

    svg
      .selectAll("rect")
      .data(flatSalesMatrix)
      .enter()
      .append("rect")
      .attr("x", (d, i) => {
        return x((i % measure).toString());
      })
      .attr("y", (d, i) => {
        return y(Math.floor(i / measure).toString());
      })
      .attr("width", cellSize)
      .attr("height", cellSize)
      .attr("fill", (d, i) => {
        return d.value > 0 ? color(d.value) : "#EBEDF0";
      })
      .attr("cursor", "pointer")
      .on("click", (e, d) => this.handleSelection(d))
      .append("title")
      .text((d, i) => getTooltip(d, measure));

    this.addLegend(svg);
  };

  addLegend = (svg) => {
    const size = 13;
    const wordwidth = 28;
    const bottom = HEIGHT - size - 5;
    const barwidth = getColorArr().length * (size + 1);

    const panel = svg.append("g");

    const legend = panel
      .append("g")
      .attr("class", "legend")
      .attr("transform", "translate(+" + wordwidth + ", 0)");

    legend
      .selectAll("rect")
      .data(getColorArr())
      .enter()
      .append("rect")
      .attr("x", (d, i) => (size + 1) * i)
      .attr("width", size)
      .attr("height", size)
      .attr("fill", (d, i) => d);

    panel
      .append("text")
      .text("Less")
      .attr("class", "legend-text")
      .attr("transform", "translate(0, 12)");

    panel
      .append("text")
      .text("More")
      .attr("class", "legend-text")
      .attr("transform", `translate(${barwidth + wordwidth + 4}, 12)`);

    const xpos = this.svgWidth - 10 - barwidth - wordwidth * 2;
    panel.attr("transform", `translate(${xpos}, ${bottom})`);
  };

  render() {
    this.heatmapChart();

    return (
      <div className="page-s">
        <Spinner isVisible={this.state.loading} />
        <div className="row">
          <div className="bg-grey col-sm-2" style={{ height: "100vh" }}>
            <div className="list-group list-group-flush">
              <Link
                className="list-group-item list-group-item-action"
                to="/tools/reports"
              >
                Reports
              </Link>
              {/* <Link className='list-group-item list-group-item-action' to='/reports/lastweek' >Last Week</Link> */}
              <Link
                className="list-group-item list-group-item-action active"
                to="/reports/sales"
              >
                Sales
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                to="/reports/powerbi"
              >
                Power BI
              </Link>
            </div>
          </div>
          <div className="col-md-8">
            <div className="mb-3">
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <label
                  className="btn btn-secondary active"
                  onClick={() => this.handleChange(null, "weeks")}
                >
                  <input type="radio" name="weeks" /> Week
                </label>
                <label
                  className="btn btn-secondary"
                  onClick={() => this.handleChange(null, "months")}
                >
                  <input type="radio" name="months" /> Month
                </label>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                background: "transparent",
              }}
            >
              <svg ref={this.svgRef} style={{ background: "" }}></svg>
              <svg
                id="svg-legend"
                style={{ background: "", height: 20, width: "100%" }}
              ></svg>
            </div>
            <OrdersSummary data={this.state.selected} click={this.goToOrder} />
          </div>
        </div>
      </div>
    );
  }
}

const OrdersSummary = (props) => {
  const { data } = props;
  if (isEmpty(data)) return <br />;

  let res = groupGranites(data);
  let qty = res.count;
  let startDay = null;

  if (props.data.measure === WEEKS && !isEmpty(data.key)) {
    startDay = getDateOfISOWeek(data.key, data.year);
    startDay = moment(startDay).format("ddd,  MMM D, YYYY");
  }

  return (
    <div className="p-3">
      <h4>Details</h4>
      <div className="row mt-5">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr className="bg-light border-top border-secondary">
              <td className="pl-2">Time:</td>
              <td className="font-weight-bold pl-1 p-2">
                {isEmpty(data.key)
                  ? "Year"
                  : data.measure === MONTHS
                  ? "Month: "
                  : "Week: "}{" "}
                {data.key}-{data.year}
              </td>
            </tr>
            {startDay != null && (
              <tr className="bg-light border-top border-secondary">
                <td className="pl-2">Starting on:</td>
                <td className="font-weight-bold pl-1 p-2">{startDay}</td>
              </tr>
            )}
            <tr className="bg-light border-top border-secondary">
              <td className="pl-2">Total:</td>
              <td className="font-weight-bold p-2 pull-right">
                {formatMoney(data.value)}
              </td>
            </tr>
            <tr className="bg-light border-top border-secondary">
              <td className="pl-2">Slabs Qty: </td>
              <td className="font-weight-bold p-2 pull-right">{qty}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <SalesDetails data={data} click={props.click} />
      <GraniteCountTable slabs={res.slabs} />
      <CustomerCountTable orders={data} />
    </div>
  );
};

const SalesDetails = (props) => {
  const data = props.data;
  if (isEmpty(data)) return null;

  // console.log("SalesDetails ", data);
  const orders = data.values;
  return (
    <div className="row">
      <h5 className="mt-4">Orders</h5>
      <table className="table tb-report">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((item, i) => (
            <tr key={i} onClick={() => props.click(item.id)}>
              <td> {item.id} </td>
              <td>
                {" "}
                {moment(item.date_sold.date).format("M/D/YYYY, h:mm a")}{" "}
              </td>
              <td> {formatMoney(item.total)} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const GraniteCountTable = (props) => {
  const slabs = props.slabs;
  return (
    <div className="row">
      <h5 className="mt-4">Granites</h5>
      <table className="table tb-report">
        <thead>
          <tr>
            <th>Name</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {slabs.map((item, i) => (
            <tr key={i}>
              <td> {item.name} </td>
              <td> {item.count} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CustomerCountTable = (props) => {
  if (isEmpty(props.orders)) return <br />;
  const orders = groupCustomers(props.orders);

  return (
    <div className="row">
      <h5 className="mt-4">Customers</h5>
      <table className="table tb-report">
        <thead>
          <tr>
            <th>Name</th>
            <th>Orders</th>
            <th className="last">Amount</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((item, i) => (
            <tr key={i}>
              <td> {item.name} </td>
              <td> {item.count} </td>
              <td className="last"> {formatMoney(item.amount)} </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesReport;
