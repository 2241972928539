// @ts-check

import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { createPrice, updatePrice, fetchPrices } from "./services/apiService";
import { priceInitialState, nameToSlug } from "./services/utils";
import {
  createPriceAction,
  updatePriceAction,
  pricesFetchedAction,
} from "./actions";
import { showAlert, Spinner } from "./components/dialogs";

import "react-datepicker/dist/react-datepicker.css";

export class PriceEdit extends Component {
  constructor(props) {
    super(props);
    const price = this.props.price || priceInitialState;

    this.state = {
      ...price,
      loading: true,
    };
  }

  async componentDidMount() {
    await this.fetchPrices();
    this.setState({ loading: false });
  }

  fetchPrices = async () => {
    if (!this.props.prices) {
      const data = await fetchPrices();
      if (data) {
        this.props.dispatch(pricesFetchedAction(data));
        this.setState(this.props.price);
      }
    }
  };

  handleSubmit = async (event) => {
    const response = await updatePrice(this.state);

    if (response) {
      this.props.dispatch(updatePriceAction(response));
      showAlert("Price updated", "success");
      this.props.history.push("/prices");
    } else {
      showAlert("Error trying to update item", "error");
    }
  };

  handleCreate = async (event) => {
    const response = await createPrice(this.state);

    if (response) {
      this.props.dispatch(createPriceAction(response));
      showAlert("Price created", "success");
      this.props.history.push("/prices");
    } else {
      showAlert("Error trying to create item", "error");
    }
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (date) => {
    this.setState({ expiration: date });
  };

  handleInputBlur = (e) => {
    if (e.target.name === "material") {
      let material = this.state.material.toCapitalizedCase();
      let slug = nameToSlug(material);
      this.setState({ material, slug });
    }
  };

  /**
   * @param {string | number | Date} date
   */
  parseDate = (date) => {
    if (date) {
      const _date = new Date(date);
      // @ts-ignore
      if (isNaN(_date)) return null;
      return _date;
    }
    return null;
  };

  render() {
    return (
      <div className="page-div">
        <Spinner isVisible={this.state.loading} />
        <div className="row">
          <div className="container">
            <h2>Granite Price</h2>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="material">Material</label>
                  <input
                    type="text"
                    className="form-control"
                    name="material"
                    value={this.state.material}
                    onChange={this.handleChange}
                    onBlur={this.handleInputBlur}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="unit_price">Unit Price</label>
                  <input
                    type="text"
                    className="form-control"
                    name="unit_price"
                    value={this.state.unit_price}
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="bundle_price">Bundle Price</label>
                  <input
                    type="text"
                    className="form-control"
                    name="bundle_price"
                    value={this.state.bundle_price}
                    onChange={this.handleChange}
                    placeholder=""
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 d-block">
                  <label htmlFor="expiration">Price Expiration</label>
                  <div>
                    <DatePicker
                      className="form-control"
                      name="expiration"
                      selected={this.parseDate(this.state.expiration)}
                      onChange={this.handleDateChange}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="filename">File</label>
                  <input
                    type="text"
                    className="form-control"
                    name="filename"
                    value={this.state.filename}
                    onChange={this.handleChange}
                    placeholder="filename.jpg"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="filename">Slug</label>
                  <input
                    type="text"
                    className="form-control"
                    name="slug"
                    value={this.state.slug}
                    onChange={this.handleChange}
                    placeholder="granite-name"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="group">Group #</label>
                  <input
                    type="text"
                    className="form-control"
                    name="group_num"
                    value={this.state.group_num}
                    onChange={this.handleChange}
                    placeholder="(number)"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label htmlFor="rank">Rank</label>
                  <input
                    type="text"
                    className="form-control"
                    name="rank"
                    value={this.state.rank}
                    onChange={this.handleChange}
                    placeholder="(number)"
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="limited"
                    checked={this.state.limited}
                    onChange={this.handleChange}
                  />
                  <label className="form-check-label" htmlFor="limited">
                    Limited Time
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="active"
                    checked={this.state.active}
                    onChange={this.handleChange}
                  />
                  <label className="form-check-label" htmlFor="active">
                    Mailing List
                  </label>
                </div>
              </div>
              {// eslint-disable-next-line
              this.props.match.params.id == this.state.id && (
                <div>
                  {this.state.id > 0 ? (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleCreate.bind(this)}
                    >
                      Create
                    </button>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

/*eslint-disable */
const mapStateToProps = (state, props) => {
  if (!state.prices) return null;
  return {
    price: state.prices.find((price) => price.id == props.match.params.id),
    prices: state.prices,
  };
};
/*eslint-enable */

export default connect(mapStateToProps)(PriceEdit);
