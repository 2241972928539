////@ts-check
import { CONSTS } from "./../constants";
import moment from "moment";

//full precision 3.2808399
const mtFeet = 3.2808;
export const formatMoney = function(num, places, symbol, thousand, decimal) {
  places = !isNaN((places = Math.abs(places))) ? places : 2;
  symbol = symbol !== undefined ? symbol : "$";
  thousand = thousand || ",";
  decimal = decimal || ".";
  var number = num,
    negative = number < 0 ? "-" : "",
    i = parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + "",
    // eslint-disable-next-line
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    symbol +
    negative +
    (j ? i.substr(0, j) + thousand : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
    (places
      ? decimal +
        Math.abs(number - i)
          .toFixed(places)
          .slice(2)
      : "")
  );
};

export const isEmptyObj = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
export const toBool = (val) => {
  return +val === 1 || val === true;
};

export const convertMtToFt = (value) => {
  return (value * mtFeet).toFixed(2);
};

/**
 * @returns {number}
 */
export const toFixed = (value) => {
  const num = Number(value).toFixed(2);
  return Number(num);
};

export const convertMtToSqFt = (value1, value2) => {
  return convertMtToFt(value1) * convertMtToFt(value2);
};

export const calcPrice = (slab) => {
  if (!slab.length || slab.length <= 0) return 0;
  if (!slab.height) return 0;
  const price = unitPrice(slab);
  if (!price) return 0;
  const size = Number(slab.sqft);
  return formatMoney(size * price, 2);
};

export const unitPrice = (slab) => {
  if (slab.unit_price) return +slab.unit_price;
  if (slab.price) return +slab.price.unit_price;
  return null;
};

export const bundlePrice = (slab) => {
  if (slab.bundle_price) return +slab.bundle_price;
  if (slab.price) return +slab.price.bundle_price;
  return null;
};

export const calcPrice2 = (slab) => {
  if (!slab.length || slab.length <= 0) return 0;
  if (!slab.height) return 0;
  const price = slab.isbundle === true ? +slab.bundle_price : +slab.unit_price;
  const p = price * slab.sqft;
  const total = +Number(p).toFixed(2);
  return total;
};

export function fixImageNotFound(e, img) {
  e.target.onerror = null;
  e.target.alt = "";
  e.target.src = img;
}

export function imageurl(img) {
  return CONSTS.imgBaseUrl + img;
}

export const dateToString = (date) => {
  return date ? moment(date).format("YYYY-MM-DD") : "";
};

export const stringToDate = (str) => {
  return str ? new Date(str + " 00:00:00") : null;
};

export const invertDirection = (value) => {
  if (value === "asc") return "desc";
  return "asc";
};

export const nameToSlug = (name) => {
  const _name = name.trim().toLowerCase();
  return _name.replace(/\s+/g, "-");
};

export const capitalize = (s) => {
  const str = s.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const DefineJSExtensions = () => {
  // String.prototype.toCapitalizedCase = function(){
  //   return this.replace(/\w\S*/g, function(txt){
  //     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //   });
  // }

  Object.assign(Array.prototype, {
    groupBy(prop) {
      return this.reduce(function(groups, item) {
        const val = item[prop];
        groups[val] = groups[val] || [];
        groups[val].push(item);
        return groups;
      }, {});
    },
  });

  Object.assign(String.prototype, {
    toCapitalizedCase() {
      return this.toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(" ");
    },
  });
};

export const priceInitialState = {
  id: 0,
  material: "",
  limited: false,
  rank: 1,
  filename: "",
  expiration: "",
  slab_num: null,
  description: null,
  unit_price: 0,
  bundle_price: 0,
  sales_price: 0,
  group_num: "1",
  active: true,
};

export const slabInitialState = {
  id: 0,
  material: "",
  slab_num: "",
  bundle_num: "",
  description: "",
  section: "",
  rack: "",
  damages: "",
  reserved: "",
  filename: "",
  length: "",
  height: "",
  sqft: "",
  weight: "",
  unit_price: "",
  bundle_price: "",
  sales_price: "",
  purchased_price: "",
  vendor: "",
  origin: "Brazil",
  invoice: "333333/33",
  invoice_date: "",
  received_at: "",
  sold_at: "",
  delivered_at: "",
  created_at: null,
  updated_at: null,
  deleted_at: null,
};

export const slabPriceInitialState = {
  id: 0,
  order: "",
  product: "",
  material: "Jacaranda",
  slab_num: "012",
  bundle_num: "3",
  description: null,
  damages: null,
  filename: "JAGUAR-3.00 CM-011889_54550.jpg",
  height: "40.00",
  length: null,
  thick: null,
  weight: null,
  unit_price: "",
  bundle_price: null,
  sales_price: null,
  purchased_price: null,
  vendor: "Gramazini",
  origin: "Brazil",
  invoice: null,
  invoice_date: null,
  shipping_date: null,
  received_at: null,
  sold_at: null,
  delivered_at: null,
  created_at: null,
  updated_at: null,
  deleted_at: null,
  price: {
    id: 19,
    material: "Jacaranda",
    slab_num: null,
    description: null,
    unit_price: null,
    bundle_price: null,
    sales_price: null,
    limited: 0,
    rank: 31,
    filename: "",
    expiration: null,
    group_num: "6",
    active: 1,
    created_at: "2019-01-10 14:33:46",
    updated_at: "2019-01-10 14:33:46",
    deleted_at: null,
  },
};

export const graniteInitialState = {
  id: 0,
  name: "",
  description: "",
  code: "",
  price: "",
  filename: "",
  created_at: null,
  updated_at: null,
  deleted_at: null,
};

export const order = {
  id: "",
  date: "",
  tax: 0,
  tax_rate: 0,
  total: 0,
  other_fees: 0,
  customer: {
    id: "",
    name: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
  },
  items: [
    {
      index: 0,
      slab_num: "",
      bundle_num: "",
      material: "",
      height: 0,
      length: 0,
      sqft: 0,
      defected: false,
      unit_price: 0.0,
      total: 0.0,
    },
  ],
};

const shippingInitialState = {
  name: "",
  phone: "",
  street: "",
  city: "",
  state: "",
  zip: "",
};

export const customerInitialState = {
  id: 0,
  name: "",
  company: "",
  email: "",
  phone: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  tax_exempted: 0,
  shipping: shippingInitialState,
};
