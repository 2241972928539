// import { priceInitialState } from "../services/utils";
import { combineReducers } from "redux";
import slabs from "./slabs";
import orders from "./orders";
import { cart, settings, profile, weekSales } from "./misc";
import granites from "./granites";
import prices from "./prices";
import customers from "./customers";
import ordersAll from "./ordersAll";

export default combineReducers({
  slabs,
  orders,
  cart,
  settings,
  granites,
  prices,
  customers,
  profile,
  weekSales,
  ordersAll
});
