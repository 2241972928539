import React from 'react';
import {RectShape} from 'react-placeholder/lib/placeholders';

import "react-placeholder/lib/reactPlaceholder.css";



export const PriceItemPlaceholder = (
	<div style={{marginTop:60}}>
    {Array(10).fill(1).map((a, x) => 
      <div key={x} className='loading-img' style={{display: 'flex', marginBottom:'10px', marginLeft:20, marginRight:15 }}>
        <RectShape color='#cacaca' style={{width: 170, height: 150}}/>
      
        <div style={{backgroundColor: '#F8F8F8', width:'100%', marginLeft:-10, paddingLeft:10}}>
        <RectShape color='#cacaca' style={{width: 300, height: 25, marginTop:20}}/>
        <RectShape color='#cacaca' style={{width: 230, height: 20, marginTop:20}}/>
        <RectShape color='#cacaca' style={{width: 250, height: 20, marginTop:20}}/>
        </div>
      </div>
    )}
	</div>
);

export const SimplePlaceHolder = (
	<div style={{marginTop:60}}>
	{Array(10).fill(1).map((a, i) => 
		<div className='loading-img' key={i} style={{ backgroundColor: '#F8F8F8', marginBottom:'10px', marginLeft:20, marginRight:15}} >
		  <div style={{height: '150px', width: '150px', backgroundColor: '#cacaca'}}></div>
		</div>
	)}
   </div>
);