import React, { Component } from "react";
import {
  requestPermissionForNotifications,
  getFCMToken,
} from "../services/PushNotification";
import { assignFCMGroup } from "../services/apiService";

export default class Settings extends Component {
  state = {
    token: null,
  };

  async componentDidMount() {
    const token = await getFCMToken();
    this.setState({ token });
  }

  requestPermission = async () => {
    const token = await requestPermissionForNotifications();
    this.setState({ token });
    if (token) await assignFCMGroup(token);
  };

  render() {
    return (
      <div className="page-s bg-light">
        <div className="container">
          <div className="mt-4 mb-2">Notifications</div>
          <div className="settings-box">
            <button
              className="btn btn-outline-secondary btn-sm"
              disabled={this.state.token}
              onClick={this.requestPermission}
            >
              Activate
            </button>
            <small className="form-text text-muted">{this.state.token}</small>
          </div>
        </div>
      </div>
    );
  }
}
