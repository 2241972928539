import React, { Component } from 'react'


export default class PriceContactDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            phone: "",
            email: "",
            changed: false
        };
    }

    handleChange = (event) => {
        const target = event.target; 
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            changed: true
        });
    }

    componentWillReceiveProps(nextProps) {
        const {name, phone, email} = nextProps.contact;
        this.setState({name, phone, email});
    }

    handleSave = () => {
        this.props.updateContact(this.state);
    }

    render() {
        const {name, phone, email} = this.state;
        return (
            <div ref={(e) => this.modal = e } className="modal fade" id="editorDialog" tabIndex="-1" role="dialog" aria-labelledby="editorDialogLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="editorDialogLabel">Edit</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                <div className="modal-body">

                    
                    <form className="form-inline">
                        <div className="form-row">
                            <div className="form-group ">
                                <label  htmlFor="name">Name</label>
                                <input type="text" 
                                className="form-control mx-sm-3" 
                                name="name" 
                                value={name} 
                                onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="form-group">
                                <label  htmlFor="email">Email</label>
                                <input type="text" 
                                className="form-control mx-sm-3" 
                                name="email" 
                                value={email} 
                                onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-row mt-2">
                            <div className="form-group ">
                                <label  htmlFor="phone">Phone</label>
                                <input type="tel" 
                                className="form-control mx-sm-3" 
                                name="phone" 
                                value={phone} 
                                onChange={this.handleChange} />
                            </div>
                        </div>
                    </form>
                </div>{/* body */}
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.handleClose}>
                        Close
                    </button>
                    <button type="button" className="btn btn-primary" data-dismiss="modal" disabled={!this.state.changed} onClick={ this.handleSave }>
                        Save
                    </button>
                </div>

                </div>
                </div>
            </div>
        )
    }
}




