import firebase from "firebase/app";
import "@firebase/messaging";
// import { assignFCMGroup } from './apiService';

export const initializeFirebase = (callback) => {
  firebase.initializeApp({
    messagingSenderId: "704643788409",
  });

  const messaging = firebase.messaging();
  messaging.onMessage((payload) => callback(payload));

  messaging.onTokenRefresh(function() {
    messaging
      .getToken()
      .then(function(refreshedToken) {
        console.log("Token refreshed. ", refreshedToken);
      })
      .catch(function(err) {
        console.log("Unable to retrieve refreshed token ", err);
      });
  });
};

export const getFCMToken = async () => {
  const messaging = firebase.messaging();
  const token = await messaging.getToken();
  return token;
};

export const requestPermissionForNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log("user token: ", token);

    return token;
  } catch (error) {
    console.error("permission: ", error);
  }
};
