import React, { Component } from "react";
import { connect } from "react-redux";
import Sortable from "react-sortablejs/lib/Sortable";

import { fixImageNotFound } from "../../../services/utils";
import { CONSTS, imageNoFoundNameW } from "./../../../constants";
import { fetchGranites, updateGranite } from "../../../services/apiService";
import { fetchedGranitesAction } from "./../../../actions";

class GranitesColor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      granites: [],
    };
  }

  async componentDidMount() {
    if (!this.props.granites) {
      const data = await fetchGranites();
      this.props.dispatch(fetchedGranitesAction(data));
      this.setState({ ready: true, loading: false, granites: data });
    } else {
      this.setState({
        ready: true,
        loading: false,
        granites: this.props.granites,
      });
    }
  }

  sortGranites = (granites) => {
    if (!granites) return [];

    const sorted = granites.sort((a, b) => {
      if (a.color_index && b.color_index) return a.color_index - b.color_index;
      return a.img_lightness - b.img_lightness;
    });

    return sorted;
  };

  onSortableChange = async (orderedIds) => {
    console.log("sortable ", orderedIds);
    let data = orderedIds.map((id, index) => {
      const granite = this.state.granites.find(
        (gr) => Number(gr.id) === Number(id)
      );
      return {
        ...granite,
        color_index: index + 1,
      };
    });

    this.setState({ granites: data });
    data = await updateGranite(data);
  };

  render() {
    const granites = this.sortGranites(this.state.granites);
    return (
      <div className="page-div">
        <div className="container text-black-50">
          Drag images to sort Granites darker to lighter
        </div>
        <div>
          <div>
            <div className="granite-view">
              <Sortable
                className=""
                // Sortable options (https://github.com/RubaXa/Sortable#options)
                options={{ animation: 500, dragClass: "sort-ghost-look" }}
                ref={(c) => {
                  if (c) {
                    this.sortable = c.sortable;
                  }
                }}
                onChange={(orderedIds) => this.onSortableChange(orderedIds)}
              >
                {granites.map((granite, index) => (
                  <div
                    key={granite.id}
                    className="granite-vcard"
                    data-id={granite.id}
                  >
                    <img
                      className="granite-card"
                      src={CONSTS.imgBaseUrl + "thumbnails/" + granite.filename}
                      alt=""
                      onError={(e) => {
                        fixImageNotFound(e, imageNoFoundNameW);
                      }}
                    />
                    <div>{granite.name}</div>
                  </div>
                ))}
              </Sortable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  granites: state.granites,
  hasGranites: state.hasGranites,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(GranitesColor);
