import React from 'react'

const Footer = () => {
  return (
    <div>
        <footer>
            <div className="container">
            <div className="row">
                <div className="col-sm-6">
                &copy; GRANITE WORLD 2018 - All Rights Reserved  
                </div>
                <div className="col-sm-6">
                <a href="https://facebook.com/Granite-World-382471665615080" target="_blank" rel="noopener noreferrer">
                    <img className="facebook-logo"  src="images/facebook_white.svg" alt="facebook-logo" />
                </a>
                <a href="https://www.instagram.com/graniteworldofficial/" target="_blank" rel="noopener noreferrer" >
                    <img className="instagram-logo" src="images/instagram_icon_white.svg" alt="instagram-logo" />
                </a>
                </div>
                <div className="granites_list" >
                </div>
            </div>
            </div>
        </footer>
    </div>
  )
}

export default Footer

