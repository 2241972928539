//@ts-check

export default function prices (state = null, action) {
    const _state = state || [];
    switch (action.type) {
        case 'ADD_PRICE':
            const list = _state.concat(action.price);
            //const list = [ ...state.prices, action.price ];
            return sortPrices(list);
        case 'UPDATE_PRICE': 
            const prices = _state.map((item, index) => {
                if (parseInt(item.id) !== parseInt(action.updates.id)) { 
                    // This isn't the item we care about - keep it as-is
                    return item
                }
                // Otherwise, this is the one we want - return an updated value
                return {
                    ...item,
                    ...action.updates
                }
            });
            return sortPrices(prices);
        case 'PRICES_FETCH_FULFILLED': 
            return sortPrices(action.payload);
        case 'DELETE_PRICE':
            return state.filter(({id}) => id !== action.id);
        default:
            return state;
    }
}

const sortByKey = key => (a, b) => a[key] - b[key];

const sortPrices = (prices) => {
    if(!prices) return null;
    return prices.sort(sortByKey('rank'))
} 




