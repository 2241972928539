/// @ts-check
import moment from 'moment';


class InvoiceReader {
  static invoiceDateRE = /(\d{2}\/\d{2}\/\d{4})\s/;
  static invoiceNumRE = /\s+(\d+\/\d+)\s/;
  static bundleNumRE = /bundle.*?(\d+)/i;
  static slabsRE = /(\d+-\d+)\s+(\d+\.\d+)\s+(\w+(\s+\w+)*)\s+\d+\.\d+\s+\d+\.\d+\s+\d+\.\d+\s+(\d+\.\d+)\s+(\d+\.\d+)\s+(\d+\.\d+)/gi;
  static bundlesRE = /(bundle(.+?)(slabs))/gi;
  static slabLineRE =  /(\d+-\d+)\s+(.*)?\s+(\d+\.\d+)\s+\d+\.\d+\s+\d+\.\d+\s+\d+\.\d+\s+(\d+\.\d+)\s+(\d+\.\d+)\s+(\d+\.\d+)/i;

  static newSlab = (item, bundle) => { 
    return {
      slab_num: item[1],
      material: item[3],
      thick: item[2],
      length: item[7],
      height: item[6],
      sqft: item[5],
      bundle: bundle
    }
  }

  static newSlab2 = (item, bundle) => {
    return {
      slab_num: item[1],
      material: item[2],
      thick: item[3],
      length: item[4],
      height: item[5],
      sqft: item[6],
      bundle: bundle
    }
  }

  static parse = (str) => {
    return {
      bundles: InvoiceReader.getBundles(str),
      invoiceNum: InvoiceReader.findInvoiceNum(str),
      invoiceDate: InvoiceReader.findInvoiceDate(str),
      receivedAt: InvoiceReader.getReceivedDate()
    }
  }

  static getSlabs = (str) => {
    let bundle = "";
    let slabs = [];

    const slabLines = str.matchAll(InvoiceReader.slabsRE);

    for(const slabLine of slabLines) {
      const slab = InvoiceReader.newSlab(slabLine, bundle);
      slabs.push(slab);
    }
    return slabs;
  }

  static getBundles = (str) => {
    let bundels = [];

    const bundleList = [...str.matchAll(InvoiceReader.bundlesRE)];

    for (const bundleBlock of bundleList) {
      let bundleNum = InvoiceReader.findBundleNumber(bundleBlock[0])
      let slabs = InvoiceReader.getSlabs(bundleBlock[0]);
      bundels.push(
        {
          number: bundleNum,
          filename: "",
          slabs: slabs
        }
      );
    }
    return bundels;
  }
  
  static findBundleNumber = (str) => {
    const result= str.match(InvoiceReader.bundleNumRE);
    return (result)? result[1]: "";
  }

  static findInvoiceNum = (str) => {
    const result = str.match(InvoiceReader.invoiceNumRE);
    return (result)? result[1]: "";
  }

  static findInvoiceDate = (str) => {
    const date = str.match(InvoiceReader.invoiceDateRE) || [""];
    const invoiceDate = moment(date[0].trim(), "D/M/YYYY"); //convert from BR to US date-format
    return invoiceDate.format('MM/DD/YYYY')
  }
  
  static getReceivedDate = () => {
    return moment().format('MM/DD/YYYY');
  }

  static parseText = (text) => {
    text = text || "";
    text = text.replace(/,/g, '.');
    const lines = text.split("\n");

    const slabs = InvoiceReader.extractBundles(lines); 

    const packingList = {
      invoiceNum: InvoiceReader.findInvoiceNum(text),
      invoiceDate: InvoiceReader.findInvoiceDate(text),
      receivedAt: InvoiceReader.getReceivedDate(),
      bundles: InvoiceReader.bundleGroups(slabs)
    }

    return packingList;
  }

  static extractBundles = (lines) => {
    let bundle = "";
    let slabs = [];

    for(const line of lines)
    {
      //check if this line has the bundle or has slab line
      const bundLine = line.match(InvoiceReader.bundleNumRE);
      if(bundLine) {
        bundle = bundLine[1];
      }

      const slabLine = line.match(InvoiceReader.slabLineRE);
      if(slabLine) {
        const slab = InvoiceReader.newSlab2(slabLine, bundle);
        slabs.push(slab);
      }
    }
    return slabs;
  }

  static bundleGroups = (slabs) => {
    const groups = InvoiceReader.GroupByBundle(slabs);
    let bundles = [];
    for(const key of Object.keys(groups))
    {
      bundles.push(
        {
          number: key,
          filename: "",
          slabs: groups[key] || []
        }
      );
    }
  
    return bundles;
  }

  static groupBy = key => array => {
    return array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
  }
  
  static GroupByBundle = InvoiceReader.groupBy('bundle');
}

export default InvoiceReader;
