import React, { Component, useState } from "react";
// import classNames from 'classnames';
import { CSVLink } from "react-csv";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import {
  fetchContacts,
  updateContact,
  createContact,
  fetchGoogleAddress,
  deleteContact,
} from "../services/apiService";
import {
  Spinner,
  showAlert,
  showDeleteConfirmation,
} from "../components/dialogs";
import { CONSTS } from "./../constants";

const MODE = {
  list: 1,
  map: 2,
  edit: 3,
};

const mousePt = { cursor: "pointer" };

const CONTACT = {
  id: 0,
  name: "",
  company: "",
  notes: "",
  email: "",
  phone: "",
  street: "",
  city: "",
  state: "",
  zip: "",
  region: "",
  website: "",
  formatted_address: "",
};

const headers = [
  { label: "Company", key: "company" },
  { label: "Name", key: "name" },
  { label: "Phone", key: "phone" },
  { label: "Email", key: "email" },
  { label: "Address", key: "street" },
  { label: "City", key: "city" },
];

export default class Contacts extends Component {
  watchId = null;
  state = {
    contacts: [],
    contact: CONTACT,
    cities: [],
    loading: true,
    mode: MODE.list,
    searchText: "",
    csvData: [],
    position: null,
    sortColumn: "company",
    sortDirection: 1,
  };

  async componentDidMount() {
    this.showSpinner(true);

    const contacts = await fetchContacts();
    this.setState({ contacts });
    this.createCheckboxes(contacts);
    this.getLocation();
    this.showSpinner(false);
  }

  componentWillUnmount() {
    try {
      navigator.geolocation.clearWatch(this.watchId);
    } catch (err) {
      console.log("clearWatch geolocation");
    }
  }

  checkGoogle = async () => {
    this.showSpinner(true);

    this.state.contacts.forEach(async (element, index) => {
      // eslint-disable-next-line
      if (element.street != "" && !element.formatted_address) {
        const address = encodeAddressUrl(element);
        const url =
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          address +
          "&key=" +
          CONSTS.GOOGLE_API_KEY;

        let data = await fetchGoogleAddress(url);
        console.log(url, data);
        if (!data || data.error) return;
        let formatted = data.formatted_address.replace(/,*\s*usa/gi, "");
        let loc = JSON.stringify(data.geometry.location);

        const contact = {
          id: element.id,
          formatted_address: formatted,
          location: loc,
        };

        const result = await updateContact(contact);

        if (!result.error) this.updateContacts(result);
      }
    });

    this.showSpinner(false);
  };

  addContact = () => {
    this.setState({ mode: MODE.edit, contact: CONTACT });
    window.scrollTo(0, 0);
  };

  showMap = () => {
    if (this.state.mode === MODE.map) {
      this.setState({ mode: MODE.list });
      return;
    }
    this.setState({ mode: MODE.map });
  };

  getLocation = () => {
    if (navigator.geolocation && this.watchId == null) {
      this.watchId = navigator.geolocation.watchPosition(this.setPosition);
      console.log("position called", this.watchId);
    } else if (!this.watchId) {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  setPosition = (position) => {
    this.setState({ position });
    console.log("Latitude: " + position.coords.latitude);
    console.log("Longitude: " + position.coords.longitude);
  };

  updateContacts = (contact) => {
    const tmp = this.state.contacts.map((item) => {
      if (item.id === contact.id) {
        return {
          ...item,
          ...contact,
        };
      }
      return item;
    });
    this.setState({ contacts: tmp });
  };

  createCheckboxes = (data) => {
    let cities = {};

    data.forEach((element) => {
      cities[element.city] = false;
    });

    this.setState({ cities });
  };

  handleCheckboxChange = (changeEvent) => {
    const { name, checked } = changeEvent.target;

    this.setState((prevState) => ({
      cities: {
        ...prevState.cities,
        [name]: checked,
      },
    }));
    console.log(this.state.cities);
  };

  filter = () => {
    const selected = Object.keys(this.state.cities).filter(
      (item) => this.state.cities[item]
    );
    let filtered = this.state.contacts.sort(this.compare);

    if (selected.length > 0) {
      filtered = filtered.filter((item) => selected.indexOf(item.city) > -1);
    }

    if (this.state.searchText.length > 0) {
      const searchText = this.state.searchText.toLowerCase();
      filtered = filtered.filter((item) => {
        const company = item.company || "";
        const name = item.name || "";
        return (
          company.toLowerCase().indexOf(searchText) !== -1 ||
          name.toLowerCase().includes(searchText)
        );
      });
    }

    return filtered;
  };

  compare = (a, b) => {
    const column = this.state.sortColumn;
    const avalue = a[column] || "";
    const bvalue = b[column] || "";
    return avalue.localeCompare(bvalue) * this.state.sortDirection;
  };

  unselectAllCheckboxes = () => {
    Object.keys(this.state.cities).forEach((name) => {
      this.setState((prevState) => ({
        cities: {
          ...prevState.cities,
          [name]: false,
        },
      }));
    });
  };

  showSpinner(value) {
    this.setState({ loading: value });
  }

  viewContact = (contact) => {
    console.log("contact clicked " + contact.id);
    this.setState({ mode: MODE.edit, contact });
    window.scrollTo(0, 0);
  };

  contactChange = (event) => {
    console.log("contactChange ", event.target.name);
    const name = event.target.name;
    const value = event.target.value;

    this.setState((prevState) => ({
      contact: {
        ...prevState.contact,
        [name]: value,
      },
    }));
  };

  saveContact = async () => {
    this.showSpinner(true);
    const data = this.state.contact;
    if (data.id > 0) {
      const contact = await updateContact(this.state.contact);
      if (!contact.error) {
        showAlert("contact updated", "success");
        this.updateContacts(contact);
      }
    } else {
      const contact = await createContact(this.state.contact);
      if (!contact.error) {
        showAlert("contact created", "success");
        this.addToContacts(contact);
      }
    }

    this.showSpinner(false);
  };

  removeContact2 = () => {
    const id = this.state.contact.id;
    showAlert("Do you want to delete " + id);
    this.showSpinner(true);
    deleteContact(id);
    this.showSpinner(false);
  };

  removeContact = async () => {
    const id = this.state.contact.id;

    showDeleteConfirmation(async () => {
      this.showSpinner(true);
      const res = await deleteContact(id);

      if (!res.error) {
        this.deleteContact(id);
        showAlert("Contact deleted", "success");
        this.showContacts();
      } else {
        showAlert(res.message, "error");
      }

      this.showSpinner(false);
    });
  };

  deleteContact = (cid) => {
    const removed = this.state.contacts.filter(({ id }) => id !== cid);
    this.setState({ contacts: removed });
  };

  addToContacts = (contact) => {
    const contacts = this.state.contacts.concat(contact);
    this.setState({ contacts });
  };

  showContacts = () => {
    this.setState({ mode: MODE.list });
  };

  searchChanged = (event) => {
    this.setState({
      searchText: event.target.value,
    });
  };

  exportList = (data) => {
    this.setState({ csvData: data });
  };

  changeSortColumn = (name) => {
    //change direction if same column name otherwise make it asc
    const direction =
      this.state.sortColumn === name ? this.state.sortDirection * -1 : 1;
    this.setState({
      sortColumn: name,
      sortDirection: direction,
    });
  };

  render() {
    const cities = Object.keys(this.state.cities);
    const filtered = this.filter();
    //const isList = this.state.mode === MODE.list;
    // const blockClassname = classNames('row', {'slide-right': isList, 'slide-right-after': !isList});

    return (
      <div className="page-s">
        <Spinner isVisible={this.state.loading} />
        {(this.state.mode === MODE.list || this.state.mode === MODE.map) && (
          <div className={"row"}>
            <div className="px-5 pt-2 side-extra col-md-2">
              <button
                onClick={this.unselectAllCheckboxes}
                className="btn btn-outline-secondary"
              >
                Unselect All
              </button>
              {cities.map((item) => (
                <Checkbox
                  label={item}
                  isSelected={this.state.cities[item]}
                  onCheckboxChange={this.handleCheckboxChange}
                  key={item}
                />
              ))}
              <div className="mt-3">
                <CSVLink
                  data={this.state.csvData}
                  headers={headers}
                  onClick={() => this.exportList(filtered)}
                >
                  Export
                </CSVLink>
              </div>
            </div>
            <div className="pt-4 col-lg-10 main-extra">
              <form className="form-inline mb-2">
                <button
                  type="button"
                  className="btn btn-outline-secondary ml-2 mb-2"
                  onClick={this.addContact}
                >
                  <i className="fa fa-plus"></i> Create contact
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary ml-2 mb-2"
                  onClick={this.showMap}
                >
                  <i className="fa fa-map"></i>
                </button>
                {/* <button type='button' disabled={this.state.loading}  className='btn btn-outline-secondary ml-2 mb-2' onClick={this.checkGoogle}>Check google</button> */}
                <label className="sr-only" htmlFor="search">
                  Search
                </label>
                <input
                  type="search"
                  className="form-control ml-2 mb-2"
                  name="searchcontact"
                  placeholder="Search"
                  value={this.state.searchText}
                  onChange={this.searchChanged}
                ></input>
                <label className="ml-2 mb-2 count-label">
                  Contacts {filtered.length}
                </label>
              </form>

              {this.state.mode === MODE.map && (
                <ContactsMap
                  isMarkerShown
                  contacts={filtered}
                  googleMapURL={CONSTS.GOOGLE_MAPS_API + CONSTS.GOOGLE_API_KEY}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `400px` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  position={this.state.position}
                />
              )}
              <div className="table-responsive mt-4">
                <table className="table table-border table-hover table-sm">
                  <thead className="thead-light">
                    <tr>
                      <th
                        style={mousePt}
                        onClick={this.changeSortColumn.bind(this, "company")}
                      >
                        Company
                      </th>
                      <th
                        style={mousePt}
                        onClick={(e) => this.changeSortColumn("name")}
                      >
                        Contact
                      </th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Address</th>
                      <th
                        style={mousePt}
                        onClick={(e) => this.changeSortColumn("city")}
                      >
                        City
                      </th>
                      {/* <th>Google</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {filtered.map((item, index) => (
                      <ContactRow
                        key={item.id}
                        item={item}
                        index={index + 1}
                        select={this.viewContact}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {this.state.mode === MODE.edit && (
          <ContactDetail
            contact={this.state.contact}
            change={this.contactChange}
            save={this.saveContact}
            remove={this.removeContact}
            showContacts={this.showContacts}
            isVisible={this.state.mode === MODE.edit}
          />
        )}
      </div>
    );
  }
}

const ContactRow = ({ item, index, select }) => {
  return (
    <tr onClick={() => select(item)}>
      <td>{item.company}</td>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.phone}</td>
      <td>{item.street}</td>
      <td>{item.city}</td>
      {/* <td>{item.formatted_address}</td> */}
    </tr>
  );
};

const ContactDetail = ({
  contact,
  change,
  save,
  showContacts,
  remove,
  isVisible,
}) => {
  let street = contact.street || "";
  street = street.replace(/\s+/, "+");
  const address =
    "https://www.google.com/maps/place/" +
    street +
    "+" +
    contact.city +
    "+" +
    contact.state +
    "+" +
    contact.zip;
  const hide = isVisible ? { display: "block" } : { display: "none" };

  // const blockClassname = classNames('container', {'slide-left': isVisible, 'slide-left-after': !isVisible});

  return (
    <div className={"container"}>
      <div className="pt-3" style={hide}>
        <button onClick={showContacts} className="btn btn-outline-secondary">
          <i className="fa fa-arrow-left mr-2"></i>Contacts
        </button>
        <h4>Contact Info</h4>
      </div>
      <form className="p-md-5" style={hide}>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="company">Company</label>
            <input
              type="text"
              className="form-control"
              name="company"
              placeholder="Enter company name"
              value={contact.company}
              onChange={change}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="name">Contact Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Enter contact Name"
              value={contact.name}
              onChange={change}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="email">Email</label>
            <div className="input-group">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Enter email"
                value={contact.email}
                onChange={change}
              />
              <span className="input-group-append">
                <a
                  className="btn btn-secondary"
                  role="button"
                  href={"mailto:" + contact.email}
                >
                  <i className="fa fa-envelope"></i>
                </a>
              </span>
            </div>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="phone">Phone</label>
            <div className="input-group">
              <input
                type="tel"
                className="form-control"
                name="phone"
                placeholder="Enter phone"
                value={contact.phone}
                onChange={change}
              />
              <span className="input-group-append">
                <a
                  className="btn btn-secondary"
                  role="button"
                  href={"tel:" + contact.phone}
                >
                  <i className="fa fa-phone"></i>
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="street">Street</label>
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              name="street"
              placeholder="Enter street"
              value={contact.street}
              onChange={change}
            />
            <span className="input-group-append">
              <a
                className="btn btn-secondary"
                role="button"
                target="_blank"
                rel="noopener noreferrer"
                href={address}
              >
                <i className="fa fa-map-o"></i>
              </a>
            </span>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-5">
            <label htmlFor="city">City</label>
            <input
              type="text"
              className="form-control"
              name="city"
              placeholder="Enter city"
              value={contact.city}
              onChange={change}
            />
          </div>
          <div className="form-group col-md-3">
            <label htmlFor="state">State</label>
            <input
              type="text"
              className="form-control"
              name="state"
              placeholder="Enter state"
              value={contact.state}
              onChange={change}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="zip">Zip</label>
            <input
              type="text"
              className="form-control"
              name="zip"
              placeholder="Enter zip"
              value={contact.zip}
              onChange={change}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label htmlFor="website">Website</label>
            <input
              type="url"
              className="form-control"
              name="website"
              placeholder="Enter website"
              value={contact.website}
              onChange={change}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="region">Region</label>
            <input
              type="text"
              className="form-control"
              name="region"
              placeholder="Enter region"
              value={contact.region}
              onChange={change}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="notes">Notes</label>
          <input
            type="text"
            className="form-control"
            name="notes"
            placeholder="Enter notes"
            value={contact.notes}
            onChange={change}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="formatted_address"
            placeholder=""
            value={contact.formatted_address}
            onChange={change}
          />
          <small className="form-text text-muted">Google Maps Address</small>
        </div>
        <div className="row">
          <button
            type="button"
            className="btn btn-outline-primary mr-3"
            onClick={save}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-outline-danger mr-3"
            onClick={remove}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={showContacts}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

const Checkbox = ({ label, isSelected, onCheckboxChange }) => (
  <div className="form-check">
    <input
      type="checkbox"
      name={label}
      checked={isSelected}
      onChange={onCheckboxChange}
      className="form-check-input"
    />
    <label className="form-check-label">{label}</label>
  </div>
);

const encodeAddressUrl = ({ street, city, state }) => {
  let _street = street.replace(/,/g, " ").replace("#", " APT ");
  const tmp = _street + "," + city + "," + state;
  return tmp.replace(/\s+/g, "+");
};

const getCenterPosition = (position) => {
  if (!position) return CONSTS.DEFAULT_CENTER;

  return {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  };
};

const directionsLink = (selected) => {
  const dest = `${selected.street},${selected.city},${selected.state}`;
  return `https://www.google.com/maps/dir/Current+Location/${dest}`;
};

const ContactsMap = withScriptjs(
  withGoogleMap((props) => {
    const [selected, setSelected] = useState(null);
    const center = getCenterPosition(props.position);
    const zoom = props.position ? 10 : 6;
    const mylocation_img = "../images/mylocation-map-icon.png";

    return (
      <GoogleMap defaultZoom={zoom} defaultCenter={center}>
        {props.isMarkerShown &&
          props.contacts.map((item) => (
            <Marker
              key={item.id}
              position={JSON.parse(item.location)}
              onClick={() => setSelected(item)}
            />
          ))}
        {props.position && <Marker position={center} icon={mylocation_img} />}
        {selected && (
          <InfoWindow
            position={JSON.parse(selected.location)}
            onCloseClick={() => setSelected(null)}
          >
            <div>
              <h6>{selected.company}</h6>
              <div>{selected.street}</div>
              <div>{selected.city}</div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={directionsLink(selected)}
                >
                  Get Directions
                </a>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    );
  })
);
