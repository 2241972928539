import history from '../history';
import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-config';
import jwtweb from 'jsonwebtoken';

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.apiUrl,
    responseType: AUTH_CONFIG.responseType,
    scope: AUTH_CONFIG.scope
  });

  
  

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getProfile = this.getProfile.bind(this); 
    this.printProfile = this.printProfile.bind(this);
    
  /*eslint no-restricted-globals:["off"] */
    this.returnUrl = location.protocol+'//'+window.location.host+'/home';
    this.userProfile =  null;
    this.accessToken = localStorage.getItem('access_token');
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) { 
        this.setSession(authResult);
        const redirectPage = localStorage.getItem('redirect') || '/'
        history.replace(redirectPage);
        location.reload();
      } else if (err) {
        history.replace('/');
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the home route
    history.replace('/home');
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_id');
    this.userProfile = null;
    this.accessToken = null;
    this.auth0.logout({returnTo: this.returnUrl});
    // navigate to the home route
    history.replace('/home');
  }

  isAuthenticated() {
    // Check whether the current time is past the 
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  } 

  printProfile() {
    if(!this.accessToken) return;
    
    this.auth0.client.userInfo(this.accessToken, (err, profile) => {
      console.log("profile");
      if (profile) {
          // Get the user’s nickname and profile image
          const username = ''+profile.nickname && profile.email;
          console.log('name: '+ username);
          console.log('picture: '+profile.picture);
          console.log(profile);
         // var nickname = profile.nickname;
          //var picture = profile.picture;
      }
    });
  }

  getProfile(cb) {
    if(!this.accessToken) return;

    this.auth0.client.userInfo(this.accessToken, (err, profile) => {
      if (profile) {
        if(profile.name !== profile.email)
        {
          profile.nickname = profile.name;
        }
        this.userProfile = profile;
      }
      cb(err, profile);
    });
  }
  
  decodeToken() {
    var idToken = localStorage.getItem('id_token');
    if(!idToken) return;
    var decoded = jwtweb.decode(idToken); // decode(accessToken, {complete:true, secret: client_id})
    console.log('decoded token');
    localStorage.setItem('user_id', decoded.sub);
    console.log(decoded);
  }
}
