/*eslint  eqeqeq: off*/
import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { fetchPrices } from "./services/apiService";
import { formatMoney } from "./services/utils";
import Auth from "./Auth/Auth";
import { pricesFetchedAction } from "./actions";
import { PROMO_DATES } from "./constants";
// import './Table.css';
import { getSetting } from "./services/apiService";
import PriceContactDialog from "./components/PriceContactDialog";

var auth = new Auth();

const limitedStyle = (limited) => {
  return limited == 1
    ? {
        backgroundColor: "#f00",
        color: "#fff",
        fontWeight: "bold",
      }
    : {};
};

const groupColor = {
  1: "#99FF66",
  2: "#FE72AE",
  3: "#FFFF66",
  4: "#00B0F0",
  5: "#FF6600",
  6: "#CC0099",
  7: "gray",
};

class PricePrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      loading: true,
      startDate: "",
      endDate: "",
      contact: {
        name: "Luis",
        email: "graniteworldutah@gmail.com",
        phone: "(801) 687-9500",
      },
    };
  }

  async componentDidMount() {
    this.processDates();

    if (!this.props.prices) {
      const data = await fetchPrices();
      this.props.dispatch(pricesFetchedAction(data));

      this.setState({ ready: true, loading: false });
    } else {
      this.setState({ ready: true, loading: false });
    }
  }

  processDates = async () => {
    const dates = await getSetting(PROMO_DATES);
    console.log(dates);
    const data = JSON.parse(dates.value);
    console.log(data);
    this.setState({
      startDate: this.dateString(data.startDate),
      endDate: this.dateString(data.endDate),
    });
  };

  dateString = (dateStr) => {
    const date = dateStr ? moment(dateStr).format("MM/DD/YYYY") : "";
    return date;
  };

  handleUpdateContact = (data) => {
    this.setState({
      contact: { ...data },
    });
  };

  render() {
    const contact = this.state.contact;

    return (
      <div
        className="price-sheet"
        style={{ backgroundColor: "white", height: "100%" }}
      >
        {this.state.loading && <div className="spinner"></div>}
        <div>
          <button
            className="btn btn-outline-secondary"
            data-toggle="modal"
            data-target="#editorDialog"
          >
            <i className="fa fa-address-card"></i>
          </button>
        </div>
        <div className="price-header">
          <h2>GRANITE WORLD LLC</h2>
          <h3 style={{ color: "gray" }}>Fabricator Pricing Sheet</h3>
        </div>
        {auth.isAuthenticated() ? (
          <table style={{ width: "100%", height: "100%" }}>
            <tbody>
              <tr>
                <td style={{ width: "88%" }}>
                  {" "}
                  {/* left panel */}
                  <table
                    className="price-table"
                    style={{
                      width: "100%",
                      backgroundColor: "green",
                      height: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          className="limited-header"
                          colSpan="4"
                          style={limitedStyle(1)}
                        >
                          RED PRICING FOR LIMITED TIME ONLY
                          <span className="ml-5">
                            {this.state.startDate + " - " + this.state.endDate}{" "}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th>Group </th>
                        <th>Name </th>
                        <th>Unit</th>
                        <th>Bundle</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.prices &&
                        this.props.prices.map((row, index) => (
                          <TableRow key={row.id} index={index} slab={row} />
                        ))}
                    </tbody>
                  </table>
                </td>
                <td
                  className="right-panel"
                  style={{ backgroundColor: "#fff", padding: 0 }}
                >
                  {" "}
                  {/* right panel */}
                  <table className="side-table" style={{ height: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <div
                            className="vertical ver-div"
                            style={{ fontSize: "20pt" }}
                          >
                            <b>Free Delivery!</b>
                          </div>
                        </td>
                        <td>
                          <div
                            className="vertical ver-div"
                            style={{ fontSize: "22pt" }}
                          >
                            Call {contact.name} to buy Granite Bundles:{" "}
                            {contact.phone}
                          </div>
                        </td>
                        <td>
                          <div
                            className="vertical ver-div"
                            style={{ fontSize: "28pt" }}
                          >
                            See Our Pictures at <b>GraniteWorldUtah.com</b>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <table>
                    <tbody>
                      <tr className="bottom-panel">
                        <td>
                          <div
                            style={{
                              paddingTop: 20,
                              paddingRight: 50,
                              fontSize: "14pt",
                            }}
                          >
                            <b>CONTACT</b>
                            <br />
                            {contact.name}: {contact.phone} <br />
                            EMAIL: {contact.email}
                            <br />
                          </div>
                        </td>
                        <td>
                          <div style={{ paddingTop: 20, fontSize: "14pt" }}>
                            <b>OUR YARD</b>
                            <br />
                            {/* <br /> */}
                            2176 W Center St. <br />
                            Provo, UT 84601
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div
            style={{ height: "100vh", textAlign: "center", color: "#bb0000" }}
          >
            Please Log in
          </div>
        )}
        <PriceContactDialog
          contact={this.state.contact}
          updateContact={this.handleUpdateContact}
        />
      </div>
    );
  }
}

/*eslint-disable */
const mapStateToProps = (state) => ({
  prices: state.prices,
});
/*eslint-enable */

const TableRow = (props) => {
  const slab = props.slab;

  return (
    <tr style={limitedStyle(slab.limited)}>
      <td
        style={{
          backgroundColor: groupColor[slab.group_num],
          color: "#000",
          fontWeight: "normal",
        }}
      >
        {"Group " + slab.group_num}
      </td>
      <td>{slab.material}</td>
      <td>{formatMoney(slab.unit_price, 2, "")}</td>
      <td>{formatMoney(slab.bundle_price, 2, "")}</td>
    </tr>
  );
};

export default connect(mapStateToProps)(PricePrint);
