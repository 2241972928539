import React, { Component } from "react";
import axios from "axios";
import { CONSTS } from "./../../constants";
import Carousel from "./Carousel";
import ImageZoom from "react-medium-image-zoom";
import { capitalize } from "./../../services/utils";
//import 'react-medium-image-zoom/dist/styles.css'

export default class GraniteSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      granites: [],
      allGranites: [],
      graniteTypes: [],
    };

    this.thumbnail = CONSTS.imgBaseUrl + "thumbnails/";
    this.changeCarouselIndex = this.changeCarouselIndex.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.carousel = React.createRef();
  }

  componentDidMount() {
    var url = CONSTS.server + "granites";
    axios.get(url).then((res) => {
      const granites = res.data;
      this.setState({ granites });
      this.setState({ allGranites: granites });
      const graniteTypes = [
        ...new Set(
          granites.map((granite) => granite.material_type.toLowerCase())
        ),
      ];
      this.setState({ graniteTypes });
    });
  }

  compare = (a, b) => {
    const column = this.state.sortColumn;
    const avalue = a[column] || "";
    const bvalue = b[column] || "";
    return avalue.localeCompare(bvalue);
  };

  handleSorting = (event) => {
    const sortColumn = event.target.value;
    console.log("handleSorting ", sortColumn);
    const granites = this.state.granites.sort((a, b) => {
      const avalue = a[sortColumn] || "";
      const bvalue = b[sortColumn] || "";
      if (typeof avalue == "number") {
        return avalue - bvalue;
      }
      return avalue.localeCompare(bvalue);
    });

    this.setState({ granites });
  };

  handleTypeChange = (event) => {
    const type = event.target.value;
    if (type === "all") {
      this.setState({ granites: this.state.allGranites });
    } else {
      const granites = this.state.allGranites.filter(
        (granite) => granite.material_type.toLowerCase() == type
      );
      this.setState({ granites });
    }
  };

  changeCarouselIndex(index) {
    this.carousel.current.goTo(index);
  }

  render() {
    return (
      <div className="page" id="granite">
        <div>
          <h2>Our Selection</h2>
          <div className="row">
            <select
              name="sort"
              onChange={this.handleSorting}
              style={{ display: "inline", marginLeft: "50px" }}
            >
              <option value="name">Sort A to Z</option>
              <option value="group_num">Sort by Group</option>
              <option value="color_index">Sort by Color</option>
            </select>

            <select
              name="sort"
              onChange={this.handleTypeChange}
              style={{ display: "inline", marginLeft: "20px" }}
            >
              <option value="all">All</option>
              {this.state.graniteTypes.map((type, index) => (
                <option key={type} value={type}>
                  {capitalize(type)}
                </option>
              ))}
            </select>
          </div>
          <div className="granite-view">
            {this.state.granites.map((granite, index) => (
              <div key={granite.id} className="granite-vcard">
                <ImageZoom
                  shouldReplaceImage={false}
                  shouldRespectMaxDimension={false}
                  onZoom={this.changeCarouselIndex.bind(this, index)}
                  image={{
                    src: this.thumbnail + granite.filename,
                    alt: granite.name,
                    className: "img",
                  }}
                  zoomImage={{
                    src: CONSTS.imgBaseUrl + granite.filename,
                    alt: granite.name,
                    style: {
                      width: "100%",
                      height: "100%",
                      background: "#000",
                    },
                  }}
                />
                <div>{granite.name}</div>
                <div className="granite-vcard-subtitle">
                  Group {granite.group_num}
                </div>
              </div>
            ))}
          </div>
        </div>
        <Carousel granites={this.state.granites} ref={this.carousel} />
      </div>
    );
  }
}
