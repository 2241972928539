import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
// import { Button } from 'react-bootstrap';
//Navbar,

// const linkActiveStyle = {
//   backgroundColor: "#F0F0F0",
//   fontWeight: "bold"
// };

class NavBar2 extends Component {
  componentDidMount() {
    this.toggleDropdown();
  }

  toggleDropdown = () => {
    $(".leaf").on("click", function() {
      $(".home-navbar-toggler").click();
    });
  };

  goTo(route) {
    // this.props.history.replace(`/${route}`)
  }

  login() {
    // this.props.auth.login();
  }

  logout() {
    //this.props.auth.logout();
  }

  render() {
    //const { isAuthenticated } = this.props.auth;

    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        id="navbar-bar"
      >
        <div className="container">
          <a className="navbar-brand" href="/" style={{ padding: 0 }}>
            <img
              src="images/welovegranite_logo.jpg"
              style={{ height: "47px" }}
              alt="logo"
            />
          </a>
          <button
            className="navbar-toggler home-navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-toggler"
            aria-controls="navbar-toggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbar-toggler">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link leaf" href="#home2">
                  HOME
                  <span className="sr-only">home</span>
                </a>
              </li>
              <li className="nav-item nav-item-separator">{"//"}</li>
              <li className="nav-item">
                <a className="nav-link leaf" href="#about">
                  ABOUT
                  <span className="sr-only">about</span>
                </a>
              </li>
              <li className="nav-item nav-item-separator">{"//"}</li>
              <li className="nav-item">
                <a className="nav-link leaf" href="#granite">
                  GRANITE
                  <span className="sr-only">granite</span>
                </a>
              </li>
              <li className="nav-item nav-item-separator">{"//"}</li>
              <li className="nav-item">
                <a className="nav-link leaf" href="#contact">
                  CONTACT
                  <span className="sr-only">contact</span>
                </a>
              </li>
              <li className="nav-item nav-item-separator">{"//"}</li>
              <li className="nav-item">
                <NavLink
                  className="nav-link leaf"
                  to="/terms"
                  activeClassName={"active"}
                >
                  TERMS
                </NavLink>
              </li>
              <li className="nav-item nav-item-separator">{"//"}</li>
              <li className="nav-item">
                <NavLink
                  className="nav-link leaf"
                  to="/slabs"
                  activeClassName={"active"}
                >
                  SLABS
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar2;

// </Route>
// </BrowserRouter>
