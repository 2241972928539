//@ts-check
/* eslint eqeqeq: "off"*/
import React, { Component } from "react";
import moment from "moment";
import classNames from "classnames";
import { connect } from "react-redux";

import { formatMoney, toFixed } from "../services/utils";
import {
  fetchOrders,
  createPayment,
  createDelivery,
  updateDelivery,
  deleteDelivery,
} from "../services/apiService";
import {
  Spinner,
  showAlert,
  showDeleteConfirmation,
} from "../components/dialogs";
import PaymentEditModal from "../components/PaymentEdit";
import { updateOrderAction } from "../actions";
import DeliveryModal from "../components/DeliveryModal";

const toBool = (val) => {
  return +val == 1 || val === true;
};

export const formatDatetime = (datetime) => {
  if (!datetime) return "";
  if (datetime.startsWith("0000-00-00")) return "";
  const tmp = moment(datetime).format("MMM D, YYYY @ h:mm a");
  return tmp;
};

const initial = {
  id: 0,
  customer: {},
  items: [],
  discounts: [],
  payments: null,
  modalIsOpen: false,
  delivery: null,
};
class InvoiceView extends Component {
  redirectSufix = "";
  state = {
    loading: true,
    order: initial,
    balance: 1,
    delivery: {},
  };

  async componentDidMount() {
    this.showSpinner();
    // @ts-ignore
    moment.suppressDeprecationWarnings = true;
    if (this.props.location.search.search("redirect=all") > -1) {
      this.redirectSufix = "/all";
    }

    const order = this.props.order || {};
    if (order.id < 1) {
      const res = await fetchOrders(this.props.match.params.id);
      this.setState({ order: res });
    } else {
      this.setState({ order: order });
    }

    this.hideSpinner();
  }

  handleClose = () => {
    this.props.history.push(`/orders${this.redirectSufix}`);
  };

  handleEdit = () => {
    this.props.history.push(`/orders/edit/${this.state.order.id}`);
  };

  showSpinner = (value = true) => {
    this.setState({ loading: value });
  };

  hideSpinner = () => {
    this.setState({ loading: false });
  };

  appendClassStatus = (str, check) => {
    if (check) return str + " success";
    return str + " danger";
  };

  handleAddPayment = async (data) => {
    this.showSpinner();

    const result = await createPayment(data);
    if (!result.error) {
      this.updateOrderAction(data);
      showAlert("Payment added", "success");
    } else {
      showAlert("Payment couldn't be added", "success");
    }

    this.hideSpinner();
  };

  orderBalance = () => {
    const order = this.state.order;
    const totalPayments = this.totalPayments(order.payments);
    const totalInvoiced = +order.total;
    const balance = toFixed(totalInvoiced) - toFixed(totalPayments);
    return balance;
  };

  totalPayments = (payments) => {
    if (!payments) return 0;
    const total = payments.reduce(
      (acumulator, item) => (acumulator += Number(item.amount)),
      0
    );
    return total;
  };

  handleSaveDelivery = async (data) => {
    this.closeModal();
    data["delivered_at"] = this.combineDateAndTime(
      data.delivered_at,
      data.delivered_time
    );
    data["order_id"] = this.state.order.id;
    let result = null;
    if (data.id > 0) {
      result = await updateDelivery(data);
    } else {
      result = await createDelivery(data);
    }

    if (!result.error) {
      console.log("delivery saved ");
      this.updateOrderAction(result);
      showAlert("Delivery saved", "success");
    } else {
      showAlert("Update failed", "error");
    }
  };

  deleteDelivery = async (data) => {
    const id = data.id;
    if (!id) {
      showAlert("Nothing to delete", "error");
      return;
    }

    showDeleteConfirmation(async () => {
      this.closeModal();

      this.showSpinner(true);
      const res = await deleteDelivery(id);

      if (!res.error) {
        await this.updateOrderAction();
        showAlert("Deivery deleted", "success");
      } else {
        showAlert(res.message, "error");
      }
      this.showSpinner(false);
    });
  };

  getTime = (time) => {
    if (!time) return "00:00:00";
    const _time = moment("2018-12-12 " + time).format("HH:mm:00");
    return _time;
  };

  combineDateAndTime = (date, time) => {
    const _time = this.getTime(time);
    const datetime = moment(date).format("YYYY-MM-DD " + _time);
    return datetime;
  };

  updateOrderAction = async (data = {}) => {
    const order_id = data.order_id ? data.order_id : this.state.order.id;
    const res = await fetchOrders(order_id);
    //console.log("updateOrderAction res", res);
    if (!res.error) {
      this.setState({ order: res });
      this.props.dispatch(updateOrderAction(res));
    }
    return;
  };

  openDeliveryModal = () => {
    const delivery = this.getDeliveryInfo();
    this.setState({ modalIsOpen: true, delivery: delivery });
  };

  getDeliveryInfo = () => {
    if (this.state.order.delivery) return this.state.order.delivery;

    const customer = this.state.order.customer.name;
    const user = this.props.profile.nickname;
    return {
      delivered_by: user,
      received_by: customer,
      delivered_at: new Date(),
    };
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    const order = this.state.order || initial;
    const customer = order.customer || {};
    const shipping = order.shipping || customer;

    const paid = this.orderBalance() <= 0;
    const delivered = order.delivery && order.delivery.delivered_at;
    const paidClass = classNames("fa fa-usd ml-1 mr-2", {
      "text-danger": !paid,
      "text-success": paid,
    });
    const deliveredClass = classNames("fa fa-truck ml-1 mr-2", {
      "text-danger": !delivered,
      "text-success": delivered,
    });

    return (
      <div className="invoice-page bg-light">
        <Spinner isVisible={this.state.loading} />
        <div className="container mt-4 py-5 bg-white invoice-container">
          <div
            className="py-3 px-3 bg-light"
            style={{ border: "0px solid gray" }}
          >
            <button
              className="btn btn-outline-secondary"
              onClick={this.handleClose}
            >
              <i className="fa fa-arrow-left mr-2"></i>
              Orders
            </button>
            {!order.deleted_at && (
              <div className="float-right">
                <button
                  className="btn btn-outline-secondary ml-4"
                  data-toggle="modal"
                  data-target="#paymentEdit"
                >
                  <i className={paidClass}></i>Add Payment
                </button>
                <button
                  className="btn btn-outline-secondary ml-4"
                  onClick={this.openDeliveryModal}
                >
                  <i className={deliveredClass}></i>Update Delivery
                </button>
                <button
                  className="btn btn-outline-secondary ml-4"
                  onClick={this.handleEdit}
                >
                  <i className="fa fa-edit ml-1 mr-2"></i>Edit
                </button>
              </div>
            )}
            {order.deleted_at && (
              <div className="float-right text-danger pr-5">
                <h1>DELETED</h1>
              </div>
            )}
          </div>
          <div className="row my-4">
            {/* <div className="col-md-3">
                    <div className="invoice-title" >INVOICE</div>
                </div> */}
            <div className="bill-to-box col-md-4">
              <div className="text-weight-bold high-line">BILL TO:</div>
              <div className="">
                <div>{customer.company} </div>
                <div>
                  {customer.street} <div></div>
                  {customer.city}, {customer.state} {customer.zip}
                </div>
                <div>{customer.phone}</div>
                <div></div>
              </div>
            </div>
            <div className="bill-to-box col-md-5">
              <div className="text-weight-bold high-line">SHIP TO:</div>
              <div>
                <div>{shipping.name}</div>
                <div>
                  {shipping.street} <div></div>
                  {shipping.city}, {shipping.state} {shipping.zip}
                </div>
                <div>{shipping.phone}</div>
                <div></div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="invoice-number2">
                <div>
                  Invoice # <span>{order.id} </span>
                </div>
                <div className="text-bottom3">
                  Date: <span>{toDate(order.created_at)}</span>
                </div>
                <div className="text-bottom3">
                  Rep: <span>{order.sales_rep}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="bg-secondary text-white p-1 mb-2">
              <h4>Order</h4>
            </div>
            <table className="invoice-table">
              <thead>
                <tr className="text-left">
                  <th>#</th>
                  <th>Description</th>
                  <th>Unit Price</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {/* <!-- items >>---> */}
                {order.items &&
                  order.items.map((item) => (
                    <tr className="item" key={item.id}>
                      <td>{Number(item.index) + 1}</td>
                      <td>
                        {item.slab ? item.slab.material : ""} - {item.slab_num}
                        &nbsp;&nbsp;&nbsp;&nbsp;{item.sqft} sf&sup2; (
                        {item.slab && toFixed(item.slab.length)}&times;
                        {item.slab && toFixed(item.slab.height)})
                      </td>
                      <td>
                        {toBool(item.isbundle) ? (
                          <span>{item.bundle_price}*</span>
                        ) : (
                          <span>{item.unit_price}</span>
                        )}
                      </td>
                      <td>{formatMoney(item.total)}</td>
                    </tr>
                  ))}
                {order.discounts.map((item) => (
                  <tr key={item.name} className="item">
                    <td></td>
                    <td>
                      Discount <span className="small">({item.name})</span>
                    </td>
                    <td></td>
                    <td>-{formatMoney(item.amount)}</td>
                  </tr>
                ))}
                {order.fees &&
                  order.fees.map((item) => (
                    <tr key={item.name} className="item">
                      <td></td>
                      <td>
                        Fee <span className="small">({item.name})</span>
                      </td>
                      <td></td>
                      <td>+{formatMoney(item.amount)}</td>
                    </tr>
                  ))}
                {order.total_fees > 0 && (
                  <tr className="item">
                    <td></td>
                    <td>Total Fees</td>
                    <td></td>
                    <td>{formatMoney(order.total_fees)}</td>
                  </tr>
                )}
                {order.tax > 0 && (
                  <tr className="item">
                    <td></td>
                    <td>Tax {toFixed(order.tax_rate * 100)}% </td>
                    <td></td>
                    <td>{formatMoney(order.tax)}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              {order.items.length > 0 && (
                <div className="bg-lighter mt-3 p-3 text-right col-md-6 offset-md-6">
                  <span>TOTAL</span>
                  <span className="ml-3 font-weight-bold">
                    {formatMoney(order.total)}
                  </span>
                </div>
              )}
            </div>
          </div>
          {/* <!-- << table section ---> */}
          <PaymentsView payments={order.payments} invoiced={order.total} />
          <DeliveryView delivery={order.delivery} />
        </div>

        <PaymentEditModal
          order={order}
          calcBalance={this.orderBalance}
          addPayment={this.handleAddPayment}
        />

        <DeliveryModal
          isOpen={this.state.modalIsOpen}
          delivery={this.state.delivery}
          close={this.closeModal}
          save={this.handleSaveDelivery}
          delete={this.deleteDelivery}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const id = props.match.params.id;
  return {
    order: state.orders ? state.orders.find((item) => item.id == id) : initial,
    isAuthenticated: state.isAuthenticated,
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(InvoiceView);

const summary = (payment) => {
  if (payment.confirmation) return payment.confirmation;
  if (payment.check_num) return payment.check_num + " " + payment.bank;
  return "";
};

const toDate = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

class PaymentsView extends Component {
  render() {
    if (!this.props.payments) return "";

    const payments = this.props.payments.sort((a, b) => {
      return a.id - b.id;
    });
    const invoiced = this.props.invoiced;
    const total = payments.reduce(
      (acumulator, item) => (acumulator += Number(item.amount)),
      0
    );
    const balance = toFixed(invoiced) - toFixed(total);
    return (
      <div className="payments mt-5">
        <div className="bg-secondary text-white p-1">
          <h4>Payments</h4>
        </div>
        <div className="table-responsive mt-4">
          <table className="table-hover invoice-table">
            <thead className="thead-light">
              <tr className="text-left">
                <th>#</th>
                <th>Method</th>
                <th>Info</th>
                <th>Date</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr className="item" key={payment.id}>
                  <td>{payment.id}</td>
                  <td>{payment.method.toUpperCase()} </td>
                  <td>{summary(payment)}</td>
                  <td>{toDate(payment.paid_date)}</td>
                  <td>{formatMoney(payment.amount)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="row mx-0 my-3">
            <div className="col-md-6"></div>
            {payments.length > 0 && (
              <div className="bg-lighter p-3 text-right col-md-6">
                <div>
                  <span>TOTAL </span>
                  <span className="ml-3">{formatMoney(invoiced)}</span>
                </div>
                <div>
                  <span>PAYMENTS APPLIED </span>
                  <span className="ml-3">{formatMoney(total)}</span>
                </div>
                <div>
                  <span className="font-weight-light">BALANCE DUE </span>
                  <span className="ml-3 font-weight-bold">
                    {formatMoney(balance)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const DeliveryView = (props) => {
  const delivery = props.delivery || {
    delivered_by: "",
    delivered_at: "",
    received_by: "",
  };
  return (
    <div className="delivery mt-5">
      <div className="bg-secondary text-white p-1">
        <h4>Delivery</h4>
      </div>
      <div className="mt-4"></div>
      <form>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="delivered_by">Delivered by</label>
            <input
              type="text"
              readOnly
              className="form-control"
              name="delivered_by"
              value={delivery.delivered_by || ""}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="received_by">Received by</label>
            <input
              type="text"
              readOnly
              className="form-control"
              name="received_by"
              value={delivery.received_by}
              placeholder=""
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="delivered_at">Date/Time</label>
            <input
              type="text"
              readOnly
              className="form-control"
              value={formatDatetime(delivery.delivered_at)}
              name="delivered_at"
            />
          </div>
        </div>
      </form>
    </div>
  );
};
