// @ts-check
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  importDataFromList,
  uploadImages,
  extractTextFromPDF,
} from "../../services/apiService";
import { fullTotalSQFT, fullTotalSlabs } from "./PkListParser/Utils";
import BundleGroup from "./PkListParser/BundleGroup";
import PackingInfo from "./PkListParser/PackingInfo";
import { showAlert, Spinner } from "../../components/dialogs";
import { GetTextFromPDF } from "./PkListParser/PdfHelper";
import InvoiceReader from "../../services/InvoiceReader";
import FileDropZone from "./PkListParser/FileDropZone";
import { appendSlabsAction } from "../../actions";

class PkListParser extends Component {
  constructor(props) {
    super(props);
    this.parseResult = [];
    this.fileBrowser = null;
    this.filesToUpload = [];
    this.fileBrowserTest = null;
    this.state = {
      dropAreaBgColor: "",
      canReview: false,
      loading: false,
      inputText: "",
      packingList: {
        invoiceNum: "",
        invoiceDate: "",
        receivedAt: "",
        bundles: [],
      },
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: e.target.value }, () => {
      if (value.trim().length > 5) this.parseText();
    });
  };

  handleInputChange = (e) => {
    const name = e.target.name;
    let plist = this.state.packingList;

    plist[name] = e.target.value;
    this.setState({ packingList: plist }, () => this.writeToConsole());
  };

  handleFilenameChanged = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.updateBundleFileName(name, value);
  };

  updateBundleFileName = (name, value) => {
    let plist = this.state.packingList;
    const bundles = plist.bundles.map((bundle) => {
      if (bundle.number === name) {
        return {
          ...bundle,
          filename: value,
        };
      }
      return bundle;
    });

    plist = {
      ...plist,
      bundles: bundles,
    };

    this.setState({ packingList: plist }, () => this.writeToConsole());
  };

  fileSelected = async () => {
    this.showSpinner(true);

    const files = this.fileBrowser.files;
    this.handleUploadImages(files);

    this.showSpinner(false);
  };

  bundleFilesSelected = async (event) => {
    const files = event.target.files;
    await this.processAllFiles(files);
  };

  processAllFiles = async (files) => {
    this.showSpinner(true);
    //process pdf first
    await this.porcessPDFFile(files);

    this.processForImageFilename(files);

    this.showSpinner(false);
  };

  porcessPDFFile = async (files) => {
    const file = this.findPackingListFile(files);
    if (!file) return;

    const fd = new FormData();
    fd.set("file", file, file.name);

    const packingList = await extractTextFromPDF(fd);
    console.log("invoice parsed ", packingList);
    await this.setState({ packingList });
    return packingList;
  };

  findPackingListFile = (files) => {
    let mfile = null;
    for (let file of files) {
      if (file.type === "application/pdf") {
        mfile = file;
        //file starts with PL or the last pdf file
        if (file.name.toLowerCase().startsWith("pl")) return file;
      }
    }
    return mfile;
  };

  convertToBinaryString = async (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      // load event handler
      reader.onload = async (e) => {
        resolve(e.target.result);
      };

      reader.readAsBinaryString(file);
    });
  };

  processForImageFilename = (files) => {
    let bundles = this.state.packingList.bundles;
    // console.log("--bundles ", bundles);
    for (let file of files) {
      if (file.type.includes("image/")) {
        this.filesToUpload.push(file);
        bundles = this.setBundleFilename(file, bundles);
      }
    }

    this.setState({
      packingList: {
        ...this.state.packingList,
        bundles: bundles,
      },
    });
  };

  setBundleFilename = (file, bundles) => {
    const bundleNumsRE = /[_\W](\d{4,})(?=[_\W])/gi;
    const bundleNums = [...file.name.matchAll(bundleNumsRE)];

    const _bundles = bundles.map((bundle) => {
      for (let num of bundleNums) {
        if (bundle.number === num[1]) {
          return {
            ...bundle,
            filename: file.name,
          };
        }
      }
      return bundle;
    });

    return _bundles;
  };

  handleUploadImages = async (files) => {
    const fd = new FormData();
    for (const file of files) {
      fd.append("files[]", file, file.name);
    }
    const resp = await uploadImages(fd);
    if (resp.error) {
      showAlert(resp.message, "error");
    } else {
      showAlert(resp, "success");
    }
  };

  uploadBundlesImages = async () => {
    if (this.filesToUpload.length > 0) {
      // remove duplication
      let uniqueList = this.removeFileDuplication(this.filesToUpload);
      this.handleUploadImages(uniqueList);
    }
  };

  removeFileDuplication = (arr) => {
    let tmp = [];
    for (let file of arr) {
      tmp[file.name] = file;
    }

    const res = Object.keys(tmp).map((k) => tmp[k]);
    return res;
  };

  showSpinner(value) {
    this.setState({ loading: value });
  }

  parseText = () => {
    const packingList = InvoiceReader.parseText(this.state.inputText);
    this.setState({ packingList }, () => this.writeToConsole());
  };

  writeToConsole = () => {
    console.log(JSON.stringify(this.state.packingList));
  };

  importData = async () => {
    this.showSpinner(true);

    const resp = await importDataFromList(this.state.packingList);

    if (resp.error) {
      showAlert(resp.message, "error");
    } else {
      showAlert(resp.count + " Slabs inserted", "success");
      this.props.dispatch(appendSlabsAction(resp.slabs));
      this.setState({ canReview: true });
    }
    //upload images from filename drop event
    await this.uploadBundlesImages();

    this.showSpinner(false);
  };

  handleDropFile = (ev) => {
    ev.preventDefault();

    let file;

    if (ev.dataTransfer.items) {
      if (ev.dataTransfer.items[0].kind === "file") {
        file = ev.dataTransfer.items[0].getAsFile();
      }
    } else {
      file = ev.dataTransfer.files[0];
    }
    if (file) {
      this.filesToUpload.push(file);
      this.updateBundleFileName(ev.target.name, file.name);
    }
  };

  handleDropBundleFiles = (ev) => {
    ev.preventDefault();
    debugger;
    let files = [];

    for (let item of ev.dataTransfer.items) {
      if (item.kind === "file") {
        files.push(item.getAsFile());
      }
    }

    if (files.length > 0) this.processAllFiles(files);
  };

  handleDragEnter = (e) => {
    e.preventDefault();
    this.setState({ dropAreaBgColor: "#F2F9F2" });
  };

  handleDragLeave = (e) => {
    e.preventDefault();
    this.setState({ dropAreaBgColor: "#F2F9F2" });
  };

  extractDataFromText(str) {
    return InvoiceReader.parse(str);
  }

  reviewSlabs = () => {
    let ids = "";
    for (let bundle of this.state.packingList.bundles) {
      let slab_numbs = "";
      for (let slab of bundle.slabs) {
        slab_numbs += slab.slab_num + ",";
      }
      ids += slab_numbs;
    }
    this.props.history.replace(`/slabs?select=${ids}`);
  };

  render() {
    const bundles = this.state.packingList.bundles || [];
    const sqftSum = fullTotalSQFT(bundles);
    const hasBundles = bundles.length > 0;

    return (
      <div className="page-div">
        <Spinner isVisible={this.state.loading} />
        <div className="container">
          <h3>Packing List Parser</h3>
          <div className="row my-3">
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => this.fileBrowser.click()}
            >
              Upload Slab Images
            </button>
            <input
              className="d-none"
              type="file"
              multiple
              accept="image/*"
              ref={(me) => (this.fileBrowser = me)}
              onChange={this.fileSelected}
            />
            {hasBundles && (
              <button
                className="btn btn-primary mr-2"
                onClick={this.importData}
              >
                Import Data
              </button>
            )}

            {this.state.canReview && (
              <button
                type="button"
                className="btn btn-secondary mr-2"
                onClick={this.reviewSlabs}
              >
                Review
              </button>
            )}
          </div>
          {/* <!-- packing list review --> */}
          {hasBundles && (
            <PackingInfo
              InputChange={this.handleInputChange}
              PackingList={this.state.packingList}
            />
          )}
          {/* <!-- bundles groups --> */}
          <div>
            {bundles.map((bundle, i) => (
              <BundleGroup
                key={bundle.number}
                bundle={bundle}
                dropped={this.handleDropFile}
                filenameChanged={this.handleFilenameChanged}
              />
            ))}

            {/* --- summary table  -- */}
            {this.SummaryTable(bundles, sqftSum)}
          </div>
          <FileDropZone process={this.processAllFiles} />
          {/* <!-- input textarea --> */}
          <div className="form-group">
            <label htmlFor="query">Raw Text Area</label>
            <textarea
              className="form-control"
              name="inputText"
              value={this.state.inputText}
              onChange={this.handleChange}
              rows={15}
            ></textarea>
          </div>
        </div>
      </div>
    );
  }

  SummaryTable(bundles, sqftSum) {
    return (
      bundles.length > 0 && (
        <div className="bg-light">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Total Slab</th>
                <th className="fullTotalSlab">{fullTotalSlabs(bundles)}</th>
                <th scope="col">Total SQFT</th>
                <th className="fullTotalSqft" scope="col">
                  {sqftSum}
                </th>
              </tr>
            </thead>
          </table>
        </div>
      )
    );
  }
}

export default connect()(PkListParser);
