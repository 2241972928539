/*eslint eqeqeq: off */
import React, { Component } from "react";
import ReactPlaceholder from "react-placeholder";
import { connect } from "react-redux";
import Modal from "react-modal";
import $ from "jquery";
//import * as clipboard from "clipboard-polyfill"
import { PriceItemPlaceholder } from "./services/placeholders";
import { fetchPrices, tidyCode } from "./services/apiService";
import MailingListTbItem from "./MailingTbItem";
import { pricesFetchedAction } from "./actions/index";
import Auth from "./Auth/Auth";
import { Spinner } from "./components/dialogs";

var promo_expiration = null;

const auth = new Auth();

class MailingListTb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      loading: false,
      isModalOpen: false,
    };
  }

  async componentDidMount() {
    if (!this.props.hasPrices) {
      const data = await fetchPrices();
      this.props.dispatch(pricesFetchedAction(data));
      this.setState({ ready: true });
    } else {
      this.setState({ ready: true });
    }
  }

  handleTemplate = async () => {
    let body = $(".page-div").html();
    body = this.removeTag(body);
    body = body.replace("Promotional Pricing Sheet", "");
    const page =
      "<!DOCTYPE html><html><head></head><body style='font-family:Helvetica, Arial, sans-serif; padding:0px;'><div style='background-color:white;padding:20px'>" +
      body +
      "</div></body></html>";
    this.showSpinner();
    const result = await tidyCode({ code: page });

    console.log(page);
    // clipboard.writeText(result);

    this.setState({ code: result, isModalOpen: true });
    this.hideSpinner();
  };

  hideCode = () => {
    this.setState({ isModalOpen: false });
  };

  removeTag = (text) => {
    var regex = /<button.*?>.*?<\/button>/gi;
    return text.replace(regex, "");
  };

  showSpinner = () => {
    this.setState({ loading: true });
  };

  hideSpinner = () => {
    this.setState({ loading: false });
  };

  render() {
    // promo_expiration = (this.props.prices[0])? this.props.prices[0].expiration: null;
    const display = this.props.prices.map((row) => (
      <MailingListTbItem key={row.id} slab={row} />
    ));
    return (
      <div className="page-div">
        <Spinner isVisible={this.state.loading} />
        {auth.isAuthenticated() && (
          <button
            className="btn btn-outline-secondary"
            onClick={this.handleTemplate}
          >
            <i className="fa fa-code"></i>
          </button>
        )}
        <div className="container">
          <h1>GRANITE WORLD LLC</h1>
          <h3>Promotional Pricing Sheet</h3>
          <ReactPlaceholder
            ready={this.state.ready}
            customPlaceholder={PriceItemPlaceholder}
            showLoadingAnimation
          >
            <div style={{ margin: "60px 20px 1px 20px", paddingBottom: "1px" }}>
              <table
                className="table-responsive"
                style={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 10px",
                }}
              >
                <tbody>{display}</tbody>
              </table>
            </div>
          </ReactPlaceholder>
          <div style={{ margin: "10px 20px" }}>
            *Valid only for a limited time {promo_expiration} <p />
            <b>We offer free delivery</b> <br />
            Phone: <a href="tel:801-687-9500">801-687-9500</a>
            <br />
            Website:{" "}
            <a href="http://www.graniteworldutah.com">
              www.graniteworldutah.com
            </a>
            <br />
          </div>
        </div>
        <CodeView
          code={this.state.code}
          isOpen={this.state.isModalOpen}
          close={this.hideCode}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  prices: state.prices ? state.prices.filter((d) => d.active == 1) : [],
});

export default connect(mapStateToProps)(MailingListTb);

const CodeView = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      closeTimeoutMS={300}
      className="modal-panel"
      contentLabel="Date Modal"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editorDialogLabel">
              Source
            </h5>
            <button type="button" className="close" onClick={props.close}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <textarea className="form-control code-view" rows="21">
              {props.code}
            </textarea>
          </div>
        </div>
      </div>
    </Modal>
  );
};
