// @ts-check
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchCustomers,
  updateCustomer,
  createCustomer,
  deleteCustomer,
} from "./../services/apiService";
import {
  fetchedCustomerAction,
  updateCustomerAction,
  deleteCustomersAction,
  createCustomersAction,
} from "../actions";
import { showAlert, showDeleteConfirmation } from "../components/dialogs";
import CustomerEdit from "./CustomerEdit";
import lowerCase from "lodash.lowercase";

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      selectedItem: {},
      loading: true,
      search: "",
      sortColumn: "company",
    };
  }

  async componentDidMount() {
    if (!this.props.customers) {
      const data = await fetchCustomers();
      this.props.dispatch(fetchedCustomerAction(data));
      this.setState({ ready: true, loading: false });
    } else {
      this.setState({ ready: true, loading: false });
    }
  }

  updateSelectedItem = (selectedItem) => {
    this.setState({ selectedItem });
  };

  handleCreate = async (item, file) => {
    this.showSpinner();

    const res = await createCustomer(item);

    if (!res.error) {
      this.props.dispatch(createCustomersAction(res));
      showAlert("Customer was created", "success");
    } else {
      showAlert(res.message, "error");
    }
    this.hideSpinner();
  };

  handleUpdate = async (item, file) => {
    this.showSpinner();

    const res = await updateCustomer(item);

    if (!res.error) {
      this.props.dispatch(updateCustomerAction(res));
      showAlert("Customer updated", "success");
    } else {
      showAlert(res.message, "error");
      console.log(res.message);
    }
    this.setState({ selectedItem: {} });
    this.hideSpinner();
  };

  handleDelete = async (id) => {
    showDeleteConfirmation(async () => {
      this.showSpinner();
      const res = await deleteCustomer(id);

      if (!res.error) {
        this.props.dispatch(deleteCustomersAction(res));
        showAlert("Customers deleted", "success");
      } else {
        showAlert(res.message, "error");
      }

      this.hideSpinner();
    });
  };

  showSpinner = () => {
    this.setState({ loading: true });
  };

  hideSpinner = () => {
    this.setState({ loading: false });
  };

  handleShowError = (msg) => {
    showAlert(msg, "error");
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({ [name]: e.target.value });
  };

  filter = (data, search) => {
    if (data) {
      const filtered = data.filter((item) => {
        const lowerSearch = lowerCase(search);
        return (
          lowerCase(item.name).includes(lowerSearch) ||
          lowerCase(item.company).includes(lowerSearch)
        );
      });
      return filtered.sort(this.compare);
    }
    return [];
  };

  compare = (a, b) => {
    const column = this.state.sortColumn;
    const avalue = a[column] || "";
    const bvalue = b[column] || "";
    return avalue.localeCompare(bvalue);
  };

  render() {
    const customers = this.filter(this.props.customers, this.state.search);

    return (
      <div className="page-div">
        <div className="container">
          {this.state.loading && <div className="spinner"></div>}
          <div className="row mb-5 inline-form">
            <div className="col-sm-2">
              <button
                className="btn btn-outline-secondary"
                data-toggle="modal"
                data-target="#editorDialog"
                onClick={() => this.updateSelectedItem({})}
              >
                New Customer
              </button>
            </div>
            <div className="col-sm-3">
              <input
                type="search"
                name="search"
                className="form-control"
                placeholder="Search"
                onChange={this.handleChange}
                value={this.state.search}
              />
            </div>
            <div className="col-sm-4 form-inline">
              <label className="mr-2">Sort By:</label>
              <select
                className="form-control"
                name="sortColumn"
                value={this.state.sortColumn}
                onChange={this.handleChange}
              >
                <option value="company">Company</option>
                <option value="name">Contact</option>
              </select>
            </div>
          </div>
          {customers.length === 0 ? (
            <div>List is empty</div>
          ) : (
            <div className="row">
              {customers.map((item) => (
                <div className="card col-md-4 m-2" key={item.id}>
                  <div className="card-body">
                    <span className="pull-right">
                      <button
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => this.handleDelete(item.id)}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                      <button
                        className="btn  btn-outline-primary btn-sm ml-2"
                        data-toggle="modal"
                        data-target="#editorDialog"
                        onClick={() => this.updateSelectedItem(item)}
                      >
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                    </span>
                    <h5 className="card-title text-left">{item.company}</h5>
                    <div className="card-text font-italic">{item.name}</div>
                    <div className="card-text">{item.street}</div>
                    <div className="card-text">
                      {item.city}, {item.state} {item.zip}
                    </div>
                    <div>{item.email}</div>
                    <div>{item.phone}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <CustomerEdit
          customer={this.state.selectedItem}
          updateItem={this.handleUpdate}
          showErrors={this.handleShowError}
          createItem={this.handleCreate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.customers,
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(CustomerList);
