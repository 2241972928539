import React, { useState, useEffect, useCallback } from 'react'
import Modal  from 'react-modal';
import DatePicker from 'react-datepicker';
import moment from 'moment';


const DeliveryModal = (props) => {
    const deliv = props.delivery || {};
    const [delivery, setDelivery] = useState({});
    const [deliveredAt, setDeliveredAt] = useState(new Date());

    const initialize = useCallback(() => {
        // console.log("initialize ", deliv);
        const delivered_time = moment(deliv.delivered_at).format('h:mm a');
        const delivered_at = moment(deliv.delivered_at, 'YYYY-MM-DD').toDate();

        setDelivery({
            ...deliv,
            delivered_by: deliv.delivered_by,
            received_by: deliv.received_by,
            delivered_at: delivered_at,
            delivered_time: delivered_time
        });

        setDeliveredAt(delivered_at);
    });
 
    useEffect(() => {
        initialize();
    }, [props.isOpen, ]);

    const handleSave= () => {
        props.save(delivery);
    }

    const handleDelete = () => {
        props.delete(delivery);
    }

    const handleDateChange = (date) => {
        setDelivery({
            ...delivery,
            delivered_at: date
        });
        setDeliveredAt(date);
    }

    const handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        setDelivery({
            ...delivery,
            [name]: value
        });
    }

    const parseDate = (date) => {
        if(date) {
            const _date = new Date(date);
            if(isNaN(_date)) return null;
            return _date;
        }
        return null;
    }

    const parseTime = (date)  => {
        if(date) {
            return moment(date).format('h:mm a');
        }
        return '0:00';
    }

    return (
        <Modal isOpen={props.isOpen}
        closeTimeoutMS={300}
        className='modal-panel'
        contentLabel="Date Modal">
        <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <h5 className="modal-title" id="editorDialogLabel">Update Delivery</h5>
                      <button type="button" className="close" onClick={props.close}>
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div className="modal-body">
                  <div className="form-horizontal" >
                        <div className="form-group form-row">
                          <label className="col-md-3 col-form-label">Delivered by</label>
                          <div className="col-md-6">
                              <input
                              className="form-control" 
                              name="delivered_by" 
                              value={delivery.delivered_by} 
                              onChange={handleChange} />
                          </div>
                      </div> 
                      <div className="form-group form-row">
                          <label className="col-md-3 col-form-label">Received by</label>
                          <div className="col-md-6">
                              <input
                              className="form-control" 
                              name="received_by" 
                              value={delivery.received_by} 
                              onChange={handleChange} />
                          </div>
                      </div> 
                      <div className="form-group form-row">
                          <label className="col-md-3 col-form-label">Date</label>
                          <div className="col-md-3">
                            <DatePicker 
                              className="form-control" 
                              name="delivered_at" 
                              selected={deliveredAt} 
                              onChange={handleDateChange}

                              />
                          </div>
                      </div> 
                      <div className="form-group form-row">
                          <label className="col-md-3 col-form-label">Time</label>
                          <div className="col-md-3">
                            <input 
                              className="form-control" 
                              name="delivered_time" 
                              value={delivery.delivered_time} 
                              onChange={handleChange}
                              />
                          </div>
                      </div> 
                  </div>
                  </div>
                  <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={props.close}>Close</button>
                      <button type="button" className="btn btn-primary" onClick={ handleSave }>Save</button>
                      <button type="button" className="btn btn-danger" onClick={ handleDelete }>Delete Delivery</button>
                  </div>
              </div>
          </div>
        </Modal>
    );
}

export default DeliveryModal;