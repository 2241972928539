// @ts-check
import Noty from 'noty';
import React from 'react'


export const showDeleteConfirmation = (callback) => {
    const n = new Noty({
      type: 'alert',
      theme: 'metroui',
      layout: 'topCenter',
      closeWith: ['button'],
      modal: true,
      text: "<div style=''>Do you want to delete item?</div>",
      buttons: [
        Noty.button('Yes', 'btn btn-danger btn-gap', () => {
          callback('money');
          n.close();
        }, {id: 'button1', 'data-status': 'ok'}
        ),
    
        Noty.button('No', 'btn btn-default ml-2', function () {
            n.close();
        })
      ]
    });
    n.show();
  }

  export const showNotification = (data) => {
    const n = new Noty({
      type: 'alert',
      theme: 'metroui',
      layout: 'topRight',
      closeWith: ['button'],
      modal: true,
      text: `<div style="width:38rem;display:flex;font-family:sans-serif;line-height:1.5">
              <div class="mt-2" >
              <img style="width:40px" src="http://graniteworldutah.com/logo_150.png"/>
              </div>
              <div class="mx-3 my-1">
              <div class="font-weight-bold">Granite World</div>
              <div>${data.notification.body}</div>
              <small><a style="color:gray" href="${data.notification.click_action}">${data.notification.click_action}</a></small>
              </div></div>`
    });
    n.show();
  }

  /**
   * @param {string} message 
   * @param {*} type 
   * @param {number} time 
   */
  export const showAlert = (message, type='alert', time=2000) => {
    // https://ned.im/noty/#/
    // type: [alert, success, warning, error, info] https://ned.im/noty/#/options 
    // theme: [mint sunset relax nest metroui semanticui light bootstrap-v3 bootstrap-v4]
    if(message.indexOf('code 401') !== -1)
    {
        message += ' (Login Required)';
    }
    const n = new Noty({
      type: type,
      theme: 'metroui',
      layout: 'topCenter',
      closeWith: ['click'],

      modal: false,
      text: "<div style=''>"+message+"</div>",
      buttons: [
      ],
      timeout: time
    });
    n.show();
  }

  export const showCode = (message, time=0) => {
    const n = new Noty({
      type: 'alert',
      theme: 'metroui',
      layout: 'topCenter',
      closeWith: ['button'],

      modal: true,
      text: "<textarea style='min-height:500px; width:500px'>"+message+"</textarea>",
      buttons: [
      ],
      timeout: time,
    });
    n.show();
  }

  /**
   * 
   * @param {Object} props 
   * @returns {any}
   */
  export const Spinner = ({isVisible}) => {
    return (isVisible)? <div className='spinner'></div>: '';
  }
  