/*eslint eqeqeq: "off"*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import Noty from "noty";
import ReactPlaceholder from "react-placeholder";

import { CONSTS, PROMO_DATES } from "./constants";
import {
  deletePrice,
  fetchPrices,
  updatePrice,
  setSetting,
  getSetting,
} from "./services/apiService";
import { PriceItemPlaceholder } from "./services/placeholders";
import { formatMoney, stringToDate, dateToString } from "./services/utils";
import {
  updatePriceAction,
  pricesFetchedAction,
  deletePriceAction,
} from "./actions/index";
import { showAlert, Spinner } from "./components/dialogs";

import "../node_modules/noty/lib/noty.css";
import "../node_modules/noty/lib/themes/metroui.css";
import Sortable from "react-sortablejs/lib/Sortable";

Modal.setAppElement("#root");

class PriceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      loading: false,
      error: false,
      modalIsOpen: false,
      startDate: null,
      endDate: null,
      selectAll: true,
      search: "",
      selectAllEnable: true,
    };
  }

  async componentDidMount() {
    const resp = await getSetting(PROMO_DATES);
    this.processResult(resp);

    if (!this.props.prices) {
      const data = await fetchPrices();
      if (!data) this.setState({ error: true });
      this.props.dispatch(pricesFetchedAction(data));

      this.setState({ ready: true });
    } else {
      this.setState({ ready: true });
    }
  }

  editPrice(slab) {
    this.props.history.push("/prices/edit/" + slab.id);
  }

  showDeleteConfirmation(event, slab) {
    event.preventDefault();

    const n = new Noty({
      type: "alert",
      theme: "metroui",
      layout: "topCenter",
      closeWith: ["button"],
      modal: true,
      text: "<div style=''>Do you want to delete item?</div>",
      buttons: [
        Noty.button(
          "Yes",
          "btn btn-danger btn-gap",
          () => {
            this.deletePrice(slab);
            n.close();
          },
          { id: "button1", "data-status": "ok" }
        ),

        Noty.button("No", "btn btn-default btn-gap", function() {
          n.close();
        }),
      ],
    });
    n.show();
  }

  deletePrice = async (slab) => {
    this.showSpinner();

    const resp = await deletePrice(slab);
    if (resp) {
      this.props.dispatch(deletePriceAction(slab.id));
    } else {
      showAlert("Error trying delete item", "error");
    }

    this.hideSpinner();
  };

  handleActiveChange = async (event, item) => {
    item.active = event.target.checked;
    //change value right way
    this.props.dispatch(updatePriceAction(item));
    // update server
    await updatePrice(item);
    // if(data) {
    //   this.props.dispatch(updatePriceAction(data));
    // }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSettingsSave = async () => {
    this.closeModal();
    this.showSpinner();

    const data = {
      startDate: dateToString(this.state.startDate),
      endDate: dateToString(this.state.endDate),
    };

    const resp = await setSetting({
      name: PROMO_DATES,
      value: JSON.stringify(data),
    });

    if (resp) {
      this.processResult(resp);

      const data = { [resp.name]: resp.value };
      this.props.dispatch({
        type: "UPDATE_SETTINGS",
        updates: data,
      });

      showAlert("Date saved!", "success");
    }

    this.hideSpinner();
  };

  processResult = (resp) => {
    const data = JSON.parse(resp.value);
    this.setState({
      startDate: stringToDate(data.startDate),
      endDate: stringToDate(data.endDate),
    });
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  showSpinner() {
    this.setState({ loading: true });
  }

  hideSpinner() {
    this.setState({ loading: false });
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleSelectAll = async () => {
    const selected = !this.state.selectAll;

    this.setState({ selectAll: selected, selectAllEnable: false });
    //no changes, don't do anything
    if (!!this.props.prices[0].active === selected) {
      this.setState({ selectAllEnable: true });
      return;
    }

    let prices = this.props.prices.map((price) => {
      return {
        ...price,
        active: selected,
      };
    });

    this.props.dispatch(pricesFetchedAction(prices));
    await this.updatePrices(prices);
    this.setState({ selectAllEnable: true });
  };

  updatePrices = async (prices) => {
    const data = await updatePrice(prices);
    if (data) {
      this.props.dispatch(pricesFetchedAction(data));
    }
  };

  onSortableChange = async (orderedIds) => {
    console.log("sortable ", orderedIds);
    let data = orderedIds.map((id, index) => {
      const price = this.props.prices.find((_price) => _price.id == id);
      return {
        ...price,
        rank: index + 1,
      };
    });
    this.props.dispatch(pricesFetchedAction(data));
    // update  database then update local
    data = await updatePrice(data);
    this.props.dispatch(pricesFetchedAction(data));
  };

  filter = (data, search) => {
    if (data) {
      const filtered = data.filter((item) =>
        item.material.toLowerCase().includes(search.toLowerCase())
      );
      return filtered;
    }
    return [];
  };

  render() {
    //todo create itemList component
    let filtered = this.filter(this.props.prices, this.state.search);

    return (
      <div className="page-div">
        <Spinner isVisible={this.state.loading} />
        <div className="container">
          {this.state.error ? (
            <div
              style={{
                color: "#ccc",
                textAlign: "center",
                width: "100%",
                margin: 20,
              }}
            >
              Error trying to retrieve data
            </div>
          ) : this.props.prices && this.props.prices.length === 0 ? (
            <h4 style={{ color: "gray" }}>List is empty</h4>
          ) : (
            ""
          )}
          <h3>Prices</h3>
          <ReactPlaceholder
            ready={this.state.ready}
            customPlaceholder={PriceItemPlaceholder}
            showLoadingAnimation
          >
            <div>
              <div className="row m-1">
                <Link to="prices/edit/0" className="btn btn-outline-primary">
                  Add New Item
                </Link>
                <button
                  className="btn btn-outline-secondary mx-2"
                  onClick={this.openModal}
                >
                  Set Dates
                </button>
                <input
                  type="search"
                  className="form-control col-sm-3"
                  name="search"
                  placeholder="Search"
                  value={this.state.search}
                  onChange={this.handleChange}
                ></input>
              </div>

              <div className="table-respo">
                <div className="d-flex mt-2">
                  <div className="mr-2">
                    <input
                      type="checkbox"
                      className=""
                      disabled={!this.state.selectAllEnable}
                      name="selectall"
                      checked={this.state.selectAll}
                      onChange={(e) => this.handleSelectAll()}
                    />
                  </div>
                  <div className="">
                    {this.state.selectAll ? "unselect" : "select"} all
                  </div>
                </div>
                <div className="price-list-container">
                  <Sortable
                    className="col-sm-10"
                    // Sortable options (https://github.com/RubaXa/Sortable#options)
                    options={{
                      animation: 500,
                      dragClass: "sort-ghost-look",
                      handle: ".sort-handle",
                    }}
                    ref={(c) => {
                      if (c) {
                        this.sortable = c.sortable;
                      }
                    }}
                    onChange={(orderedIds) => this.onSortableChange(orderedIds)}
                  >
                    {filtered.map((row) => (
                      <PriceListItem
                        key={row.id}
                        item={row}
                        deleteItem={(e, item) =>
                          this.showDeleteConfirmation(e, item)
                        }
                        onActiveChange={(e, item) =>
                          this.handleActiveChange(e, item)
                        }
                      />
                    ))}
                  </Sortable>
                </div>
              </div>
            </div>
          </ReactPlaceholder>

          <Modal
            isOpen={this.state.modalIsOpen}
            className="modal-panel"
            contentLabel="Date Modal"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="editorDialogLabel">
                    Set Dates
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={this.closeModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-horizontal">
                    <div className="form-group form-row">
                      <label className="col-md-3 col-form-label">Start</label>
                      <div className="col-md-6">
                        <DatePicker
                          className="form-control"
                          name="endDate"
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange}
                        />
                      </div>
                    </div>
                    <div className="form-group form-row">
                      <label className="col-md-3 col-form-label">End</label>
                      <div className="col-md-6">
                        <DatePicker
                          className="form-control"
                          name="endDate"
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.closeModal}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSettingsSave}
                  >
                    Save
                  </button>
                  :
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const PriceListItem = (props) => {
  const { item, deleteItem, onActiveChange } = props;

  return (
    <div className="price-card" data-id={item.id}>
      <div className="px-2 pt-2">
        <input
          type="checkbox"
          className="text-center"
          name="active"
          checked={item.active}
          onChange={(e) => onActiveChange(e, item)}
        />
      </div>
      <div>
        <a href={CONSTS.imgBaseUrl + "thumbnails/" + item.filename}>
          <img
            className="price-card-image"
            src={CONSTS.imgBaseUrl + "thumbnails/" + item.filename}
            alt={"image" + item.id}
          />
        </a>
      </div>

      <div className="price-card-description">
        <div className="sort-handle">
          <span style={{ fontSize: "xx-large" }}>
            {item.material}
            {item.limited > 0 && "*"}
          </span>
        </div>
        <div style={{ fontSize: "large", margin: "5px 0" }}>
          <span style={{ margin: "0px" }}>Unit Price: </span>
          <span
            style={{
              fontWeight: "bold",
              color: item.limited > 0 ? "#FF4136" : "black",
            }}
          >
            {formatMoney(item.unit_price, 2, "")}
          </span>
        </div>
        <div style={{ fontSize: "large", margin: "5px 0" }}>
          <span style={{ margin: "0px" }}>Bundle Price: </span>
          <span
            style={{
              fontWeight: "bold",
              color: item.limited > 0 ? "#FF4136" : "black",
            }}
          >
            {formatMoney(item.bundle_price, 2, "")}
          </span>
        </div>
      </div>

      <div className="price-card-tools">
        <Link to={`/prices/edit/${item.id}`}>
          <i className="fa fa-pencil"></i>
        </Link>
        <button
          className="link-button text-danger ml-3"
          onClick={(e) => deleteItem(e, item)}
        >
          <i className="fa fa-trash"></i>
        </button>
        <span className="sort-handle ml-4">
          <i className="fa fa-ellipsis-v"></i>
        </span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  prices: state.prices,
  settings: state.settings,
});

export default connect(mapStateToProps)(PriceList);
