
export default function customers (state = null, action) {
    switch (action.type) {
        case 'ADD_CUSTOMER':
            const _state = state || [];
            return _state.concat(action.customer);

        case 'UPDATE_CUSTOMER':
            const customers = state.map((item, index) => {
                if (parseInt(item.id) !== parseInt(action.updates.id)) { 
                    // This isn't the item we care about - keep it as-is
                    return item;
                }
                // Otherwise, this is the one we want - return an updated value
                return {
                    ...item,
                    ...action.updates
                }
            });

            return customers;
        case 'DELETE_CUSTOMER':
            return state.filter(({id}) => id !== action.id);

        case 'CUSTOMERS_FETCHED':
            return action.payload;
        default: 
            return state;
    } 
}