//@ts-check
import React, { Component } from 'react'
import Modal from 'react-modal';



const initialState = {
  name: "",
  description: "",
  amount: "",
  errorName: false,
  errorAmount: false
};

export default class AddDiscountDlg extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
        [name]: value
    });
  }
  
  handleClose = () => {
    this.setState(initialState);
    this.props.close(false);
  }

  handleSave = () => {
    //validade fields first
    const isValid = this.validateForm();
    console.log("is valid? ", isValid);
    if(!isValid) return;

    const {name, amount, description} = this.state;
    const discount = {
      name: name,
      description: description,
      amount: amount,
    }

    this.setState(initialState);
    this.props.save(discount);
    return
  }

  validateForm = () => {
    const noAmount = isNaN(parseFloat(this.state.amount));
    const noName = (this.state.name.length < 3) || (this.state.name.length > 15);

      this.setState({
        errorAmount: noAmount,
        errorName: noName
      });
    return !(noAmount || noName);
  }

  render() {
    return (
        <Modal isOpen={this.props.isOpen}
        closeTimeoutMS={300}
        className='modal-panel'
        contentLabel="Discount Dialog">
        <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <h5 className="modal-title" id="editorDialogLabel">Add Discount</h5>
                      <button type="button" className="close" onClick={this.props.close}>
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div className="modal-body">
                  <div className="form-horizontal" >
                        <div className="form-group form-row">
                          <label className="col-md-3 col-form-label">Name</label>
                          <div className="col-md-4">
                              <input
                              className="form-control" 
                              name="name" 
                              placeholder='summer promo'
                              value={this.state.name } 
                              onChange={this.handleChange} />
                            { this.state.errorName &&  <small style={{color: 'red'}}>Must be 3-15 characters</small> }
                          </div>
                      </div> 
                      <div className="form-group form-row">
                          <label className="col-md-3 col-form-label">Description</label>
                          <div className="col-md-7">
                              <input
                              className="form-control" 
                              placeholder="for customer buying 4+ slabs"
                              name="description" 
                              value={this.state.description} 
                              onChange={this.handleChange} />
                          </div>
                      </div> 
                      <div className="form-group form-row">
                          <label className="col-md-3 col-form-label">Amount</label>
                          <div className="col-md-4">
                            <input 
                              className="form-control" 
                              placeholder="200"
                              name="amount" 
                              value={this.state.amount} 
                              onChange={this.handleChange}
                              />
                              { this.state.errorAmount && <small style={{color: 'red'}}>Must be a number</small> }
                          </div>
                      </div> 
                  </div>
                  </div>
                  <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={ () => this.handleClose()}>Close</button>
                      <button type="button" className="btn btn-primary" onClick={ () => this.handleSave() }>Save</button>
                  </div>   
              </div>
          </div>
        </Modal>
    );
  }
}
