import React from "react";
import { connect } from "react-redux";
import memoize from "memoize-one";
import { AutoSizer, List, WindowScroller } from "react-virtualized";
// import ImageZoom from 'react-medium-image-zoom'
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import {
  fetchSlabs,
  updateSlab,
  createSlab,
  deleteSlab,
  uploadImage,
  addToCart,
  fetchSelectSlabs,
} from "./services/apiService";
import { CONSTS } from "./constants";
import Auth from "./Auth/Auth";
import SlabEditDialog from "./components/SlabEditDialog";
import {
  updateSlabAction,
  createSlabAction,
  deleteSlabAction,
  slabsFetchedAction,
  updateCartAction,
} from "./actions/index";
import { showAlert, showDeleteConfirmation } from "./components/dialogs";
import { toFixed, calcPrice, unitPrice, bundlePrice } from "./services/utils";

const divStyle = {
  marginTop: "80px",
};

const auth = new Auth();
const ITEM_WIDTH = 310;
const ITEM_PADDING = 5;
const ITEM_HEIGHT = auth.isAuthenticated() ? 623 : 452;
const theme_unit = 5;

const styles = {
  grid: {
    marginTop: theme_unit,
    marginBottom: theme_unit,
    justifyContent: "center",
  },
  gridItem: {
    padding: 0,
  },
  card: {
    height: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  msg: {
    color: "#ccc",
    textAlign: "center",
    width: "100%",
    margin: 20,
  },
};

const getRowCount = (width, itemsAmount) => {
  const maxItemsPerRow = getMaxItemsPerRow(width);
  return Math.ceil(itemsAmount / maxItemsPerRow);
};

const getMaxItemsPerRow = (width) => {
  return Math.max(Math.floor(width / (ITEM_WIDTH + ITEM_PADDING)), 1);
};

const generateIndexesForRow = (rowIndex, maxItemsPerRow, itemsAmount) => {
  const result = [];
  const startIndex = rowIndex * maxItemsPerRow;

  for (
    let i = startIndex;
    i < Math.min(startIndex + maxItemsPerRow, itemsAmount);
    i++
  ) {
    result.push(i);
  }

  return result;
};

const RowItem = React.memo(function RowItem(props) {
  return (
    <div style={styles.gridItem}>
      <SlabItem
        slab={props.slab}
        addToCart={props.addToCart}
        changeItem={props.changeItem}
        deleteItem={props.deleteItem}
      />
    </div>
  );
});

class SlabList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      selectedSlab: {},
      loading: false,
      error: false,
    };
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const select = (query.get("select") || "").replace(/\s/g, "");
    if (select.length > 0) {
      this.showSpinner();
      const data = await fetchSelectSlabs(select);
      this.props.dispatch(slabsFetchedAction(data));
      this.setState({ ready: true, error: data == null });
    }
    if (!this.props.slabs) {
      this.showSpinner();
      const start = new Date().getTime();
      const data = (await fetchSlabs()) || [];
      console.log(
        "data slabs",
        data.length,
        (new Date().getTime() - start) / 1000
      );
      this.props.dispatch(slabsFetchedAction(data));
      const _slabs = this.props.slabs || [];
      console.log(
        "props slabs",
        _slabs.length,
        (new Date().getTime() - start) / 1000
      );
      this.setState({ ready: true, error: data == null });
      console.log("done slabs", (new Date().getTime() - start) / 1000);
    } else {
      this.setState({ ready: true });
    }
    this.hideSpinner();
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({ [name]: value });
  };

  replaceModalItem = (slab) => {
    this.setState({ selectedSlab: slab });
  };

  handleCreate = async (slab) => {
    this.showSpinner();
    await this.uploadImage(slab);
    const res = await createSlab(slab);

    if (res) {
      this.props.dispatch(createSlabAction(res));
      showAlert("Slab created", "success");
    }
    this.hideSpinner();
  };

  handleUpdate = async (slab) => {
    this.showSpinner();
    await this.uploadImage(slab);
    const res = await updateSlab(slab);

    if (!res.error) {
      this.props.dispatch(updateSlabAction(res));
      showAlert("Slab updated", "success");
    } else {
      showAlert(res.message, "error");
      console.log(res.message);
    }

    this.hideSpinner();
  };

  async uploadImage(slab) {
    let image = slab.fileToUpload;
    if (image) {
      let file = await uploadImage(image, "");
      slab.filename = file.filename;
      delete slab.imageToUpload;

      return;
    }
  }

  handleDelete = async (id) => {
    showDeleteConfirmation(async () => {
      this.showSpinner();
      const res = await deleteSlab(id);

      if (!res.error) {
        this.props.dispatch(deleteSlabAction(res));
        showAlert("Slab deleted", "success");
      } else {
        showAlert(res.message, "error");
      }

      this.hideSpinner();
    });
  };

  handleAddToCart = (slab) => {
    const cart = addToCart(slab);

    if (cart) {
      this.props.dispatch(updateCartAction(cart));
      slab.reserved = this.timestamp();
      updateSlab(slab);
      //slab.onHold = true;
      this.props.dispatch(updateSlabAction(slab));
    }
  };

  noRowsRenderer = (p) =>
    this.state.loading ? (
      ""
    ) : (
      <div style={styles.msg}>No slab found{JSON.stringify(p)}</div>
    );

  timestamp = () => {
    return new Date().getTime();
  };

  showSpinner() {
    this.setState({ loading: true });
  }

  hideSpinner() {
    this.setState({ loading: false });
  }

  filter = memoize((list, filterText) =>
    list.filter((slab) => {
      const material = slab.material || "";
      const slab_num = slab.slab_num || "";
      return (
        material.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
        slab_num.startsWith(filterText)
      );
    })
  );

  reservedFilter = (list, filterText) => {
    let res = filterText.split(":");
    if (res[1]) {
      return list.filter(
        (s) =>
          s.reserved &&
          s.reserved.toLowerCase().startsWith(res[1].toLowerCase())
      );
    }
    return list.filter((s) => s.reserved);
  };

  render() {
    let filtered = [];
    if (this.props.slabs) {
      filtered = this.state.search.startsWith("reserved:")
        ? this.reservedFilter(this.props.slabs, this.state.search)
        : this.filter(this.props.slabs, this.state.search);
    }

    return (
      <div className="container" style={divStyle}>
        {this.state.loading && <div className="spinner"></div>}
        <div className="row">
          <form className="form-inline ml-lg-4 col-sm-4">
            <label className="sr-only" htmlFor="search">
              Search
            </label>
            <input
              type="search"
              className="form-control mb-2 mr-sm-2 mb-sm-0"
              name="search"
              placeholder="Search"
              value={this.state.search}
              onChange={this.handleChange}
            ></input>
          </form>
          {auth.isAuthenticated() && (
            <span className="col-sm-6">
              <button
                className="btn btn-secondary"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => this.replaceModalItem({})}
              >
                New Slab
              </button>
              <button className="btn btn-info ml-2" disabled>
                {filtered.length}
              </button>
            </span>
          )}
        </div>
        <div className="row">
          {this.state.error ? (
            <div style={styles.msg}>Error trying to retrieve data</div>
          ) : (
            // virtualized list <
            <AutoSizer disableHeight>
              {({ width }) => {
                const slabs = filtered;
                const rowCount = getRowCount(width, slabs.length);

                return (
                  <WindowScroller>
                    {({ height, scrollTop }) => (
                      <List
                        style={styles.grid}
                        autoHeight
                        height={height}
                        scrollTop={scrollTop}
                        width={width}
                        rowCount={rowCount}
                        rowHeight={ITEM_HEIGHT}
                        rowRenderer={({ index, style, key }) => {
                          const maxItemsPerRow = getMaxItemsPerRow(width);
                          // console.log("slabs", slabs.length)
                          const slabsList = generateIndexesForRow(
                            index,
                            maxItemsPerRow,
                            slabs.length
                          ).map((_index) => slabs[_index]);

                          return (
                            <div style={{ ...style, ...styles.row }} key={key}>
                              {/* {console.log("style ", style)} */}
                              {slabsList.map((slab) => (
                                <RowItem
                                  key={slab.id}
                                  slab={slab}
                                  changeItem={this.replaceModalItem}
                                  deleteItem={this.handleDelete}
                                  addToCart={this.handleAddToCart}
                                />
                              ))}
                            </div>
                          );
                        }}
                        noRowsRenderer={this.noRowsRenderer}
                      />
                    )}
                  </WindowScroller>
                );
              }}
            </AutoSizer>
          )
          //> virtualized list
          }
        </div>
        <SlabEditDialog
          slab={this.state.selectedSlab}
          updateSlab={this.handleUpdate}
          createSlab={this.handleCreate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  slabs: state.slabs,
});

export default connect(mapStateToProps)(SlabList);

const getImageFullPath = (filename) => {
  return CONSTS.imgBaseUrl + filename;
};

const slabHasPrice = (slab) => {
  if (slab.price != null) return true;
  return +slab.unit_price > 0 && +slab.bundle_price > 0;
};

export const SlabItem = ({ slab, changeItem, deleteItem, addToCart }) => {
  return (
    <div className="card slab-card">
      <div>
        {/* <ImageZoom
                        image={{
                          src: getImageFullPath(slab.filename),
                          alt: slab.filename,
                          className: 'card-img-top slab-card-img',
                          
                        }}
                        zoomImage={{
                          src: getImageFullPath(slab.filename),
                          alt: slab.filename
                        }}
                      /> */}
        <TransformWrapper>
          <TransformComponent>
            <LazyLoadImage
              height={180}
              width={278}
              effect="blur"
              src={getImageFullPath(slab.filename)}
              delayTime="3000"
            />
          </TransformComponent>
        </TransformWrapper>
        {slab.reserved && (
          <div className="reserved-lock">
            <i className="fa fa-lock"></i>
          </div>
        )}
      </div>
      <div className="card-body">
        <p
          className="card-title font-weight-bold"
          style={{ fontFamily: "'Open Sans', sans-serif'" }}
        >
          {slab.material}
        </p>
        <div className="card-text-group">
          <p className="card-text">
            <span className="font-weight-bold">SN</span> {slab.slab_num}
          </p>
          <p className="card-text font-weight-light">
            <span className="font-weight-bold">Bundle</span> {slab.bundle_num}
          </p>
          <p className="card-text font-weight-light">
            <span className="font-weight-bold">Size</span> {slab.sqft}{" "}
            <span>ft&#178;</span>
            <span className="lh-13">
              {" "}
              ({toFixed(slab.length)}&times;{toFixed(slab.height)})
            </span>
          </p>
          <p className="card-text font-weight-light">
            <span className="font-weight-bold">Loc.</span> {slab.section}
            {slab.rack}{" "}
          </p>
          {auth.isAuthenticated() && (
            <div style={{ marginBottom: 15 }}>
              <p className="card-text">
                <span className="font-weight-bold">Unit price </span>
                {toFixed(unitPrice(slab))}{" "}
              </p>
              <p className="card-text">
                <span className="font-weight-bold">Bundle price </span>
                {toFixed(bundlePrice(slab))}{" "}
              </p>
              <p className="card-text">
                <span className="font-weight-bold">Total Price </span>
                {calcPrice(slab)}{" "}
              </p>
            </div>
          )}
          <p className="card-text">
            <span className="font-weight-bold">Origin</span> {slab.origin}{" "}
          </p>
        </div>
      </div>
      {auth.isAuthenticated() && (
        <div className="card-footer">
          <div>
            <button
              className="btn btn-outline-primary btn-sm"
              data-toggle="modal"
              data-target="#exampleModal"
              onClick={() => changeItem(slab)}
            >
              Edit
            </button>
            <button
              className="btn btn-outline-danger btn-sm ml-2"
              onClick={() => deleteItem(slab)}
            >
              Delete
            </button>
          </div>
        </div>
      )}
      <div className="card-footer">
        <div className="text-right">
          {auth.isAuthenticated() && (
            <button
              className="btn btn-outline-secondary btn-sm"
              disabled={slab.reserved || !slabHasPrice(slab)}
              onClick={() => addToCart(slab)}
            >
              Add to Cart
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
