import React, { Component } from "react";
import { fetchInactiveSlabs, updateSlabs } from "../services/apiService";
import { Spinner } from "../components/dialogs";

export default class InactiveSlabs extends Component {
  filtered = [];
  state = {
    slabs: [],
    search: "",
    loading: false,
  };
  async componentDidMount() {
    const slabs = await fetchInactiveSlabs();
    this.setState({ slabs });
  }

  handleChange = (event) => {
    const name = event.target.name;

    this.setState({
      [name]: event.target.value,
    });
  };

  activateSlabs = async () => {
    this.showSpinner(true);

    const data = this.filtered.reduce((acc, item) => {
      if (item.active) {
        console.log("redude", acc);
        acc.push({
          id: item.id,
          sold_at: null,
        });
      }
      return acc;
    }, []);

    let result = await updateSlabs(data);
    if (!result.error) this.removeSlabs(result);
    this.showSpinner(false);
  };

  removeSlabs = (slablist) => {
    const ids = slablist.reduce((acc, item) => {
      acc.push(item.id);
      return acc;
    }, []);
    const slabs = this.state.slabs.filter((item) => !ids.includes(item.id));
    this.setState({ slabs });
  };

  showSpinner = (value) => {
    this.setState({ loading: value });
  };

  handleActiveChange = (e, slab) => {
    const value = e.target.checked;

    const slabs = this.state.slabs.map((item) => {
      if (slab.id === item.id) return { ...item, active: value };
      return item;
    });

    this.setState({ slabs });
  };

  render() {
    const filtered = this.state.slabs.filter((item) =>
      item.slab_num.startsWith(this.state.search.toLowerCase())
    );
    this.filtered = filtered;

    return (
      <div className="page-s">
        <Spinner isVisible={this.state.loading} />
        <div className="container mt-3">
          <h5>Inactive Slabs</h5>
          <div className="row my-4">
            <div className="col-sm-3 mb-2">
              <input
                type="text"
                className="form-control"
                name="search"
                placeholder="Search #"
                value={this.state.search}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-sm-3 mb-2">
              <button
                className="btn btn-outline-secondary"
                onClick={this.activateSlabs}
              >
                Activate Selected{" "}
              </button>
            </div>
            <div className="col-sm-3">
              <label className="count-label">Count {filtered.length}</label>
            </div>
          </div>
          <div className="">
            {filtered.map((item) => (
              <div
                key={item.id}
                className="bg-light p-2 mb-2 border border-gray"
              >
                <input
                  type="checkbox"
                  className="mx-3"
                  name="active"
                  checked={item.active}
                  onChange={(e) => this.handleActiveChange(e, item)}
                />
                {item.slab_num}
                <span className="ml-4">{item.material} </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
