/* eslint eqeqeq: 0 */
import React, { Component } from 'react'
import moment from 'moment';
import { isEmptyObj, formatMoney, toFixed } from '../services/utils';

import '../invoice.css';


const initial = {
    customer : {name:"", email: "", street: "", phone: ""},
    items: []
}

const toBool = (val) => {
    return (+val == 1 || val === true); 
}
export default class Invoice extends Component {
    state = { order: initial, loading: true }

    componentWillReceiveProps(nextProps) {
        const order =  !isEmptyObj(nextProps.order)? nextProps.order: initial;
        this.setState({order, loading: this.props.loading});
    }

    render() {
        const order = this.state.order;
        const customer = order.customer;

    return (
        <div className="modal fade" id="invoiceDialog" tabIndex="-1" role="dialog" aria-labelledby="editorDialogLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={{width: '226mm'}} >
                    <div className="modal-header">
                        <h5 className="modal-title" id="editorDialogLabel">
                            {this.props.loading && <span className='text-secondary font-weight-light'>Loading...</span>}
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                <div className="modal-body">
                <div className="page-box"> 
                    <div>
                    <table className="header-table">
                    <tbody>
                        <tr>
                        <td>
                            <div>
                                <div>
                                    <img className='invoice-logo' src="/images/paper_logo.png"  alt='logo' />
                                </div>
                                <div className="header-addr">
                                    <b>Granite World, LLC</b>
                                    <div className="imt-03">
                                    2176 W Center St.<br />
                                    Provo, UT 84601 <br />
                                    phone: (801) 404-8704 <br/>
                                    web: graniteworldutah.com
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td className="invoice-title-td">
                            <div className="invoice-title-box">
                                <div className="invoice-title text-right" >INVOICE</div>
                                <div className="invoice-number">
                                    <div>Invoice # <span>{order.id} </span></div>
                                    <div className="text-bottom">Date: <span>{moment(order.created_at).format('MM/DD/YYYY')}</span></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="bill-to-boxes">
                <table>
                    <tbody>
                    <tr>
                        <td>
                            <div className="bill-to-box">
                                <div className="text-weight-bold high-line">BILL TO:</div>
                                <div className=''>
                                    <div>{customer.name} </div>
                                    <div>{customer.street} <div>
                                    </div>{customer.city}, {customer.state} {customer.zip}</div>
                                    <div>{customer.phone}</div>
                                    <div></div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className='bill-to-box'>
                                <div className="text-weight-bold high-line">SHIP TO:</div>
                                <div className=''>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className='invoice-div'>
                <table className="invoice-table">
                    <thead>
                    <tr className="text-left">
                        <th>#</th>
                        <th>Description</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                        {/* <!-- items >>---> */}
                        {
                            order.items.map(item => (
                                <tr className="item" key={item.id}>
                                    <td>{item.index + 1}</td>
                                    <td>{item.slab.material} - {item.slab_num}&nbsp;&nbsp;&nbsp;&nbsp;{item.sqft} sf&sup2;
                                        ({toFixed(item.slab.length)}&times;{toFixed(item.slab.height)})
                                    </td>
                                    <td>{toBool(item.isbundle)? <span>{item.bundle_price}*</span>: <span>{item.unit_price}</span>}</td>
                                    <td>{ formatMoney(item.total) }</td>
                                </tr> 
                            ))
                        }
                        {/* <!-- << items --> */}
                        {/* <!-- subtotal --> */}
                        <tr className="subtotals">
                                <td></td>
                                <td></td>
                                <th>Subtotal</th>
                                <td>{formatMoney(order.subtotal)}</td>
                        </tr>
                        <tr className="subtotals">
                                <td></td>
                                <td></td>
                                <th>Tax ({ toFixed(order.tax_rate * 100) }%)</th>
                                <td>{formatMoney(order.tax)}</td>
                        </tr> 
                        <tr className="subtotals">
                                <td></td>
                                <td></td>
                                <th>Other Fees</th>
                                <td>{formatMoney(order.total_fees)}</td>
                        </tr>
                        <tr className="subtotals">
                                <td></td>
                                <td></td>
                                <th>Total Due</th>
                                <td>{formatMoney(order.total)}</td>
                        </tr>  
                    </tbody>
                </table>
            </div> 
            {/* <!-- << table section ---> */}
            <div className="bottom-box">
                <div className='contact-box'>
                    <p>
                        Make all checks payable to Granite World.
                    </p>
                    <br />
                    <p>
                        If you have any questions concerning this invoice, contact Granite World at 801-404-8702 or graniteworldutah@gmail.com
                    </p>
                </div> 
                {/* <!-- after table ---> */}
                <div className='delivery-box'>
                    <span>
                        RECEIVED BY __________________________________________________________  
                    </span>
                    <span className="delivery-date" >
                        Date: ______/______/______
                    </span>
                </div>
                <div className="text-center imt-2">
                    THANK YOU FOR YOUR BUSINESS
                </div>
        </div> 
        {/* <!-- delivery ---> */}
        </div>
        </div> {/** modal body  */}
        </div>
        </div>
        </div>
    );
  }
}